const initialValuesForeignOperatorForm = {
  cpfCnpjRaiz: "",
  tin: "",
  nome: "",
  situacao: "",
  logradouro: "",
  nomeCidade: "",
  codigoSubdivisaoPais: "",
  codigoPais: "",
  cep: "",
  email: "",
  dataReferencia: null,
  codigoInterno: "",
};

export default initialValuesForeignOperatorForm