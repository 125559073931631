import { useState } from 'react';

import { MdArrowDropDown } from 'react-icons/md';

import { Flex, Select, Switch, useDisclosure } from '@chakra-ui/react';

import NotificationModal from './NotificationModal';

const shouldRender = (fieldProps, condition = true) => (typeof condition === 'function' ? condition(fieldProps) : condition);

const hasPermission = (allowedRoles = [], deniedRoles = []) => {
  const userRole = localStorage.getItem('profile-role')?.trim();
  if (!userRole) return false;
  if (deniedRoles.includes(userRole)) return false;
  return allowedRoles.length === 0 || allowedRoles.includes(userRole);
};

const CustomFields = ({ children, ...props }) => {
  // Extrai as propriedades do campo, valor selecionado e identificador
  const { field, selected, identifier } = props || {};
  // Extrai os atributos essenciais do campo
  const { type, options, action, modalText, updateOnce, align, isFieldVisible, isFieldDisabled, allowedRoles, deniedRoles } =
    field || {};

  if (!shouldRender(props, isFieldVisible) || !hasPermission(allowedRoles, deniedRoles)) {
    return null;
  }

  const isDisabled = shouldRender(props, isFieldDisabled);

  // Hooks para controle dos modais (select e radio)
  const { isOpen: isOpenSelectModal, onOpen: onOpenSelectModal, onClose: onCloseSelectModal } = useDisclosure();
  const { isOpen: isOpenRadioModal, onOpen: onOpenRadioModal, onClose: onCloseRadioModal } = useDisclosure();

  // Função auxiliar para obter o rótulo do botão com base no status da ação
  const getButtonLabel = (enableAction, field) => {
    // Se enableAction for true, tenta usar o label do primeiro item; caso contrário, o segundo
    const label = enableAction ? field?.roles?.[0]?.label : field?.roles?.[1]?.label;
    return label !== undefined ? label || '' : enableAction ? 'Ativar' : 'Desativar';
  };

  switch (type) {
    case 'select': {
      const [currentFilter, setCurrentFilter] = useState({
        label: options.label || '',
        value: options.value || '',
        slug: options.slug || '',
      });
      // Desabilita o input se updateOnce for true e já tiver sido alterado
      const [fieldDisabled, setFieldDisabled] = useState(isDisabled);

      // Função para tratar a confirmação da seleção via modal
      const handleClickSelect = (userDecision) => {
        if (userDecision) {
          action(identifier, currentFilter);
          if (updateOnce) setFieldDisabled(true);
        }
        onCloseSelectModal();
      };

      return (
        <Flex alignItems="center" cursor="pointer" justifyContent={align || 'center'}>
          <Select
            key={identifier}
            data-custom-field-select
            icon={<MdArrowDropDown />}
            variant="primary"
            defaultValue={selected}
            isDisabled={fieldDisabled}
            onClick={(e) => e.stopPropagation()}
            onChange={(event) => {
              if (!event.target.value) {
                toast.error('Você deve selecionar um perfil');
                return;
              }
              const selectedOption = options.find((e) => e.value === event.target.value);
              setCurrentFilter({
                label: event.target.value,
                value: selectedOption?.value,
                slug: selectedOption?.slug,
              });
              onOpenSelectModal();
            }}>
            {options.map((role, key) => (
              <option key={`${role.value}-${key}`} value={role.value}>
                {role.label}
              </option>
            ))}
          </Select>

          <NotificationModal
            isOpen={isOpenSelectModal}
            onOpen={onOpenSelectModal}
            onClose={onCloseSelectModal}
            callback={handleClickSelect}
            modalText={modalText}
          />
        </Flex>
      );
    }
    case 'radio': {
      // Função para determinar o status inicial com base nas condições do campo
      const findStatusByCondition = (initialValue, conditions) => {
        const match = conditions.find((condition) => condition.condition === initialValue);
        return match ? match.status : false;
      };

      const initialRadioStatus = findStatusByCondition(selected, field?.roles);
      const [enableAction, setEnableAction] = useState(initialRadioStatus);
      // Desabilita o input se updateOnce for true e já tiver sido alterado
      const [fieldDisabled, setFieldDisabled] = useState(isDisabled);

      // Função para tratar a confirmação da alteração do status via modal
      const handleClickRadio = (userDecision) => {
        if (userDecision) {
          action(identifier, !enableAction);
          setEnableAction(!enableAction);
          if (updateOnce) setFieldDisabled(true);
        }
        onCloseRadioModal();
      };

      return (
        <Flex align="center" gap="10px" fontSize="13px" justifyContent={align || 'center'}>
          <Switch
            size="md"
            data-customField-radio
            isChecked={enableAction}
            isDisabled={fieldDisabled}
            onChange={(e) => {
              e.stopPropagation();
              onOpenRadioModal();
            }}
          />
          {getButtonLabel(enableAction, field)}
          <NotificationModal
            isOpen={isOpenRadioModal}
            onOpen={onOpenRadioModal}
            onClose={onCloseRadioModal}
            callback={handleClickRadio}
            modalText={modalText}
          />
        </Flex>
      );
    }
    default:
      return null;
  }
};

export default CustomFields;
