import { useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Collapse, Flex, Grid, IconButton, Text } from '@chakra-ui/react';

import { colors } from '../../../styles/colors';

/**
 * Componente de toggle para expandir/recolher seções do formulário.
 * @param {object} props - Propriedades do componente.
 * @param {string} props.label - Título da seção.
 * @param {ReactNode} props.children - Conteúdo da seção.
 * @param {boolean} [props.defaultOpen=false] - Define se a seção inicia aberta.
 */
const DynamicFormSectionToggle = ({ children, ...props }) => {
  const { label, defaultOpen = false, highlight = false } = props || null;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <Box
      borderWidth={1}
      borderColor={colors['gray-bg']}
      borderRadius="md"
      w={'full'}
      p={'6.5px 8px'}
      mb={4}
      bg={highlight ? 'white' : '#f6f6f6'}
      boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)'}>
      <Flex align="center" cursor="pointer" gap={'6px'} onClick={() => setIsOpen(!isOpen)}>
        <IconButton
          icon={isOpen ? <ChevronUpIcon h={'20px'} w={'20px'} /> : <ChevronDownIcon h={'20px'} w={'20px'} />}
          aria-label={isOpen ? 'Recolher seção' : 'Expandir seção'}
          size="sm"
          variant="ghost"
          color={colors.linkColor}
        />
        <Text color={colors.linkColor}>{label}</Text>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Grid mt={4} p={'15px 38px'} gap={'35px'}>
          {children}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default DynamicFormSectionToggle;
