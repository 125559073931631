import React, { useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

import { Button, Flex, FormLabel, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';

import BackgroundLogin from '../../components/Login/BackgroundLogin/BackgroundLogin';
import requests from '../../services/requests';

import './styles.css';

const LoginPage = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const yup = require('yup');

  return (
    <BackgroundLogin>
      <Formik
        initialValues={{
          user: '',
          pwd: '',
        }}
        validationSchema={yup.object().shape({
          user: yup.string().required('Campo de usuário obrigatório'),
          pwd: yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres').required('Campo de senha obrigatório'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          requests
            .authLogin({ username: values.user, password: values.pwd })
            .then((res) => {
              var token = res.data.token;
              localStorage.setItem('session-token', token);

              requests.getProfile().then((profile) => {
                localStorage.setItem('profile-role', profile.role);
                localStorage.setItem('profile-identifier', profile.identifier);
                localStorage.setItem('profile-name', profile.name);
                localStorage.setItem('profile-email', profile.email);
                localStorage.setItem('profile-cellphone', profile.cellphone);
                localStorage.setItem('profile-phone', profile.phone);
                localStorage.setItem('profile-role-human-readable', profile.roleHumanReadable);
                localStorage.setItem('profile-picture', profile.picture);
                localStorage.setItem('profile-permissions', JSON.stringify(profile.permissions));

                let redirectUserTo = window.localStorage.getItem('redirectTo') ?? '/dashboard';
                let redirectUserExpiresAt = window.localStorage.getItem('redirectToExpiresAt');

                if (!redirectUserExpiresAt || new Date() > redirectUserExpiresAt) {
                  redirectUserTo = '/dashboard';
                }

                window.localStorage.removeItem('redirectTo');
                window.localStorage.removeItem('redirectToExpiresAt');
                window.location.href = redirectUserTo;
              });
            })
            .catch((error) => {
              localStorage.setItem('profile-email', values.user);

              const message =
                'Este usuário está expirado e deve solicitar reativação de seu acesso, entre em contato com o time da Vendemmia.';

              if (error.response.data.message === message) {
                window.location.href = '/reactivation';
              }
            }).finally(() => {
              setSubmitting(false);
            });
        }}>
        {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Flex direction="column" gap="10px" className="down-anim">
              <Flex direction="column" gap="20px">
                <Flex direction="column" h="64px">
                  {values.user && (
                    <FormLabel fontSize="small" className="up-anim" color="white" mb="0px">
                      Usuário
                    </FormLabel>
                  )}

                  <Input
                    h="full"
                    variant="flushed"
                    placeholder="Usuário"
                    name="user"
                    value={values.user}
                    onChange={handleChange}
                    color="#FFFFFF"
                    _placeholder={{ color: '#FFFFFF' }}
                    _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                  />

                  <ErrorMessage component="p" className="error-message-error" name="user" />
                </Flex>

                <Flex direction="column" h="64px">
                  {values.pwd && (
                    <FormLabel fontSize="small" className="up-anim" color="white" mb="0px">
                      Senha
                    </FormLabel>
                  )}

                  <InputGroup h="full" alignItems="flex-end">
                    <Input
                      h="full"
                      variant="flushed"
                      placeholder="Senha"
                      name="pwd"
                      type={show ? 'text' : 'password'}
                      value={values.pwd}
                      onChange={handleChange}
                      color="#FFFFFF"
                      _placeholder={{ color: '#FFFFFF' }}
                      _focus={{ borderColor: '#FFFFFF', boxShadow: 'none' }}
                    />

                    <InputRightElement color="#FF2F69" top="" cursor="pointer" _hover={{ opacity: '0.8' }} onClick={handleClick}>
                      {show ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage component="p" className="error-message-error" name="pwd" />
                </Flex>

                <Button
                  variant="link"
                  w="fit-content"
                  justifyContent="start"
                  _hover={{ textColor: '#FF2F69', textDecoration: 'none' }}>
                  <Text
                    textStyle="paragraph"
                    textColor="#FFFFFF"
                    transition=".3s"
                    _hover={{ textColor: '#FF2F69', textDecoration: 'none' }}
                    onClick={() => {
                      localStorage.setItem('profile-email', values.user);
                      window.location.href = '/recover';
                    }}>
                    Esqueci a senha
                  </Text>
                </Button>
              </Flex>

              <Flex
                w="full"
                mt={{ base: '20px', md: '30px' }}
                gap={{ base: '0px', md: '15px' }}
                justify={{ base: 'space-between', md: 'end' }}>
                <Button
                  variant="outline"
                  height="40px"
                  borderRadius="5px"
                  borderColor="#FF2F69"
                  textColor="#FF2F69"
                  transition=".3s"
                  _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
                  isDisabled={isSubmitting}
                  onClick={() => {
                    window.location.href = '/register';
                  }}>
                  <Text>Cadastrar</Text>
                </Button>

                <Button
                  type="submit"
                  variant="solid"
                  height="40px"
                  borderRadius="5px"
                  bgColor="#FF2F69"
                  textColor="#FFFFFF"
                  transition=".3s"
                  _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
                  isLoading={isSubmitting}>
                  <Text>Entrar</Text>
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </BackgroundLogin>
  );
};

export default LoginPage;
