import React, { useEffect, useState } from 'react';

import { FaTrashAlt } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdAdd,
  MdAddCircleOutline,
  MdList,
  MdOutlineHistory,
  MdOutlineModeEditOutline,
  MdOutlineReportProblem,
  MdOutlineUploadFile,
} from 'react-icons/md';

import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { Flex, Grid, Link, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicForm } from '../../../components/CommonForm';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import CommonPage from '../../../components/CommonPage/CommonPage';
import FloatActionButton from '../../../components/Generic/FloatActionButton';
import { PageContainer } from '../../../components/PageContainer/PageContainer';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import { requests } from '../../../services/v2/requests';
import { catalogNote } from '../../../utils/exports';
import { filterOptionsCatalog } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { commonListConfigAttributes } from './components/commonListConfigAttributes';
import { commonListConfigManufacturer } from './components/commonListConfigManufacturer';
import { commonListConfigProduct } from './components/commonListConfigProduct';
import DynamicFormAttributes from './components/DynamicFormAttributes';
import DynamicFormManufacturer from './components/DynamicFormManufacturer';
import DynamicFormProduct from './components/DynamicFormProduct';
import { HeaderModalDetails } from './components/HeaderModalDetails';
import NcmQueryTable from './components/NcmQueryTable';
import PanelTabsController from './components/PanelTabsController';

const CatalogManagementPage = () => {
  // Hooks de permissão e tokens
  const hasPermission = permissions.vendemmiaProductCatalog;
  const customToken =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3NDAxNjYzNTQsImV4cCI6MTgwMjM3NDM1NCwicm9sZXMiOlsiUk9MRV9BRE1JTiJdLCJ1c2VybmFtZSI6IlNoWkc5U0UifQ.Ox-S3C0vmqEuKjHf4qBNiBxt8kMnzeoRCGSueucqfgIvp5peidcyXrgTbZEOG2Mko-v2-I-erxoMOA2OMNDo8BFhV3JW8BzU09AvgiKNX3ojQgeetrRQMVF1WKh9tAwPDlyFV6JVd-oKOEryIq8sQRepiy4hV_OCKUELGT26wcR8yALLRIy6M9Se2hQszvURB20o3Qw-Ydhpl0oBej0blN0DUgfT0b-eLDm0KtaoaWDB4ms9Bcm6g6Jvwna28Ww0Mfs0B3hSrrftRdgRruHeT9wJkMWk8Icsg6at8eGHYYDjruaD4kF8PVwkmecAYWK5gDQtRmXhtVjEX8d9MnUvz1jxTmUUFk2Ypa_351m3uMyl5TKUSkm_3D5FTSQI7s6fzaZut0-bW6ueS0JpAKUjk87G9S8o-lHC3tbOIonvQdYWizhUzBYNQ0G-E1aKmAKslLu_d6E9UH3HwC22yq4RIDAb-X18MwWszenegJRDuMD_uTbSMqq9M3CnDAC8Q3nrvfb-nxlVE5YVuUOzMo67VtoTQZFPiZV8-7E6oUekiI7gFuyJnQov4GLqxGPAuS-34pkWRAAqI-EwFllWOublw_3_Ue7aQQXSsgZ2McLmkghj7ZZ3peLxCA4mdjjLSt8rocsyk9RI_OJTVQN79ZvEt1-jqwplpEC51iUTsUDgs7A';

  // Estados iniciais
  const initialProductValuesForm = {
    denominacao: '',
    situacao: '',
    modalidade: '',
    codigoInterno: '',
    codigoInternoERP: '',
    ncm: '',
    descricao: '',
    dataReferencia: null,
    cpfCnpjRaiz: '',
  };

  // Hooks de estado
  const [filterOptions, setFilterOptions] = useState([]);
  const [initialProductValues, setInitialProductValues] = useState(initialProductValuesForm);
  const [identifierProduct, setIdentifierProduct] = useState('');
  const [identifierManufacturer, setIdentifierManufacturer] = useState('');
  const [product, setProduct] = useState();
  const [productList, setProductList] = useState([]);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [foreignOperatorList, setForeignOperatorList] = useState([]);
  const [inconsistenciesList, setInconsistenciesList] = useState([]);
  const [logHistoryList, setLogHistoryList] = useState([]);

  const [tab, setTab] = useState('informations');

  const [hasPortalUnicoErrorsProduct, setHasPortalUnicoErrorsProduct] = useState(false);

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  // Hooks de controle
  const { isOpen: isEditProductModal, onOpen: openEditProductModal, onClose: closeEditProductModal } = useDisclosure();
  const { isOpen: isProductDrawerOpen, onOpen: openProductDrawer, onClose: closeProductDrawer } = useDisclosure();
  const { isOpen: isManufacturerModalOpen, onOpen: openManufacturerModal, onClose: closeManufacturerModal } = useDisclosure();
  const { isOpen: isAttributesDrawerOpen, onOpen: openAttributesDrawer, onClose: closeAttributesDrawer } = useDisclosure();
  const { isOpen: isInconsistenciesOpen, onOpen: openInconsistenciesDrawer, onClose: closeInconsistenciesDrawer } = useDisclosure();
  const { isOpen: isNcmDrawerOpen, onOpen: openNcmDrawer, onClose: closeNcmDrawer } = useDisclosure();
  const { isOpen: isLogHistoryDrawerOpen, onOpen: openLogHistoryDrawer, onClose: closeLogHistoryDrawer } = useDisclosure();
  const { isOpen: modalDeleteIsOpen, onOpen: modalDeleteOnOpen, onClose: modalDeleteOnClose } = useDisclosure();

  // Hooks de listagem
  const {
    action: actionProductList,
    setAction: setActionProductList,
    handleSort: handleSortProductList,
    sorting: sortingProductList,
    metadata: metadataProductList,
    setMetadata: setMetadataProductList,
    isLoading: isLoadingProductList,
    setIsLoading: setIsLoadingProductList,
  } = commonListMethods();

  const {
    action: actionManufacturerList,
    setAction: setActionManufacturerList,
    handleSort: handleSortManufacturerList,
    sorting: sortingManufacturerList,
    metadata: metadataManufacturerList,
    setMetadata: setMetadataManufacturerList,
    isLoading: isLoadingManufacturerList,
    setIsLoading: setIsLoadingManufacturerList,
  } = commonListMethods();

  const {
    action: actionAttributesList,
    setAction: setActionAttributesList,
    handleSort: handleSortAttributesList,
    sorting: sortingAttributesList,
    metadata: metadataAttributesList,
    setMetadata: setMetadataAttributesList,
    isLoading: isLoadingAttributesList,
    setIsLoading: setIsLoadingAttributesList,
  } = commonListMethods();

  // Funções de requisição
  const getProductList = async (filters, _key, page) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/list`,
          endpoint: true,
          customToken: 'customToken',
          filters,
          page,
        }),
      setIsLoading: setIsLoadingProductList,
    });

    setProductList(res?.data?.data);
    setMetadataProductList(res?.data?.meta);
  };

  const getInformationsShow = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifier}/show`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setInitialProductValues({
      denominacao: res.data.denominacao,
      situacao: res.data.situacao,
      modalidade: res.data.modalidade,
      codigoInterno: res.data.codigoInterno,
      codigoInternoERP: res.data.codigoInternoERP,
      ncm: res.data.ncm,
      descricao: res.data.descricao,
      dataReferencia: res.data.dataReferencia,
      cpfCnpjRaiz: res.data.cpfCnpjRaiz,
      identifier: res.data.identifier,
    });

    setProduct({
      ncmCode: res.data.ncm?.code,
      ncmDescription: `${res.data.ncm?.code} ${'-'} (${res.data.ncm?.description})`,
      ...res.data,
    });

    setHasPortalUnicoErrorsProduct(res.data?.hasPortalUnicoErrors);
  };

  const getManufacturerList = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifier}/manufacturer/show`,
          endpoint: true,
          customToken: 'customToken',
        }),
      setIsLoading: setIsLoadingManufacturerList,
    });

    setManufacturerList(res.data.data);
    setMetadataManufacturerList(res.data.metadata);
  };

  const getAttributes = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifier}/attribute/show`,
          endpoint: true,
          customToken: 'customToken',
        }),
      setIsLoading: setIsLoadingAttributesList,
    });

    setAttributesList(res.data.data);
    setMetadataAttributesList(res.data.metadata);
  };

  const getForeignOperatorList = async () => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/foreign-operator/list`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    const list =
      res.data.data.map((op) => ({
        label: `${op.nome} (${op.codigoSubdivisaoPais})`,
        value: op.identifier,
      })) || [];

    setForeignOperatorList(list);
  };

  const getInconsistencies = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifier}/portal-unico/report`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setInconsistenciesList(res.data);
  };

  const getInconsistenciesManufacturer = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/manufacturer/${identifier}/portal-unico/report`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setInconsistenciesList(res.data);
  };

  const getLogHistory = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifier}/log/list`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setLogHistoryList(res.data);
  };

  const deleteManufacturer = async () => {
    const res = await executeRequest({
      action: () =>
        requests.delete({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifierProduct}/manufacturer/${identifierManufacturer}/remove`,
          endpoint: true,
          customToken: 'customToken',
        }),
      msgSuccess: 'Operador removido com sucesso!',
      msgError: 'Problemas ao remover o operador.',
      callback: () => {
        getManufacturerList(identifierProduct);
      },
    });

    setLogHistoryList(res.data);
  };

  // Funções de ação
  const handleProductEditModal = async (identifier) => {
    setInitialProductValues(initialProductValuesForm);

    setIdentifierProduct(identifier);

    await getInformationsShow(identifier);
    await getManufacturerList(identifier);
    await getAttributes(identifier);

    openEditProductModal();
  };

  const handleInconsistenciesDrawer = async (identifier) => {
    await getInconsistencies(identifier);

    openInconsistenciesDrawer();
  };

  const handleInconsistenciesManufacturerDrawer = async (identifier) => {
    await getInconsistenciesManufacturer(identifier);

    openInconsistenciesDrawer();
  };

  const handleLogHistoryDrawer = async (identifier) => {
    await getLogHistory(identifier);

    openLogHistoryDrawer();
  };

  const sendPortalUnicoProduct = async (identifier) => {
    await executeRequest({
      action: () =>
        requests.put({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifier}/portal-unico/send`,
          endpoint: true,
          customToken: 'customToken',
        }),
      msgSuccess: 'Produto enviado com sucesso para o Portal Único.',
      msgError: 'Problemas ao enviar o produto para o portal único.',
      triggerRefresh,
      errorCallback: () => {
        triggerRefresh();
      },
    });
  };

  const sendPortalUnicoManufacturer = async (identifier) => {
    await executeRequest({
      action: () =>
        requests.put({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifierProduct}/manufacturer/${identifier}/portal-unico/send`,
          endpoint: true,
          customToken: 'customToken',
        }),
      msgSuccess: 'Produto enviado com sucesso para o Portal Único.',
      msgError: 'Problemas ao enviar o produto para o portal único.',
      callback: () => {
        closeManufacturerModal();
        getManufacturerList(identifierProduct);
      },
      errorCallback: () => {
        getManufacturerList(identifierProduct);
      },
    });
  };

  const handleDrawerNewProduct = () => {
    setInitialProductValues(initialProductValuesForm);

    openProductDrawer();
  };

  const handleModalAddManufacturer = async () => {
    await getForeignOperatorList();

    openManufacturerModal();
  };

  const handleOpenModalDelete = (identifierManufacturer) => {
    setIdentifierManufacturer(identifierManufacturer);

    modalDeleteOnOpen();
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsCatalog());
  };

  const customFields = [
    {
      isFieldDisabled: (item) => item.rawItem.sisComexEnvioStatus === 'ENVIADO' ?? item.rawItem.hasPortalUnicoErrors,
      type: 'radio',
      title: 'sisComexEnvioStatus',
      name: 'sisComexEnvioStatus',
      modalText: 'Você tem certeza que deseja enviar o produto para o portal único?',
      roles: [
        { label: 'Sincronizado', condition: 'ENVIADO', status: true },
        { label: 'Sincronizar', condition: 'PENDENTE', status: false },
        { label: 'Sincronizar', condition: 'CADASTRADO', status: false },
      ],
      bgColor: { turnIn: '#6C48C2', turnOff: '#C5C5C5' },
      action: sendPortalUnicoProduct,
    },
  ];

  const customButtons = [
    {
      main: [
        {
          isButtonVisible: (item) => item.hasPortalUnicoErrors,
          label: 'Inconsistências',
          tooltip: 'Ver inconsistências',
          icon: <MdOutlineReportProblem color="red" size={20} />,
          action: handleInconsistenciesDrawer,
        },
        {
          label: 'Histórico de alterações',
          tooltip: 'Ver histórico de alterações',
          icon: <MdOutlineHistory size={20} />,
          action: handleLogHistoryDrawer,
        },
        {
          label: 'Editar produto',
          tooltip: 'Editar produto',
          icon: <MdOutlineModeEditOutline size={20} />,
          action: handleProductEditModal,
        },
      ],
      config: [{ maxVisibleButtons: 845654 }],
    },
  ];

  const actionButtonsManufacturer = [
    {
      label: 'Cadastrar fabricante',
      variant: 'primary',
      icon: <MdAccountCircle size={22} />,
      onClick: () => window.open('/comex/foreign-operator', '_blank'),
    },
    {
      label: 'Vincular fabricante',
      variant: 'primary',
      icon: <MdAddCircleOutline size={22} />,
      onClick: handleModalAddManufacturer,
    },
  ];

  const customFieldsManufacturer = [
    {
      isFieldDisabled: false,
      type: 'radio',
      title: 'vincular',
      name: 'vincular',
      modalText: 'Você tem certeza que deseja enviar o vinculo entre fabricante e produto para o portal único?',
      roles: [
        { label: 'Sincronizado', condition: true, status: true },
        { label: 'Sincronizar', condition: false, status: false },
      ],
      bgColor: { turnIn: '#6C48C2', turnOff: '#C5C5C5' },
      action: sendPortalUnicoManufacturer,
    },
  ];

  const customButtonsManufacturer = [
    {
      main: [
        {
          isButtonVisible: (item) => item.hasPortalUnicoErrors,
          label: 'Inconsistências',
          tooltip: 'Inconsistências',
          icon: <MdOutlineReportProblem color="red" size={20} />,
          action: handleInconsistenciesManufacturerDrawer,
        },
        {
          label: 'Excluir',
          tooltip: 'Excluir',
          deniedRoles: ['ROLE_CLIENT'],
          icon: <FaTrashAlt size={16} color="#E74C3C" />,
          action: handleOpenModalDelete,
        },
      ],
    },
  ];

  const actionButtonsAttributes = [
    {
      label: 'Gerenciar atributos',
      tooltip: 'Gerenciar atributos',
      variant: 'primary',
      icon: <MdAddCircleOutline size={22} />,
      onClick: () => openAttributesDrawer(),
    },
  ];

  const tabOptions = [
    {
      key: 'informations',
      text: 'Informações',
    },
    {
      key: 'attributes',
      text: 'Atributos',
    },
    {
      key: 'manufacturer',
      text: 'Fabricante/Produtor',
    },
  ];

  const getTabOptions = (tab) => {
    switch (tab) {
      case 'manufacturer':
        return (
          <CommonList
            key={'manufacturer'}
            list={manufacturerList}
            rawData={manufacturerList}
            //
            action={actionManufacturerList}
            sorting={sortingManufacturerList}
            metadata={metadataManufacturerList}
            setAction={setActionManufacturerList}
            setMetadata={setMetadataManufacturerList}
            handleSort={handleSortManufacturerList}
            isLoading={isLoadingManufacturerList}
            //
            actionButtons={actionButtonsManufacturer}
            customFields={customFieldsManufacturer}
            customButtons={customButtonsManufacturer}
            resetStyles
            {...commonListConfigManufacturer}
          />
        );

      case 'attributes':
        return (
          <CommonList
            key={'attributes'}
            list={attributesList}
            rawData={attributesList}
            //
            action={actionAttributesList}
            sorting={sortingAttributesList}
            metadata={metadataAttributesList}
            setAction={setActionAttributesList}
            setMetadata={setMetadataAttributesList}
            handleSort={handleSortAttributesList}
            isLoading={isLoadingAttributesList}
            //
            actionButtons={actionButtonsAttributes}
            resetStyles
            {...commonListConfigAttributes}
          />
        );

      default:
        return (
          <DynamicFormProduct
            isEdit
            identifier={identifierProduct}
            initialValues={initialProductValues}
            triggerRefresh={triggerRefresh}
            getInformationsShow={getInformationsShow}
            sendPortalUnicoProduct={sendPortalUnicoProduct}
            hasPortalUnicoErrorsProduct={hasPortalUnicoErrorsProduct}
            getInconsistencies={getInconsistencies}
            getLogHistory={getLogHistory}
            inconsistenciesList={inconsistenciesList}
            logHistoryList={logHistoryList}
          />
        );
    }
  };

  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(getProductList);

  useEffect(() => {
    localStorage.setItem('customToken', customToken);

    getFilterOptions();
  }, []);

  useEffect(() => {
    setTab('informations');
  }, [isEditProductModal]);

  return (
    <CommonPage
      screen="vendemmia-catalog-management"
      title="Catálago de produtos"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Código, denominação ou descrição"
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={getProductList}
      list={productList}
      metadata={metadataProductList}
      sorting={sortingProductList}
      isLoading={isLoadingProductList}
      isRefreshLoading={isLoadingProductList}
      forceLoadTrigger={forceLoadTrigger}
      refreshSeconds={5000}
      showPeriodFilter={false}
      allowEmptySearchPeriod={false}>
      <PageContainer>
        <CommonList
          list={productList}
          rawData={productList}
          //
          action={actionProductList}
          sorting={sortingProductList}
          metadata={metadataProductList}
          setAction={setActionProductList}
          setMetadata={setMetadataProductList}
          handleSort={handleSortProductList}
          //
          customFields={customFields}
          customButtons={customButtons}
          isLoading={isLoadingProductList}
          {...commonListConfigProduct}
        />
      </PageContainer>

      <Modal isOpen={isEditProductModal} onOpen={openEditProductModal} onClose={closeEditProductModal} size="full">
        <ModalOverlay />

        <ModalContent p="25px !important" overflow="hidden">
          <ModalHeader
            p="25px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px"
            borderColor="#7070702E">
            <HeaderModalDetails closeModal={closeEditProductModal} setIdentifierProduct={setIdentifierProduct}>
              <Flex gap="28px">
                <Image src={catalogNote} />

                <Grid>
                  <Text color="#0F0A1D99" fontSize="14px">
                    Detalhes do Produto
                  </Text>

                  <Text as="strong" color="#0F0A1D" fontSize="24px">
                    #{`${initialProductValues.codigoInterno}`}
                  </Text>
                </Grid>
              </Flex>
            </HeaderModalDetails>

            <PanelTabsController tab={tab} setTab={setTab} tabs={tabOptions} />
          </ModalHeader>

          <ModalBody p="0px">
            {getTabOptions(tab)}

            <CommonDrawer
              heading="Atributos"
              placement="left"
              width="100%"
              closeOnEsc={false}
              isOpen={isAttributesDrawerOpen}
              onOpen={openAttributesDrawer}
              onClose={closeAttributesDrawer}>
              <DynamicFormAttributes
                identifierProduct={identifierProduct}
                product={product}
                getAttributes={getAttributes}
                closeAttributesDrawer={closeAttributesDrawer}
              />
            </CommonDrawer>

            <CommonModal
              isOpen={isManufacturerModalOpen}
              onOpen={openManufacturerModal}
              onClose={closeManufacturerModal}
              closeButton={true}
              size="3xl">
              <DynamicFormManufacturer
                list={foreignOperatorList}
                identifier={identifierProduct}
                getManufacturerList={getManufacturerList}
                closeManufacturerModal={closeManufacturerModal}
              />
            </CommonModal>
          </ModalBody>
        </ModalContent>
      </Modal>

      <CommonDrawer
        heading="Novo produto"
        placement="left"
        isOpen={isProductDrawerOpen}
        onOpen={openProductDrawer}
        onClose={closeProductDrawer}>
        <DynamicFormProduct
          identifier={identifierProduct}
          initialValues={initialProductValues}
          triggerRefresh={triggerRefresh}
          closeProductDrawer={closeProductDrawer}
          getInconsistencies={getInconsistencies}
          getLogHistory={getLogHistory}
          inconsistenciesList={inconsistenciesList}
          logHistoryList={logHistoryList}
        />
      </CommonDrawer>

      <CommonDrawer
        heading="Consulta completa de NCM"
        placement="left"
        width="60%"
        isOpen={isNcmDrawerOpen}
        onOpen={openNcmDrawer}
        onClose={closeNcmDrawer}>
        <DynamicForm config={{ initialValues: {}, callback: () => {} }}>
          <NcmQueryTable />
        </DynamicForm>
      </CommonDrawer>

      <CommonDrawer
        heading="Inconsistências"
        placement="left"
        isOpen={isInconsistenciesOpen}
        onOpen={openInconsistenciesDrawer}
        onClose={closeInconsistenciesDrawer}>
        <Table className="table-default" w="full" mt="10px">
          <Thead>
            <Tr>
              <Th>Descrição</Th>
            </Tr>
          </Thead>

          <Tbody>
            {inconsistenciesList && Array.isArray(inconsistenciesList) ? (
              inconsistenciesList.map((message, key) => (
                <Tr key={key}>
                  <Td>{message}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="1">
                  <Text textAlign="center">Nenhum erro encontrado no histórico.</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </CommonDrawer>

      <CommonDrawer
        heading="Histórico de alterações"
        placement="left"
        width="60%"
        isOpen={isLogHistoryDrawerOpen}
        onOpen={openLogHistoryDrawer}
        onClose={closeLogHistoryDrawer}>
        <Table className="table-default" w="full" mt="10px">
          <Thead>
            <Tr>
              <Th>Descrição</Th>
              <Th>Versão</Th>
              <Th>Usuário</Th>
              <Th>Efetuada em</Th>
            </Tr>
          </Thead>

          <Tbody>
            {logHistoryList.length > 0 ? (
              logHistoryList.map((entity, key) => (
                <Tr key={key}>
                  <Td>{entity.description}</Td>

                  <Td w="75px">{entity.versao}</Td>

                  <Td w="100px">{entity.userName}</Td>

                  <Td w="120px">{entity.createdAtFormatted}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="4">
                  <Text textAlign="center">Nenhuma alteração registrada.</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </CommonDrawer>

      <CommonModal
        heading={'Certeza que deseja excluir?'}
        subtitle={'Deseja mesmo excluir esse fabricante do produto?'}
        callback={deleteManufacturer}
        isOpen={modalDeleteIsOpen}
        onOpen={modalDeleteOnOpen}
        onClose={modalDeleteOnClose}
        variant={'caution'}
        size={'md'}
        removeRadius={true}
      />

      <FloatActionButton
        saveButton={
          <Button
            rightIcon={<MdAdd color="#FFFFFF" size={20} />}
            h="50px"
            px="24px"
            py="14px"
            bgColor="primary"
            borderRadius="27px"
            color="#FFFFFF"
            loadingText="Salvando"
            _hover={{ bgColor: '#8067DC' }}
            onClick={() => handleDrawerNewProduct()}>
            <Text>Cadastrar produto</Text>
          </Button>
        }
        options={[
          <Link href={'/comex/import-files'} target="_blank" rel="noopener noreferrer" _hover={{ textDecoration: 'none' }} key={1}>
            <Button
              bgColor="green"
              h="50px"
              variant="primary"
              borderRadius="50px"
              _hover={{ bgColor: '#70D499' }}
              leftIcon={<MdOutlineUploadFile color="#FFFFFF" size={20} />}>
              Gerenciar importação de arquivos
            </Button>
          </Link>,

          <Button
            key={2}
            bgColor="green"
            h="50px"
            variant="primary"
            borderRadius="50px"
            _hover={{ bgColor: '#70D499' }}
            leftIcon={<MdList color="#FFFFFF" size={20} />}
            onClick={() => openNcmDrawer()}>
            Consulta completa de NCM
          </Button>,

          <Link href={'/comex/foreign-operator'} target="_blank" rel="noopener noreferrer" _hover={{ textDecoration: 'none' }} key={3}>
            <Button
              bgColor="green"
              h="50px"
              variant="primary"
              borderRadius="50px"
              _hover={{ bgColor: '#70D499' }}
              leftIcon={<MdAccountCircle color="#FFFFFF" size={20} />}>
              Operador estrangeiro
            </Button>
          </Link>,
        ]}
        isOpen={isOpenFloatButton}
        setIsOpen={setIsOpenFloatButton}
      />
    </CommonPage>
  );
};

export default CatalogManagementPage;
