import React from 'react';

import { Route } from 'react-router-dom';

import IndexPage from '../pages';
import ReactivationPage from '../pages/auth/Reactivation/ReactivationPage';
import RecoverPage from '../pages/auth/Recover/RecoverPage';
import RegisterPage from '../pages/auth/Register/RegisterPage';
import SignaturePage from '../pages/auth/Signature/SignaturePage';

export const AuthRoutes = (
  <>
    <Route exact path="/" element={<IndexPage />} />
    <Route exact path="/recover" element={<RecoverPage />} />
    <Route exact path="/register" element={<RegisterPage />} />
    <Route exact path="/reactivation" element={<ReactivationPage />} />
    <Route exact path="/signature" element={<SignaturePage />} />
  </>
);
