import { createContext } from 'react';

import { Formik } from 'formik';

import { DynamicFormStyles } from './styles/DynamicFormStyles';

export const DynamicFormContext = createContext();

export const DynamicForm = ({ children, ...props }) => {
  const config = props.config;

  const { callback } = config;

  return (
    <DynamicFormContext.Provider value={props}>
      <DynamicFormStyles />

      <Formik
        onSubmit={(values) => {
          callback && callback(values);
        }}
        {...config}>
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {children}
          </form>
        )}
      </Formik>
    </DynamicFormContext.Provider>
  );
};
