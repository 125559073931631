import { useState } from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/layout';

import Card from '../../../components/Card/Card';
import TotalProducts from '../../../components/Charts/Warehouse/TotalProducts';
import { CommonBarChart } from '../../../components/CommonChats/CommonBarChart';
import CommonList from '../../../components/CommonList/CommonList';
import { PeriodBar } from '../../../components/CommonList/components/Filters/PeriodBar';
import { formattedDate } from '../../../components/CommonList/Helpers/formattedDate';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsStockTurnover } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';
import { formatToTwoDecimalPlaces } from '../../../utils/text/format/formatToTwoDecimalPlaces';

import { commonListConfig } from './components/commonListConfig';

export const StockTurnoverPage = () => {
  const hasPermission = permissions.warehouseStockTurnover;
  const hasPermissionWarehouse = permissions.warehouse;

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [sublist, setSublist] = useState();

  const [totalProducts, setTotalProducts] = useState();
  const [stockByPeriod, setStockByPeriod] = useState([]);

  const [isLoadingChart, setIsLoadingChart] = useState(true);

  const [forceSetSearchPeriod, setForceSetSearchPeriod] = useState();

  const [period, setPeriod] = useState({
    end: '',
    next: '',
    previous: '',
    start: '',
  });

  const { action, setAction, handleSort, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const load = (filters, _key, page) => {
    getFilterOptions();
    getStockTurnover(filters, page);
    getStockTurnoverMetrics(filters, page);
  };

  const { forceLoadTrigger } = useForceRefresh(load);

  const getStockTurnover = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchStockTurnover(filters, page),
      setIsLoading,
    });

    const formattedTurnoverList = res.data.data.map((item) => {
      return { ...item, stockTurnover: formatToTwoDecimalPlaces(item.stockTurnover) };
    });

    setList(formattedTurnoverList);
    setSublist(formattedTurnoverList);
    setMetadata(res.data.meta);

    setPeriod({
      end: res?.data.dates.end,
      next: res?.data.dates.next,
      previous: res?.data.dates.previous,
      start: res?.data.dates.start,
    });
  };

  const getStockTurnoverMetrics = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchStockTurnoverMetrics(filters, page),
      setIsLoading: setIsLoadingChart,
    });

    setTotalProducts(res.data);
    setStockByPeriod(
      res.data.byPeriod
        .map((item) => ({
          mount: `${item.month}\n${item.year}`,
          total: parseInt(item.total, 10), // Converte para número, caso seja necessário
        }))
        .reverse() // Inverte a ordem dos itens
    );
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsStockTurnover());
  };

  const handleNext = () => {
    setForceSetSearchPeriod({
      startsAt: period.next,
    });
  };

  const handlePrevious = () => {
    setForceSetSearchPeriod({
      startsAt: period.previous,
    });
  };

  const customFilters = [
    {
      template: '1fr',
      filter: (
        <>
          <PeriodBar
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            formmated={period?.start && formattedDate('month', period)}
          />
        </>
      ),
    },
  ];

  return (
    <Page
      screen="stockTurnover"
      title="Giro de estoque"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Cód. produto, empresa ou ref"
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={load}
      list={list}
      metadata={metadata}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      forceSetSearchPeriod={forceSetSearchPeriod}
      showFilters={true}
      showRefreshData={false}
      showPeriodFilter={false}
      allowEmptySearchPeriod>
      <Grid templateRows={'480px 1fr'} gap={'10px'} m={'10px'}>
        <Grid templateColumns={'615px 1fr'} gap={'10px'}>
          <Card w="full" title={'Total de Produtos'}>
            <ScreenLoader isLoading={isLoadingChart}>
              <TotalProducts data={totalProducts} />
            </ScreenLoader>
          </Card>

          <Card
            w="full"
            title={'Estoque disponível'}
            header={
              <Flex justifyContent={'flex-end'} gap={'20px'}>
                <Flex gap={'10px'} alignItems={'center'}>
                  <Box bg={'#422C76'} borderRadius={'3px'} w={'30px'} h={'19px'} display={'block'} />

                  <Text color={'#0F0A1D'} size={'13px'}>
                    Meses anteriores
                  </Text>
                </Flex>

                <Flex gap={'10px'} alignItems={'center'}>
                  <Box bg={'#C767DC'} borderRadius={'3px'} w={'30px'} h={'19px'} display={'block'} />

                  <Text color={'#0F0A1D'} size={'13px'}>
                    Mês vigente
                  </Text>
                </Flex>
              </Flex>
            }>
            <ScreenLoader isLoading={isLoadingChart}>
              <CommonBarChart
                props={{
                  data: stockByPeriod,
                  label: ['total'],
                  indexBy: 'mount',
                  colors: (bar) => {
                    const isLastBar = bar.index === stockByPeriod.length - 1;
                    return isLastBar ? '#C767DC' : '#422C76';
                  },
                  tooltip: (e) => {
                    const { total } = e.data;

                    return (
                      <Card p={'10px'}>
                        <Text color={'#6C48C2'} fontSize={'12px'}>
                          {e.indexValue}
                        </Text>

                        <Text color={'#B7B7B7'} fontSize={'12px'} lineHeight={'18px'}>
                          Disponivel em estoque:
                        </Text>

                        <Text color={'#707070'} fontSize={'17px'}>
                          {total}
                        </Text>
                      </Card>
                    );
                  },
                }}
              />
            </ScreenLoader>
          </Card>
        </Grid>
        <CommonList
          //lists
          list={list}
          rawData={list}
          subList={sublist}
          //actions
          action={action}
          /* sorting={sorting} */
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          //loading
          isLoading={isLoading}
          //custom
          customFilters={customFilters}
          //props
          {...commonListConfig}
        />
      </Grid>
    </Page>
  );
};
