import React, { useEffect, useState } from 'react';

import { Button, Flex, TabPanel, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';

import Tag from '../../../../../components/Generic/Tag';
import ModalUpload from '../../../../../components/Modal/Upload/ModalUpload';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';
import { limitChars } from '../../../../../utils/strings';

import DocumentsActions from './DocumentsActions';

const DocumentsTab = ({ user, refresh, load, setLoad}) => {
  const [documents, setDocuments] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const listDocuments = () => {
    setDocuments(user?.files);
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .uploadDriverFile(user?.identifier, docs, config)
        .then(() => {
          resolve();
          refresh();
          onClose();
        })
        .catch((e) => {
          reject();
        })
        .finally(() => {
          onClose();
        });
    });
  };

  const changeStatus = (identifier, transition, data = {}) => {
    setLoad(true);
    requests.changeStatusFileUser(identifier, transition, data).then(() => {
      refresh();
      setLoad(false);
    });
  };

  useEffect(() => {
    listDocuments();
  }, [user]);

  const convertStatusColor = (original) => {
    let color;
    switch (original) {
      case 'APROVADO':
        color = 'green';
        break;
      case 'REPROVADO':
        color = 'red';
        break;
      case 'PENDENTE':
        color = 'gray';
        break;
      default:
        color = 'yellow';
        break;
    }

    return color;
  };

  return (
    <>
      <ModalUpload
        title="Documentos do motorista"
        subtitle="Upload de documentos do motorista"
        accept=".xml, .pdf, .png, .jpeg"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        multiple={true}
        requestAction={sendUpload}
        nameForm="user_file_upload"
        additional={[
          {
            label: 'Tipo de Documento',
            group: 'type',
            name: 'type',
            required: true,
            options: [
              { value: 'CNH', label: 'CNH' },
              { value: 'EXAME_TOXICOLOGICO', label: 'Exame Toxicológico' },
              { value: 'ANTT', label: 'Antt' },
              { value: 'COMPROVANTE_RESIDENCIA', label: 'Comprovante de Residência' },
              { value: 'PANCARD', label: 'Pancard' },
              { value: 'OUTROS', label: 'Outros' },
            ],
          },
        ]}
      />
      <TabPanel>
        <Flex gap="35px" justify="flex-end">
          <Button variant="primary" mb="5" onClick={() => onOpen()}>
            <Text fontSize="14px" textColor="#FFFFFF">
              Anexar documento
            </Text>
          </Button>
        </Flex>
        <ScreenLoader isLoading={load}>
          <Table className="table-default">
            <Thead border="1px" borderBottom="1px">
              <Tr>
                <Th>Documento</Th>
                <Th>Data de envio</Th>
                <Th>Status</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody border="1px">
              {!load &&
                documents?.length > 0 &&
                documents?.map(function (document, key) {
                  return (
                    <Tr key={key} style={{ '--delay': `0.${key}s` }}>
                      <Td>{limitChars(document?.name)}</Td>
                      <Td>{document?.lastSendDateFormatted}</Td>

                      <Td>
                        <Tag type={convertStatusColor(document?.status)}>{document?.statusFormatted}</Tag>
                      </Td>
                      <Td>
                        <DocumentsActions
                          enableActions={document?.status == 'EM_ANALISE' || document?.status == 'INVALIDO'}
                          identifier={user?.identifier}
                          name={user?.name}
                          document={document}
                          callback={changeStatus}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </ScreenLoader>
      </TabPanel>
    </>
  );
};

export default DocumentsTab;
