import React from 'react';

import { Route } from 'react-router-dom';

import AdditionalFieldsManagement from '../pages/vendemmia/additional-fields-management/AdditionalFieldsManagement';
import AiAssistantPage from '../pages/vendemmia/ai_assistent/AiAssistantPage';
import BillingDashboardPage from '../pages/vendemmia/billing-dashboard/BillingDashboardPage';
import CertificatePage from '../pages/vendemmia/certificate/CertificatePage';
import ClientManagementPage from '../pages/vendemmia/client-management/ClientManagementPage';
import ContractManagementDetailPage from '../pages/vendemmia/contract-management/components/ContractManagementDetailPage';
import ModuleContractManagementPage from '../pages/vendemmia/contract-management/ContractManagementPage';
import CreditAnalysisDetailPage from '../pages/vendemmia/credit-analysis/components/CreditAnalysisDetailPage';
import CreditAnalysisPage from '../pages/vendemmia/credit-analysis/CreditAnalysisPage';
import FaqPage from '../pages/vendemmia/faq/FaqPage';
import InconsistencyPage from '../pages/vendemmia/inconsistency/InconsistencyPage';
import NcmDetailPage from '../pages/vendemmia/ncm-management/components/NcmDetailPage';
import NcmManagementPage from '../pages/vendemmia/ncm-management/NcmManagementPage';
import ContractManagementPage from '../pages/vendemmia/operation-specification/components/ContractManagementPage';
import OperationSpecificationPage from '../pages/vendemmia/operation-specification/OperationSpecificationPage';
import PolicyPrivacyPage from '../pages/vendemmia/policy/privacy/PolicyPrivacyPage';
import TermUsePage from '../pages/vendemmia/policy/term-of-use/TermUsePage';
import ProfitabilityPage from '../pages/vendemmia/profitability/ProfitabilityPage';
import { ProtestLettersPage } from '../pages/vendemmia/protest-letters/ProtestLettersPage';
import QuoteDetailPage from '../pages/vendemmia/quote/QuoteDetailPage';
import QuotePage from '../pages/vendemmia/quote/QuotePage';
import ReportGeneratorPage from '../pages/vendemmia/report-generator/ReportGeneratorPage';
import ReportViewPage from '../pages/vendemmia/report-view/ReportViewPage';
import { SuppliersPage } from '../pages/vendemmia/suppliers/SuppliersPage';
import SystemReportsPage from '../pages/vendemmia/system-reports/SystemReportsPage';
import TaxInquiryDetailPage from '../pages/vendemmia/tax-inquiry/components/TaxInquiryDetailPage';
import TaxInquiryPage from '../pages/vendemmia/tax-inquiry/TaxInquiryPage';
import { TruckingRateQuotePage } from '../pages/vendemmia/trucking-rate-quote/TruckingRateQuotePage';
import UserManagementPage from '../pages/vendemmia/user-management/UserManagementPage';

export const VendemmiaRoutes = (
  <>
    <Route exact path="/vendemmia/quote" element={<QuotePage />} />
    <Route exact path="/vendemmia/quote/new" element={<QuoteDetailPage />} />
    <Route exact path="/vendemmia/quote/detail/:identifier" element={<QuoteDetailPage />} />
    <Route exact path="/vendemmia/trucking-rate-quote" element={<TruckingRateQuotePage />} />
    <Route exact path="/vendemmia/operation-specification" element={<OperationSpecificationPage />} />
    <Route exact path="/vendemmia/operation-specification/new" element={<ContractManagementPage />} />
    <Route exact path="/vendemmia/operation-specification/detail/:identifier/:isPreviwer" element={<ContractManagementPage />} />
    <Route exact path="/vendemmia/operation-specification/detail/:identifier" element={<ContractManagementPage />} />
    <Route exact path="/vendemmia/credit-analysis" element={<CreditAnalysisPage />} />
    <Route exact path="/vendemmia/credit-analysis/detail/:identifier" element={<CreditAnalysisDetailPage />} />
    <Route exact path="/vendemmia/contract-management" element={<ModuleContractManagementPage />} />
    <Route exact path="/vendemmia/contract-management/new" element={<ContractManagementDetailPage />} />
    <Route exact path="/vendemmia/contract-management/detail/:identifier" element={<ContractManagementDetailPage />} />
    <Route exact path="/vendemmia/comex/billing" element={<BillingDashboardPage />} />
    <Route exact path="/vendemmia/comex/profitability" element={<ProfitabilityPage />} />
    <Route exact path="/vendemmia/ai-assistant" element={<AiAssistantPage />} />
    <Route exact path="/vendemmia/report-generator" element={<ReportGeneratorPage />} />
    <Route exact path="/vendemmia/report-view" element={<ReportViewPage />} />
    <Route exact path="/vendemmia/tax-inquiry" element={<TaxInquiryPage />} />
    <Route exact path="/vendemmia/tax-inquiry/new" element={<TaxInquiryDetailPage />} />
    <Route exact path="/vendemmia/tax-inquiry/detail/:identifier" element={<TaxInquiryDetailPage />} />
    <Route exact path="/vendemmia/certificate" element={<CertificatePage />} />

    {/* Gestão */}
    <Route exact path="/vendemmia/system-reports" element={<SystemReportsPage />} />
    <Route exact path="/vendemmia/user-management" element={<UserManagementPage />} />
    <Route exact path="/vendemmia/client-management" element={<ClientManagementPage />} />
    <Route exact path="/vendemmia/faq" element={<FaqPage />} />
    <Route exact path="/vendemmia/policy/terms-of-use" element={<TermUsePage />} />
    <Route exact path="/vendemmia/policy/privacy" element={<PolicyPrivacyPage />} />
    <Route exact path="/vendemmia/additional-fields-management" element={<AdditionalFieldsManagement />} />
    <Route exact path="/vendemmia/ncm-management" element={<NcmManagementPage />} />
    <Route exact path="/vendemmia/ncm-management/new" element={<NcmDetailPage />} />
    <Route exact path="/vendemmia/ncm-management/detail/:identifier" element={<NcmDetailPage />} />
    <Route exact path="/vendemmia/protest-letters" element={<ProtestLettersPage />} />
    <Route exact path="/vendemmia/suppliers" element={<SuppliersPage />} />
    <Route exact path="/vendemmia/inconsistency" element={<InconsistencyPage />} />
  </>
);
