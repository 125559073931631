import React from 'react';

import { MdHelp } from 'react-icons/md';
import { ProgressBar } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';

import { Box, Divider, Flex, Image, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import pointStatus from '../../../../assets/svgs/trip/pointStatus.svg';
import truckIcon from '../../../../assets/svgs/trip/truckIcon.svg';
import Card from '../../../../components/Card/Card';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

import TrajectoryPublic from './Trajectory/TrajectoryPublic';

const TripStatus = ({ entity, setCenter, setZoom, tripPercentage, isLoading, additionalInformation }) => {
  const tooltipStart = (
    <>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
      {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
    </>
  );

  const tooltipEnd = (
    <>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
      {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
    </>
  );

  const percentage = (modality, status, typeExternalCode) => {
    let text;

    if (status === 'COMPLETED') {
      text = 'Viagem concluída';
    } else if (modality === 'FRETE_NAO_RASTREAVEL') {
      text = 'Porcentagem estimada de conclusão com base nos horários previstos';
    } else if (modality === 'APP_MOTORISTA' || typeExternalCode === 'OPENTECH') {
      text = 'Porcentagem estimada de conclusão com base na distância';
    } else {
      text = 'Carregando...';
    }

    return text;
  };

  return (
    <ScreenLoader h="auto" isLoading={isLoading}>
      <Card
        // maxH="calc(100vh - 366px)"
        header={
          <Flex w="full" h="45px" justify="space-between">
            <Flex gap="5px" align="center">
              <Box h="31px" w="3px" bgColor="#6C48C2" />

              <Text fontSize={{ sm: '18px', md: '20px' }} fontWeight="bold">
                Status da viagem
              </Text>
            </Flex>

            <Flex gap="5px" align="center">
              <Box h="9px" w="9px" bgColor="#2ECC71" borderRadius="50%" />

              <Text fontSize={{ sm: '14px', md: '16px' }}>{entity.statusReadable}</Text>
            </Flex>
          </Flex>
        }>
        <Flex h="full" direction="column" m={{ sm: '10px', md: '20px' }} gap="20px" overflow="auto">
          {tripPercentage !== 100 && (
            <Box position="relative" h="0px" m="20px">
              <ProgressBar percent={tripPercentage} filledBackground="#6C48C2" height="3px" />
              <Image
                src={pointStatus}
                position="absolute"
                top="-9px"
                left="-10px"
                width="20px"
                alt="Starting Point"
                filter={`grayscale(${entity?.status == 'TRAVELLING' && tripPercentage > 0 ? 0 : 100}%)`}
              />

              {/* Imagem do caminhão seguindo o progresso */}
              {entity?.status == 'TRAVELLING' && (
                <Tooltip bg="white" color="#6C48C2" placement="right" p="2" shadow="lg" label={`${`${entity?.percentageCompletion}%`}`}>
                  <Image
                    src={truckIcon}
                    width="24px"
                    position="absolute"
                    top="-43px"
                    left={`calc(${tripPercentage}% - 55px)`}
                    transition="left 0.5s ease"
                    transform="rotate(90deg)"
                    alt="Truck Icon"
                    zIndex={1}
                  />
                </Tooltip>
              )}

              {/* Ícone no fim */}
              <Image
                src={pointStatus}
                position="absolute"
                top="-9px"
                right="-10px"
                width="20px"
                filter={`grayscale(${entity?.status == 'COMPLETED' ? 0 : 100}%)`}
                alt="Ending Point"
              />
              <Box mt="4" color="#5F5B68">
                <Text textStyle="subtitle" textColor="gray-250" fontSize={'12px'}>
                  {`* ${percentage(entity?.modality, entity?.status, entity?.typeExternalCode)}`}
                </Text>
              </Box>
            </Box>
          )}

          {tripPercentage !== 100 && <Divider borderBottomWidth="2px" />}

          <Flex direction="column" gap={2}>
            <Flex>
              <Flex width={{ base: '5.313rem', md: '9.375rem' }} fontWeight="bold" ml="4">
                Viagem
              </Flex>

              <Flex flex="1" fontWeight="bold">
                Início
              </Flex>

              <Flex flex="1" fontWeight="bold">
                Fim
              </Flex>
            </Flex>

            <Flex border="1px solid" borderColor="#6C48C2" borderRadius="md">
              <Flex p={2} width="9.375rem">
                <Text color="#6C48C2">Previsão</Text>
              </Flex>

              <Flex flex="1" p={2}>
                {entity?.startsAtFormatted ?? '-'}
              </Flex>

              <Flex flex="1" p={2} alignItems="center">
                <>
                  <Text>{entity?.endsAtFormatted}</Text>

                  {entity?.status == 'TRAVELLING' && (
                    <>
                      <Text color="#6C48C2" ml="2">
                        {`${`${entity?.percentageCompletion}%`}`}
                      </Text>

                      <Tooltip label="Porcentagem estimada de conclusão com base na distância">
                        <Box style={{ paddingLeft: '2px' }}>
                          <MdHelp color="#422C76" />
                        </Box>
                      </Tooltip>
                    </>
                  )}
                </>
              </Flex>
            </Flex>

            {additionalInformation?.estimatedEndsAt && (
              <Flex border="1px solid" borderColor="#6C48C2" borderRadius="md">
                <Flex p={2} width="9.375rem">
                  <Text color="#6C48C2">Estimado *</Text>
                </Flex>

                <Flex flex="1" p={2}>
                  -
                </Flex>

                <Flex flex="1" p={2}>
                  {additionalInformation?.estimatedEndsAt}
                </Flex>
              </Flex>
            )}

            <Flex border="1px solid" borderColor="#6C48C2" borderRadius="md">
              <Flex p={2} width="150px">
                <Text color="#6C48C2">Efetivo</Text>
              </Flex>

              <Flex flex="1" p={2} alignItems="center">
                {entity?.startsEtdFormatted != null ? (
                  <>
                    <Text>{entity?.startsEtdFormatted}</Text>

                    <Tooltip label={tooltipStart}>
                      <Box style={{ paddingLeft: '2px' }}>
                        <MdHelp color="#422C76" />
                      </Box>
                    </Tooltip>
                  </>
                ) : (
                  '-'
                )}
              </Flex>

              <Flex flex="1" p={2} alignItems="center">
                {entity?.endsEtdFormatted != null ? (
                  <>
                    <Text>{entity?.endsEtdFormatted}</Text>

                    <Tooltip label={tooltipEnd}>
                      <Box style={{ paddingLeft: '2px' }}>
                        <MdHelp color="#422C76" />
                      </Box>
                    </Tooltip>
                  </>
                ) : (
                  '-'
                )}
              </Flex>
            </Flex>

            <Flex color="#5F5B68">
              {additionalInformation?.endsMessage != null && entity.status == 'TRAVELLING' && (
                <Text textStyle="subtitle" textColor="gray-250" fontSize={'12px'}>
                  {`* ${additionalInformation?.endsMessage}`}
                </Text>
              )}
            </Flex>
          </Flex>

          <Divider borderBottomWidth="2px" />

          <TrajectoryPublic entity={entity} setCenter={setCenter} setZoom={setZoom} />
        </Flex>
      </Card>
    </ScreenLoader>
  );
};

export default TripStatus;
