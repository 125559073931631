import React, { useState } from 'react';

import { MdHelp } from 'react-icons/md';
import Select from 'react-select';

import { Box, Divider, Flex } from '@chakra-ui/layout';
import { FormControl, FormLabel, Table, Tbody, Td, Thead, Tooltip, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import { requests } from '../../../../services/v2/requests';
import { executeRequest } from '../../../../utils/requests/executeRequest';

const NcmQueryTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ncmOptions, setNcmOptions] = useState([]);
  const [list, setList] = useState([]);
  const [selectedNcm, setSelectedNcm] = useState(null);

  const listOperationNcmsAction = async (code = '') => {
    if (code?.length < 4) {
      return;
    }

    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/ncm/list?search=${code}&`,
          pageSize: 999,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    const formattedOptions = res.data.data.map((item) => ({
      label: `${item.code} - ${item.description}`,
      value: item.code,
    }));

    setNcmOptions(formattedOptions);
  };

  const getListProductNcms = async (ncm) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/ncm/${ncm}/attribute/show`,
          endpoint: true,
          customToken: 'customToken',
        }),
      setIsLoading,
    });

    setList(res.data);
  };

  return (
    <Flex w="full" direction="column" p="25px">
      <FormControl onPaste={(e) => listOperationNcmsAction(e.target.value)}>
        <FormLabel htmlFor="ncm">NCM</FormLabel>

        <Select
          styles={{
            control: (base, state) => ({
              ...base,
              height: '47px',
              backgroundColor: '#F5F5F5',
              border: state.isFocused || state.isActive ? '1px solid #6C48C2' : '1px solid #ccc',
              boxShadow: state.isFocused || state.isActive ? '0 0 0 1px #6C48C2' : 'none',
              '&:hover': {
                border: '1px solid #6C48C2',
              },
            }),
          }}
          placeholder="Digite os quatro primeiros dígitos do código NCM. Ex: 0101"
          isClearable
          value={selectedNcm}
          options={ncmOptions}
          onChange={(option) => {
            setSelectedNcm(option);
            setList([]);
            if (option) {
              getListProductNcms(option.value);
            }
          }}
          onInputChange={(inputValue) => {
            listOperationNcmsAction(inputValue);
          }}
        />
      </FormControl>

      <Divider my="20px" />

      <ScreenLoader isLoading={isLoading}>
        <Table>
          <Thead>
            <Tr>
              <Td></Td>
              <Td>Descrição</Td>
              <Td>Atributo obrigatório</Td>
              <Td>Forma de preenchimento</Td>
            </Tr>
          </Thead>

          <Tbody>
            {list.length > 0 ? (
              <>
                {list.map((entity, index) => (
                  <Tr key={index}>
                    <Td>
                      <Flex color="linkColor" fontWeight="bold">
                        {entity.codigo}
                      </Flex>
                    </Td>

                    <Td>
                      <Flex>{entity.nome}</Flex>
                    </Td>

                    <Td>
                      <Flex>{entity.obrigatorio === true ? 'Sim' : 'Não'}</Flex>
                    </Td>

                    <Td>
                      <Flex gap="5px">
                        {entity.formaPreenchimento}

                        <Tooltip label={entity.orientacaoPreenchimento}>
                          <Box>
                            <MdHelp color="#422C76" />
                          </Box>
                        </Tooltip>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Flex justify="center" my="25px">
                    Nenhum resultado encontrado para a seleção atual.
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </ScreenLoader>
    </Flex>
  );
};

export default NcmQueryTable;
