import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfigAttributes = {
  // Estrutura da tabela
  title: ' ',
  categories: [
    {
      title: 'Código',
      value: 'atributo',
      defaultValue: '-',
    },
    {
      title: 'Descrição',
      value: 'descricao',
      defaultValue: '-',
      align: 'center',
    },
    {
      title: 'Valor',
      value: 'valor',
      defaultValue: '-',
    },
  ],
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 35,
  // Estilo
  resizeRows: '1fr 1fr 1fr 50px',
};
