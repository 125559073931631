import axios from 'axios';
import { toast } from 'react-toastify';

const apiClient = axios.create();

apiClient.interceptors.response.use(
  (response) => {
    let token = window.localStorage.getItem('session-token') ?? null;
    if (token) {
      let loggedAt = new Date(JSON.parse(atob(token.split('.')[1]))?.iat * 1000);
      if (loggedAt < new Date(1669127572000)) {
        window.localStorage.clear();
      }
    }

    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      window.localStorage.removeItem('session-token');
    } else if (error.response?.status === 500) {
      toast.error('Algo deu errado, tente novamente mais tarde!');
    }

    if (error.response) {
      if (error.response.data.errors && error.response.data.errors.length > 0) {
        // error.response.data.errors.map((error) => {
        //   return toast.error(error.message);
        // });
      } else if (error.response.data.message) {
        if (error.response.data.message === 'Invalid JWT Token' || error.response.data.message === 'Expired JWT Token') {
          toast.info('Seu acesso expirou, faça sua autenticação novamente.', {
            toastId: 'error-jwt-token-expired',
          });
        } else if (error.response.data.message === 'Invalid credentials.') {
          toast.error('Combinação de e-mail e senha inválida.');
        } else {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        toastId: 'error-generic',
      });
    }

    return Promise.reject(error);
  }
);

export default apiClient;
