import React, { useState } from 'react';

import { MdListAlt } from 'react-icons/md';

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../../components/CommonDrawer/CommonDrawer';

const ProcessesInconsistentTable = ({ data }) => {
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure();

  // Estado para armazenar o item selecionado
  const [selectedEntity, setSelectedEntity] = useState(null);

  return (
    <Flex h="600px" w="full" direction="column" p="25px" overflow="auto">
      <Table minW="1300px">
        <Thead>
          <Tr className="border">
            <Th>
              <Box p="10px!important">&nbsp;</Box>
            </Th>
            <Th>
              <Box p="10px!important">Processo</Box>
            </Th>
            <Th>
              <Box p="10px!important" justifyContent={'center'}>
                Erros
              </Box>
            </Th>
            <Th>
              <Box p="10px!important" justifyContent={'center'}>
                Visualizar
              </Box>
            </Th>
            <Th>
              <Box p="10px!important">&nbsp;</Box>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.length > 0 ? (
            data.map((entity, key) => (
              <Tr className="border" key={key}>
                <Td>
                  <Box p="10px!important">&nbsp;</Box>
                </Td>
                <Td>
                  <Flex p="10px!important">{entity.process ?? '-'}</Flex>
                </Td>
                <Td>
                  <Flex p="10px!important" justifyContent={'center'}>
                    {entity.count ?? '-'}
                  </Flex>
                </Td>
                <Td>
                  <Flex p="10px!important" justifyContent={'center'}>
                    <Tooltip label="Lista de erros">
                      <Box>
                        <MdListAlt
                          size={25}
                          cursor="pointer"
                          color="#422C76"
                          onClick={() => {
                            setSelectedEntity(entity); // Atualiza o item selecionado
                            drawerOnOpen(); // Abre o drawer
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Td>
                <Td>
                  <Box p="10px!important">&nbsp;</Box>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className="border">
              <Td colSpan={5}>
                <Flex justify="center">Não há resultados que correspondam aos filtros selecionados.</Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* Drawer para exibir os processos do item selecionado */}
      <CommonDrawer placement="left" heading={'Lista de erros'} isOpen={drawerIsOpen} onClose={drawerOnClose}>
        <Table w="full">
          <Thead>
            <Tr>
              <Th>Etapa</Th>
              <Th>Título</Th>
              <Th>Descrição</Th>
            </Tr>
          </Thead>
          <Tbody>
            {selectedEntity?.errors?.map((item, index) => (
              <Tr key={index}>
                <Td>{item.stage}</Td>
                <Td>{item.title}</Td>
                <Td>{item.description}</Td>
              </Tr>
            )) || (
              <Tr>
                <Td colSpan={3}>
                  <Flex justify="center">Nenhum erro disponível</Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </CommonDrawer>

      {/* <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} /> */}
    </Flex>
  );
};

export default ProcessesInconsistentTable;
