import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  // Estrutura da tabela
  categories: [
    {
      title: 'Cód. produto',
      /* field: 'e.originModality', */
      value: 'productCode',
      align: 'center',
    },
    {
      title: 'Estoque anterior',
      value: 'stockPreviousMonthAvailable',
      type: 'label',
      align: 'center',
      /* tooltip: 'Teste', */
    },
    {
      title: 'Estoque atual',
      value: 'stockTodayAvailable',
      type: 'label',
      align: 'center',
      /* tooltip: 'Teste', */
    },
    {
      title: 'Giro de estoque',
      value: 'stockTurnover',
      align: 'center',
    },
    {
      title: 'Cliente',
      value: 'clientName',
      align: 'center',
    },
    {
      title: 'Armazem',
      value: 'warehouse',
      align: 'center',
    },
    {
      title: 'Curva ABC',
      variantTag: 'softDashed',
      value: 'curve',
      align: 'center',
    },
  ],
  subCategories: [
    {
      title: 'Descrição',
      value: 'productDescription',
      defaultValue: 'não possui',
    },
  ],
  mainCategoryMobile: 'idNotafiscal',
  // Funcionalidades
  hasCollapsed: true,
  hasPagination: true,
  collapsedContentType: 'table',
  tags: ['curve'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'A', value: 'A' },
    { color: '#F1C40F', label: 'B', value: 'B' },
    { color: '#E74C3C', label: 'C', value: 'C' },
  ],
  pathSublist: '',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 22,
  maxLengthResumeChildren: 100,
  /* hasDeleteButton: true, */
  // Estilo
  resizeRows: '1fr 150px 150px 1fr 1fr 1fr 1fr',
  resizeRowsChildren: '1fr',
};
