import { useContext } from 'react';

import { MdOpenInNew } from 'react-icons/md';

import { Button, ButtonGroup, Text, Tooltip } from '@chakra-ui/react';

import CustomButtons from '../../../components/CommonList/components/CustomButtons';
import DeleteConfirmationModal from '../../../components/CommonList/components/DeleteConfirmationModal';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import { CommonListContext } from '../contexts/CommonListContext';
import { redirectToDetailsOnClick } from '../Helpers/redirectToDetailsOnClick';

const CustomButtonsRenderer = ({ hasDetailsButton, customButtons, rawItem, identifier, detailsRedirectEndpoint, content }) => {
  // Verifica se o dispositivo é mobile para ajustar a renderização
  const { isMobile } = useResponsiveCheck();
  // Obtém configurações do contexto, incluindo botões de exclusão
  const { hasDeleteButton, deleteButtonAction, deleteButtonSubtitle } = useContext(CommonListContext);

  // Handler para redirecionar para a página de detalhes do item
  const handleDetailsClick = (e) => {
    e.stopPropagation();
    redirectToDetailsOnClick(detailsRedirectEndpoint, identifier);
  };

  // Se nenhum botão for necessário, não renderiza nada
  if (!hasDetailsButton && !hasDeleteButton && !customButtons) return null;

  // Renderiza os botões customizados, se disponíveis
  const renderCustomButtons = () =>
    customButtons && (
      <CustomButtons buttons={customButtons} buttonProps={rawItem} identifier={identifier} subCategoriesProps={rawItem} />
    );

  // Renderiza o botão de detalhes se estiver habilitado
  const renderDetailsButton = () =>
    hasDetailsButton && (
      <Tooltip label="Ver detalhes">
        <Button variant="commonTable" onClick={handleDetailsClick}>
          <MdOpenInNew size={20} />
        </Button>
      </Tooltip>
    );

  // Renderiza o botão de exclusão se estiver habilitado
  const renderDeleteButton = () =>
    hasDeleteButton && (
      <Tooltip label="Excluir">
        <DeleteConfirmationModal
          deleteButtonAction={deleteButtonAction}
          identifier={identifier}
          deleteButtonSubtitle={deleteButtonSubtitle}
        />
      </Tooltip>
    );

  // Renderiza os botões em grupo para mobile, ou em linha com o conteúdo para desktop
  return isMobile ? (
    <ButtonGroup display="flex" pt="10px" mt="15px" borderTop="1px solid #70707036" gap="10px" data-content-button>
      {renderCustomButtons()}
      {renderDetailsButton()}
      {renderDeleteButton()}
    </ButtonGroup>
  ) : (
    <Text display="flex" data-content-button>
      {renderCustomButtons()}
      {renderDetailsButton()}
      {renderDeleteButton()}
      {content}
    </Text>
  );
};

export default CustomButtonsRenderer;
