import React from 'react';

import { Route } from 'react-router-dom';

import CalendarPage from '../pages/comex/calendar/CalendarPage';
import CashFlowPage from '../pages/comex/cashflow/CashFlowPage';
import ForeignOperatorPage from '../pages/comex/foreign-operator/ForeignOperatorPage';
import ImportFilesPage from '../pages/comex/import-files/ImportFilesPage';
import KanbanPage from '../pages/comex/kanban/KanbanPage';
import MappingPage from '../pages/comex/mapping/MappingPage';
import ProcessDetailPage from '../pages/comex/process/ProcessDetailPage';
import ProcessPage from '../pages/comex/process/ProcessPage';
import CatalogManagementPage from '../pages/vendemmia/catalog-management/CatalogManagementPage';

export const ComexRoutes = (
  <>
    <Route exact path="/comex/process" element={<ProcessPage />} />
    <Route exact path="/comex/process/detail/:identifier" element={<ProcessDetailPage />} />
    <Route exact path="/comex/kanban" element={<KanbanPage />} />
    <Route exact path="/comex/calendar" element={<CalendarPage />} />
    <Route exact path="/comex/mapping" element={<MappingPage />} />
    <Route exact path="/comex/cashflow" element={<CashFlowPage />} />

    <Route exact path="/comex/catalog-management" element={<CatalogManagementPage />} />
    <Route exact path="/comex/foreign-operator" element={<ForeignOperatorPage />} />
    <Route exact path="/comex/import-files" element={<ImportFilesPage />} />
  </>
);
