import React from 'react';

import { Route } from 'react-router-dom';

import KPIComexPage from '../pages/metrics/KPIComex/KPIComexPage';
import KPILmsPage from '../pages/metrics/KPILms/KPILmsPage';
import KPIWmsPage from '../pages/metrics/KPIWms/KPIWmsPage';
import MetricsAnalyticsPage from '../pages/metrics/MetricsAnalytics/MetricsAnalyticsPage';
import MetricsCarbonPage from '../pages/metrics/MetricsCarbon/MetricsCarbonPage';
import MetricsWarehousePage from '../pages/metrics/MetricsWarehouse/MetricsWarehousePage';

export const MetricsRoutes = (
  <>
    <Route exact path="/metrics/analytics" element={<MetricsAnalyticsPage />} />
    <Route exact path="/metrics/warehouse" element={<MetricsWarehousePage />} />
    <Route exact path="/metrics/kpi-comex" element={<KPIComexPage />} />
    <Route exact path="/metrics/kpi-warehouse" element={<KPIWmsPage />} />
    <Route exact path="/metrics/kpi-lms" element={<KPILmsPage />} />
    <Route exact path="/metrics/kpi-carbon" element={<MetricsCarbonPage />} />
  </>
);
