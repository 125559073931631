import { useEffect, useMemo, useState } from 'react';

import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import addRadioInputPreset from '../Helpers/addRadioInputPreset';
import extractIdentifiers from '../Helpers/extractIdentifiers';
import { FormattedList } from '../Helpers/formattedList';

export const useCommonListProps = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { children, ...rest } = props;

  //default properties
  const { list, categories } = rest;
  const subCategories = rest?.subCategories;

  //data
  const data = rest?.rawData;

  //responsiveness
  const { isMobile, deviceType, viewport } = useResponsiveCheck();
  const mainCategoryMobile = rest?.mainCategoryMobile;

  //methods
  const setMetadata = rest?.setMetadata;
  const setAction = rest?.setAction;

  //conditionally
  //buttonDetails
  const hasDetailsButton = rest?.hasDetailsButton;
  const detailsRedirectEndpoint = rest?.detailsRedirectEndpoint;
  const hasDetailsButtonSublist = rest?.hasDetailsButtonSublist;
  const detailsRedirectEndpointSublist = rest?.detailsRedirectEndpointSublist;
  //buttonDelete
  const hasDeleteButton = rest?.hasDeleteButton;
  const deleteButtonAction = rest?.deleteButtonAction;
  const deleteButtonSubtitle = rest?.deleteButtonSubtitle;
  //customButtons
  const customButtons = rest?.customButtons;

  //customFields
  const customFields = rest?.customFields;
  const radioBgColor = addRadioInputPreset(customFields);

  //customFilters
  const customFilters = rest?.customFilters;

  //ActionButtons
  const actionButtons = rest?.actionButtons;

  //modal
  const expandableRow = rest?.expandableRow ?? true;

  //pagination
  const action = rest?.action;
  const metadata = rest?.metadata;
  const hasPagination = rest?.hasPagination;
  const hasPages = rest?.metadata?.total_pages > 1;
  //collapsed
  const hasCollapsed = rest?.hasCollapsed;
  const collapsedContentType = rest?.collapsedContentType;
  //status
  const tags = rest?.tags;
  const statusPreset = rest?.statusPreset;
  //images
  const images = rest?.images;
  const imagePreset = rest?.imagePreset;

  //ordering
  const sorting = rest?.sorting;
  const handleSort = rest?.handleSort;

  //instance
  const title = rest?.title;
  const subtitle = rest?.subtitle;
  const subcategories = rest?.subCategories;
  const subList = rest?.subList ? rest.subList : [];
  const pathSublist = rest?.pathSublist;

  //styles
  const resetStyles = rest?.resetStyles ?? false;

  //verify
  const hasHeading = !!(title || subtitle);

  //loader
  const isLoading = rest?.isLoading ?? false;

  //extrai todos os identifiers para utilizar em ações como da página de editar
  const identifierKey = rest?.identifierKey;
  const identifier = list && extractIdentifiers(list, identifierKey);

  //formatar a lista removendo as categorias ocultas
  const formattedList = FormattedList(list, categories);

  //messages
  const emptyListMessage = rest?.emptyListMessage ?? 'Não existem registros disponíveis para exibição.';

  //resume
  const maxLengthResume = rest?.maxLengthResume ?? 70;
  const maxLengthResumeChildren = rest?.maxLengthResumeChildren ?? 35;

  //rowSize
  const cellRowSize = rest?.cellRowSize ?? '1fr';
  const resizeRows = rest?.resizeRows ?? cellRowSize;
  const resizeRowsChildren = rest?.resizeRowsChildren ?? cellRowSize;

  //length
  let categoryLength = categories?.length;

  let gridTemplateLength = categoryLength + 1;
  let listLength;

  listLength = list && list.length !== 0 ? Object.keys(list[0]).length : 0;

  children || hasDetailsButton || hasCollapsed ? listLength++ : listLength;
  listLength < categoryLength ? (listLength = categoryLength) : (categoryLength = listLength);
  gridTemplateLength = categoryLength === listLength ? categoryLength : categoryLength + 1;
  gridTemplateLength = `repeat(${gridTemplateLength}, ${cellRowSize})`;

  gridTemplateLength = resizeRows ? resizeRows : gridTemplateLength;
  //calcula as colunas corretamente quando se adiciona/vazia o titulo ou children

  // Memoizing the condition for `isLoaded` using useMemo
  const isContentLoaded = useMemo(() => {
    if (!isLoading && list?.length > 0) {
      return true;
    } else {
      return list?.length > 0 ? false : true;
    }
  }, [isLoading, list]);

  // Atualiza a lista conforme realiza uma nova chamada a API
  useEffect(() => {
    setIsLoaded(isContentLoaded);
  }, [isContentLoaded]);

  useEffect(() => {
    const loadProps = async () => {};

    loadProps();
  }, [props]);

  return {
    //default properties
    list,
    categories,
    subCategories,
    //data
    data,
    //responsiveness
    isMobile,
    deviceType,
    viewport,
    mainCategoryMobile,
    //methods
    setMetadata,
    setAction,
    //conditionally
    //buttonDetails
    hasDetailsButton,
    detailsRedirectEndpoint,
    hasDetailsButtonSublist,
    detailsRedirectEndpointSublist,
    //buttonDelete
    hasDeleteButton,
    deleteButtonAction,
    deleteButtonSubtitle,
    //customButtons
    customButtons,
    //customFields
    customFields,
    //customFilters
    customFilters,
    //actionButtons
    actionButtons,
    radioBgColor,
    //modal
    expandableRow,
    //verify
    hasHeading,
    //styles
    resetStyles,
    //pagination
    action,
    metadata,
    hasPagination,
    hasPages,
    //collapsed
    hasCollapsed,
    collapsedContentType,
    //status
    tags,
    statusPreset,
    //images
    images,
    imagePreset,
    //ordering
    sorting,
    handleSort,
    //instance
    title,
    subtitle,
    subcategories,
    subList,
    pathSublist,
    //loader
    isLoading,
    isLoaded,
    //others
    //extrai todos os identifiers para utilizar em ações como da página de editar
    identifier,
    //formatar a lista removendo as categorias ocultas
    formattedList,
    //messages
    emptyListMessage,
    //resume
    maxLengthResume,
    maxLengthResumeChildren,
    //rowSize
    cellRowSize,
    resizeRows,
    resizeRowsChildren,
    gridTemplateLength,
  };
};
