import React, { useContext } from 'react';

import { CommonListContext } from '../contexts/CommonListContext';

export const Controller = () => {
  // Utiliza o contexto para acessar os filtros personalizados
  const { customFilters } = useContext(CommonListContext);

  return (
    <>
      {customFilters?.map(({ filter }, index) => (
        <React.Fragment key={index}>{filter}</React.Fragment>
      ))}
    </>
  );
};
