import * as Yup from 'yup';

// Regex para validação de CEP
const cepRegex = /^\d{5}-\d{3}$/;

// Schema de validação para o formulário de cotação de frete
export const validationTruckingRateQuoteScheme = Yup.object().shape({
  // Seção de informações gerais
  responsible: Yup.object().nullable().required('Responsável é obrigatório'),
  process: Yup.array().nullable(),
  trip: Yup.object().nullable(),

  // Seção de Empresa e Rota - Origem
  companyOrigin: Yup.object().nullable().required('CNPJ de origem é obrigatório'),
  cepOrigin: Yup.string().matches(cepRegex, 'CEP inválido. Use o formato 00000-000').nullable(),
  stateOrigin: Yup.object().nullable().required('Estado de origem é obrigatório'),
  cityOrigin: Yup.object().nullable().required('Cidade de origem é obrigatória'),
  addressOrigin: Yup.string().nullable(),

  // Seção de Empresa e Rota - Destino
  companyDestination: Yup.object().nullable().required('CNPJ de destino é obrigatório'),
  cepDestination: Yup.string().matches(cepRegex, 'CEP inválido. Use o formato 00000-000').nullable(),
  stateDestination: Yup.object().nullable().required('Estado de destino é obrigatório'),
  cityDestination: Yup.object().nullable().required('Cidade de destino é obrigatória'),
  addressDestination: Yup.string().nullable(),

  // Distância
  distance: Yup.number().typeError('Quilometragem deve ser um número').positive('Quilometragem deve ser um valor positivo').nullable(),

  // Seção de Valores Base
  goodsValue: Yup.number()
    .typeError('Valor da mercadoria deve ser um número')
    .positive('Valor da mercadoria deve ser positivo')
    .nullable(),
  goodsWeight: Yup.number().typeError('Peso da carga deve ser um número').positive('Peso da carga deve ser positivo').nullable(),
  freightWeightMonetary: Yup.number().typeError('Frete peso deve ser um número').positive('Frete peso deve ser positivo').nullable(),
  freightRiskPercent: Yup.number()
    .typeError('ADV % deve ser um número')
    .min(0, 'ADV % não pode ser negativo')
    .max(100, 'ADV % não pode ser maior que 100')
    .nullable(),
  freightValueMonetary: Yup.number().typeError('ADV deve ser um número').min(0, 'ADV não pode ser negativo').nullable(),
  freightValuePercent: Yup.number()
    .typeError('Gris % deve ser um número')
    .min(0, 'Gris % não pode ser negativo')
    .max(100, 'Gris % não pode ser maior que 100')
    .nullable(),
  freightRiskMonetary: Yup.number().typeError('Gris deve ser um número').min(0, 'Gris não pode ser negativo').nullable(),

  // Seção de Pedágio
  vehicleModality: Yup.object().nullable(),
  truckAxleQuantity: Yup.number()
    .typeError('Quantidade de eixos deve ser um número')
    .integer('Quantidade de eixos deve ser um número inteiro')
    .min(1, 'Quantidade de eixos deve ser pelo menos 1')
    .nullable(),
  truckAxleMonetary: Yup.number()
    .typeError('Custo por eixo deve ser um número')
    .min(0, 'Custo por eixo não pode ser negativo')
    .nullable(),
  tollMonetary: Yup.number()
    .typeError('Valor total do pedágio deve ser um número')
    .min(0, 'Valor total do pedágio não pode ser negativo')
    .nullable(),

  // Seção de Prestação de serviços
  servicesMonetary: Yup.number()
    .typeError('Valor do serviço deve ser um número')
    .min(0, 'Valor do serviço não pode ser negativo')
    .nullable(),

  // Seção de Impostos
  pisCofinsBasePercent: Yup.number()
    .typeError('Base PIS/COFINS % deve ser um número')
    .min(0, 'Base PIS/COFINS % não pode ser negativo')
    .max(100, 'Base PIS/COFINS % não pode ser maior que 100')
    .nullable(),
  pisCofinsBaseMonetary: Yup.number()
    .typeError('Base PIS/COFINS deve ser um número')
    .min(0, 'Base PIS/COFINS não pode ser negativo')
    .nullable(),
  pisPercent: Yup.number()
    .typeError('PIS % deve ser um número')
    .min(0, 'PIS % não pode ser negativo')
    .max(100, 'PIS % não pode ser maior que 100')
    .nullable(),
  pisMonetary: Yup.number().typeError('PIS deve ser um número').min(0, 'PIS não pode ser negativo').nullable(),
  cofinsPercent: Yup.number()
    .typeError('COFINS % deve ser um número')
    .min(0, 'COFINS % não pode ser negativo')
    .max(100, 'COFINS % não pode ser maior que 100')
    .nullable(),
  cofinsMonetary: Yup.number().typeError('COFINS deve ser um número').min(0, 'COFINS não pode ser negativo').nullable(),
  icmsBasePercent: Yup.number()
    .typeError('Base ICMS % deve ser um número')
    .min(0, 'Base ICMS % não pode ser negativo')
    .max(100, 'Base ICMS % não pode ser maior que 100')
    .nullable(),
  icmsBaseMonetary: Yup.number().typeError('Base ICMS deve ser um número').min(0, 'Base ICMS não pode ser negativo').nullable(),
  icmsPercent: Yup.number()
    .typeError('Imposto ICMS/ISS % deve ser um número')
    .min(0, 'Imposto ICMS/ISS % não pode ser negativo')
    .max(100, 'Imposto ICMS/ISS % não pode ser maior que 100')
    .nullable(),
  icmsMonetary: Yup.number()
    .typeError('Imposto ICMS/ISS deve ser um número')
    .min(0, 'Imposto ICMS/ISS não pode ser negativo')
    .nullable(),

  // Seção de Totalizador
  totalMonetary: Yup.number()
    .typeError('Valor final deve ser um número')
    .positive('Valor final deve ser positivo')
    .required('Valor final é obrigatório'),
});
