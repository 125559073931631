import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

const NotificationModal = ({ callback, isOpen, onOpen, onClose, modalText }) => {
  const { isOpen: disclosureIsOpen, onOpen: disclosureOnOpen, onClose: disclosureOnClose } = useDisclosure();

  const handleOpen = () => {
    onOpen ? onOpen() : disclosureOnOpen();
  };

  const handleClose = () => {
    onClose ? onClose() : disclosureOnClose();
  };

  return (
    <Modal size="lg" isOpen={isOpen || disclosureIsOpen} onOpen={handleOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      
      <ModalContent p="0px" borderRadius="9px">
        <ModalBody display="flex" flexDirection="column" alignItems="center" justifyContent="center" p="20px 40px">
          <Text fontSize="19px" fontWeight="medium" textColor="#0F0A1D" textAlign="center">
            {modalText ? modalText : `Você tem certeza que deseja realizar essa alteração?`}
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="center" p="20px 40px" gap="20px">
          <Button variant="secundary" borderRadius="7px" w="120px" h="50px" onClick={() => callback(false)}>
            Cancelar
          </Button>

          <Button variant="primary" borderRadius="7px" w="120px" h="50px" onClick={() => callback(true)}>
            Continuar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NotificationModal;
