import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import { MdHelp } from 'react-icons/md';
import * as Yup from 'yup';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  ModalFooter,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import { requests } from '../../../../services/v2/requests';
import { executeRequest } from '../../../../utils/requests/executeRequest';

const DynamicFormAttributes = ({ identifierProduct, product, getAttributes, closeAttributesDrawer }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({ attributes: [] });

  // Função para inicializar os atributos recursivamente
  const initializeAttributes = useCallback((attributes) => {
    return attributes.map((attr) => ({
      ...attr,
      checked: attr.checked || false,
      value: attr.value || '',
      subAtributos: attr.temSubAtributos ? initializeAttributes(attr.subAtributos || []) : [],
    }));
  }, []);

  // Busca os atributos da API
  const fetchAttributes = useCallback(
    async (ncm, identifier) => {
      setIsLoading(true);
      try {
        const response = await executeRequest({
          action: () =>
            requests.get({
              url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/ncm/${ncm}/attribute/show?search=${identifier}&`,
              endpoint: true,
              customToken: 'customToken',
            }),
          setIsLoading,
        });

        const apiData = response?.data || [];
        setInitialValues({ attributes: initializeAttributes(apiData) });
      } catch (error) {
        console.error('Error fetching attributes:', error);
        setInitialValues({ attributes: [] });
      } finally {
        setIsLoading(false);
      }
    },
    [initializeAttributes]
  );

  // Schema de validação dinâmico corrigido
  const createValidationSchema = useCallback(() => {
    const attributeSchema = Yup.object().shape({
      checked: Yup.boolean(),
      value: Yup.mixed().when('checked', {
        is: true,
        then: (schema) => {
          // Usamos um teste genérico que funciona para todos os tipos
          return schema
            .test('is-valid', 'Campo obrigatório', function (value) {
              // Acessamos o contexto do atributo atual
              const { parent } = this;
              const isBoolean = parent.formaPreenchimento === 'BOOLEANO';

              if (isBoolean) {
                return value !== undefined && value !== null;
              }
              return value !== '' && value !== undefined && value !== null;
            })
            .required('Campo obrigatório');
        },
        otherwise: (schema) => schema.notRequired(),
      }),
      subAtributos: Yup.lazy((subAtributos) => {
        if (!subAtributos || !Array.isArray(subAtributos)) {
          return Yup.array().notRequired();
        }
        return Yup.array().of(attributeSchema);
      }),
    });

    return Yup.object().shape({
      attributes: Yup.array().of(attributeSchema),
    });
  }, []);

  // Função para preparar os dados para envio
  const prepareDataForApi = useCallback((attributes) => {
    const markLastChild = (attrs, parentChecked = true) => {
      return attrs
        .map((attr) => {
          const isActive = attr.checked && parentChecked;

          if (isActive) {
            const baseAttr = {
              atributo: attr.codigo,
              valor: attr.value,
              descricao: attr.nomeApresentacao,
            };

            if (attr.temSubAtributos) {
              const checkedSubAttributes = attr.subAtributos.filter((sub) => sub.checked);

              if (checkedSubAttributes.length > 0) {
                const subAttributesWithFlag = markLastChild(checkedSubAttributes, isActive);
                return [baseAttr, ...subAttributesWithFlag];
              }
            }

            // Se não tem subatributos ou nenhum subatributo está marcado
            return [{ ...baseAttr, deveSerEnviado: true }];
          }
          return [];
        })
        .flat();
    };

    return {
      attributes: markLastChild(attributes),
    };
  }, []);

  // Envio para API
  const submitAttributes = useCallback(
    async (values) => {
      const payload = prepareDataForApi(values.attributes);

      try {
        const response = await executeRequest({
          action: () =>
            requests.post({
              url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifierProduct}/attribute/add`,
              data: payload,
              endpoint: true,
              customToken: 'customToken',
            }),
          msgSuccess: 'Atributo vinculado com sucesso!.',
          setIsLoading,
        });

        if (response) {
          getAttributes(identifierProduct);
        }
      } catch (error) {
        console.error('Error saving attributes:', error);
      }
    },
    [identifierProduct, getAttributes, closeAttributesDrawer, prepareDataForApi]
  );

  // Componente para renderizar cada linha de atributo
  // eslint-disable-next-line react/display-name
  const AttributeRow = React.memo(
    ({ entity, index, fieldPrefix, handleChange, setFieldValue, errors, parentChecked = true, parentName }) => {
      const fieldPath = `${fieldPrefix}[${index}]`;
      const isDisabled = !parentChecked;
      const isRequired = entity.checked;

      const tipoPreenchimento = {
        TEXTO: 'Texto livre',
        LISTA_ESTATICA: 'Lista de opções',
        BOOLEANO: 'Sim/Não',
        NUMERO_INTEIRO: 'Número inteiro',
      };

      const handleCheckChange = (e) => {
        const isChecked = e.target.checked;
        setFieldValue(`${fieldPath}.checked`, isChecked);

        // Se desmarcar, limpa o valor e desmarca subatributos
        if (!isChecked) {
          setFieldValue(`${fieldPath}.value`, '');

          if (entity.temSubAtributos) {
            entity.subAtributos.forEach((sub, subIndex) => {
              setFieldValue(`${fieldPath}.subAtributos[${subIndex}].checked`, false);
              setFieldValue(`${fieldPath}.subAtributos[${subIndex}].value`, '');
            });
          }
        }
      };

      return (
        <Tr key={index} opacity={isDisabled ? 0.6 : 1}>
          <Td width="70px" borderBottom="0">
            <Flex align="center" justify="center">
              <Tooltip
                label={isDisabled ? `O atributo "${parentName}" deve estar marcado para liberar este atributo` : ''}
                isDisabled={!isDisabled}
                placement="right"
                hasArrow>
                <Box>
                  <Checkbox iconColor="primary" isChecked={entity.checked} onChange={handleCheckChange} isDisabled={isDisabled} />
                </Box>
              </Tooltip>
            </Flex>
          </Td>

          <Td width="130px" fontWeight="bold" textColor="#6C48C2" borderBottom="0">
            {entity.codigo}
          </Td>

          <Td borderBottom="0">{entity.nome}</Td>

          <Td width="190px" borderBottom="0">
            {entity.temSubAtributos ? 'Com sub-atributos' : 'Simples'}
          </Td>

          <Td width="230px" borderBottom="0">
            <Flex gap="5px" alignItems="flex-start">
              {tipoPreenchimento[entity.formaPreenchimento] || entity.formaPreenchimento}

              {entity.orientacaoPreenchimento && (
                <Tooltip label={entity.orientacaoPreenchimento}>
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Td>

          <Td width="400px" borderBottom="0">
            <FormControl isInvalid={errors?.[index]?.value} isRequired={isRequired && !isDisabled} isDisabled={isDisabled}>
              {entity.formaPreenchimento === 'BOOLEANO' || entity.formaPreenchimento === 'LISTA_ESTATICA' ? (
                <Select
                  w="full"
                  placeholder="Selecione uma opção"
                  name={`${fieldPath}.value`}
                  value={entity.value || ''}
                  onChange={handleChange}
                  isDisabled={isDisabled}
                  sx={{
                    height: '47px',
                    backgroundColor: '#F5F5F5',
                    border: '1px solid #70707036 !important',
                    boxShadow: 'none !important',
                    _focus: {
                      border: '1px solid #6C48C2',
                      boxShadow: '0 0 0 1px #6C48C2 !important',
                    },
                    _hover: {
                      border: '1px solid #6C48C2 !important',
                    },
                  }}>
                  {entity.options?.map((option, optIndex) => (
                    <option key={optIndex} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              ) : (
                <Input
                  w="full"
                  placeholder="Digite o valor"
                  name={`${fieldPath}.value`}
                  value={entity.value || ''}
                  onChange={(e) => {
                    if (entity.formaPreenchimento === 'NUMERO_INTEIRO') {
                      // Valida se é um número inteiro
                      if (e.target.value === '' || /^-?\d+$/.test(e.target.value)) {
                        handleChange(e);
                      }
                    } else {
                      handleChange(e);
                    }
                  }}
                  type={entity.formaPreenchimento === 'NUMERO_INTEIRO' ? 'number' : 'text'}
                  inputMode={entity.formaPreenchimento === 'NUMERO_INTEIRO' ? 'numeric' : 'text'}
                  pattern={entity.formaPreenchimento === 'NUMERO_INTEIRO' ? '-?\\d*' : undefined}
                  isDisabled={isDisabled}
                  sx={{
                    height: '47px',
                    backgroundColor: '#F5F5F5',
                    border: '1px solid #70707036 !important',
                    boxShadow: 'none !important',
                    _focus: {
                      border: '1px solid #6C48C2',
                      boxShadow: '0 0 0 1px #6C48C2 !important',
                    },
                    _hover: {
                      border: '1px solid #6C48C2 !important',
                    },
                  }}
                />
              )}
            </FormControl>
          </Td>

          <Td w="88px" borderBottom="0">
            {entity.temSubAtributos && (
              <AccordionButton p={0}>
                <IconButton variant="ghost" icon={<AccordionIcon />} />
              </AccordionButton>
            )}
          </Td>
        </Tr>
      );
    }
  );

  // Componente para renderizar os atributos recursivamente
  // eslint-disable-next-line react/display-name
  const AttributesList = React.memo(({ attributes, fieldPrefix, parentChecked = true, parentName = '', ...formikProps }) => {
    return attributes.map((entity, index) => {
      const currentPath = `${fieldPrefix}[${index}]`;
      const isActive = entity.checked && parentChecked;

      return (
        <AccordionItem
          key={`${entity.codigo}-${index}`}
          mb="10px"
          bgColor="white"
          border="1px solid #70707036"
          borderRadius="10px 10px 10px 10px">
          <Table bgColor="white" borderRadius="10px">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Código</Th>
                <Th>Descrição</Th>
                <Th>Tipo</Th>
                <Th>Forma de Preenchimento</Th>
                <Th>Valor</Th>
                <Th></Th>
              </Tr>
            </Thead>

            <Tbody>
              <AttributeRow
                entity={entity}
                index={index}
                fieldPrefix={fieldPrefix}
                parentChecked={parentChecked}
                parentName={parentName}
                {...formikProps}
              />
            </Tbody>
          </Table>

          {entity.temSubAtributos && (
            <AccordionPanel p="40px" bgColor="#F2F2F2" borderRadius="0px 0px 10px 10px">
              <Accordion allowMultiple>
                <AttributesList
                  attributes={entity.subAtributos}
                  fieldPrefix={`${currentPath}.subAtributos`}
                  parentChecked={isActive}
                  parentName={entity.codigo}
                  {...formikProps}
                />
              </Accordion>
            </AccordionPanel>
          )}
        </AccordionItem>
      );
    });
  });

  useEffect(() => {
    if (product?.ncmCode && identifierProduct) {
      fetchAttributes(product.ncmCode, identifierProduct);
    }
  }, [product?.ncmCode, identifierProduct, fetchAttributes]);

  return (
    <Formik initialValues={initialValues} validationSchema={createValidationSchema()} onSubmit={submitAttributes} enableReinitialize>
      {({ values, errors, handleChange, setFieldValue, isSubmitting, handleSubmit }) => {
        // Calcula se o botão deve estar desativado
        const isSaveDisabled = useMemo(() => {
          const hasCheckedAttributes = (attributes) => {
            return attributes.some((attr) => attr.checked || (attr.subAtributos && hasCheckedAttributes(attr.subAtributos)));
          };

          return !hasCheckedAttributes(values.attributes);
        }, [values.attributes]);

        return (
          <Form style={{ height: '100%' }} onSubmit={handleSubmit}>
            <style>
              {`
            .chakra-collapse {
              margin-top: 0px;
              border-top: 0px;
            }

            .chakra-accordion__item:nth-child(even),
            [role="list"] li:nth-child(even) div, 
            [role="list"] li .chakra-collapse div {
              background-color: transparent;
            }

            .chakra-accordion__item:nth-child(even) .chakra-collapse,
            [role="list"] li:nth-child(even) .chakra-collapse {
              background-color: transparent;
            }

            .chakra-modal__body {
              overflow: hidden 
            }

            .chakra-modal__content form {
              display: grid;
              flex: 1 1 0%;
              align-content: space-between;
            }
          `}
            </style>

            <Grid gridTemplateColumns="repeat(1, 1fr)" p="25px" gap="10px" overflow="auto">
              <FormControl>
                <FormLabel textColor="#6C48C2">Denominação</FormLabel>
                <InputDefault mb="8px" isReadOnly value={product?.denominacao || ''} />
              </FormControl>

              <FormControl>
                <FormLabel textColor="#6C48C2">Descrição</FormLabel>
                <InputDefault pt="10px" as="textarea" minH="150px" isReadOnly value={product?.descricao || ''} />
              </FormControl>

              <FormControl>
                <FormLabel textColor="#6C48C2">Descrição da NCM</FormLabel>
                <InputDefault pt="10px" as="textarea" minH="150px" isReadOnly value={product?.ncmDescription || ''} />
              </FormControl>

              {isLoading ? (
                <ScreenLoader isLoading={isLoading} />
              ) : (
                <Accordion allowMultiple>
                  <AttributesList
                    attributes={values.attributes}
                    fieldPrefix="attributes"
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors.attributes}
                    parentName=""
                  />
                </Accordion>
              )}
            </Grid>

            <ModalFooter borderTop="1px" borderColor="#7070702E" p="25px 0px 0px 0px">
              <Button
                h="47px"
                w="fit-content"
                variant="submit"
                type="submit"
                isLoading={isSubmitting}
                isDisabled={isLoading || isSaveDisabled}>
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.memo(DynamicFormAttributes);
