import React, { useState } from 'react';

import { MdListAlt, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../../components/CommonDrawer/CommonDrawer';
import { commonListMethods } from '../../../../components/CommonList/methods/commonListMethods';

const ProcessesErrorsTable = ({ data }) => {
  const { isLoading } = commonListMethods();
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure();

  // Estado para armazenar o item selecionado
  const [selectedEntity, setSelectedEntity] = useState(null);

  return (
    <Flex h="600px" w="full" direction="column" p="25px" overflow="auto">
      <Table w="full">
        <Thead>
          <Tr className="border">
            <Th>
              <Box p="10px!important">&nbsp;</Box>
            </Th>
            <Th>
              <Box p="10px!important">Etapa</Box>
            </Th>
            <Th>
              <Box p="10px!important">Título</Box>
            </Th>
            <Th>
              <Box p="10px!important">Descrição</Box>
            </Th>
            <Th>
              <Box p="10px!important">Processos</Box>
            </Th>
            <Th>
              <Box p="10px!important">Visualizar</Box>
            </Th>
            <Th>
              <Box p="10px!important">&nbsp;</Box>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.length > 0 ? (
            data.map((entity, key) => (
              <Tr className="border" key={key}>
                <Td>
                  <Box p="10px!important">&nbsp;</Box>
                </Td>
                <Td>
                  <Flex w="150px" p="10px!important">
                    {entity.stage ?? '-'}
                  </Flex>
                </Td>
                <Td>
                  <Flex p="10px!important">{entity.title ?? '-'}</Flex>
                </Td>
                <Td>
                  <Flex p="10px!important">{entity.description ?? '-'}</Flex>
                </Td>
                <Td>
                  <Flex p="10px!important" justifyContent={'center'}>
                    {entity.count ?? '-'}
                  </Flex>
                </Td>
                <Td>
                  <Flex p="10px!important" justifyContent={'center'}>
                    <Tooltip label="Lista de processos">
                      <Box>
                        <MdListAlt
                          size={25}
                          cursor="pointer"
                          color="#422C76"
                          onClick={() => {
                            setSelectedEntity(entity); // Atualiza o item selecionado
                            drawerOnOpen(); // Abre o drawer
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Td>
                <Td>
                  <Box p="10px!important">&nbsp;</Box>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className="border">
              <Td colSpan={7}>
                <Flex justify="center">Não há resultados que correspondam aos filtros selecionados.</Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* Drawer para exibir os processos do item selecionado */}
      <CommonDrawer placement="left" heading={'Lista de processos'} isOpen={drawerIsOpen} onClose={drawerOnClose}>
        <Table w="full">
          <Thead>
            <Tr>
              <Th>Processo</Th>
              <Th></Th>
              <Th>Processo</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {selectedEntity?.process
              ?.reduce((acc, item, index, array) => {
                if (index % 2 === 0) {
                  acc.push(array.slice(index, index + 2));
                }
                return acc;
              }, [])
              .map((pair, rowIndex) => (
                <Tr key={rowIndex}>
                  {pair.map((item, colIndex) => (
                    <React.Fragment key={colIndex}>
                      <Td p={'5px 24px'}>{item.code}</Td>
                      <Td p={'5px 24px'}>
                        <Flex p="10px!important">
                          <Tooltip label="Visualizar processo">
                            <Link to={`/comex/process/detail/${item.identifier}`} target="_blank">
                              <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                            </Link>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </React.Fragment>
                  ))}
                  {pair.length === 1 && <Td colSpan={2}></Td>} {/* Caso tenha número ímpar, adiciona célula vazia */}
                </Tr>
              )) || (
              <Tr>
                <Td colSpan={4}>
                  <Flex justify="center">Nenhum processo disponível</Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </CommonDrawer>
    </Flex>
  );
};

export default ProcessesErrorsTable;
