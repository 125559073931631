import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Flex, Grid, Image } from '@chakra-ui/react';

import logo from '../../../assets/images/logo.svg';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import requests from '../../../services/requests';

import DriverInfo from './components/DriverInfo';
import MapTrajectory from './components/Map/MapTrajectory';
import TripStatus from './components/TripStatus';

const TravelDetailPublicPage = () => {
  const { identifier, identifierLink } = useParams();
  const { viewport } = useResponsiveCheck();

  const [isLoadEntityLink, setIsLoadingEntityLink] = useState(true);
  const [entityLink, setEntityLink] = useState([]);

  const [tripPercentage, setTripPercentage] = useState();
  const [currentDriverPosition, setCurrentDriverPosition] = useState();
  const [additionalInformation, setAdditionalInformation] = useState({});

  const [center, setCenter] = useState({
    lat: -23.5792551,
    lng: -46.641581,
  });
  const [zoom, setZoom] = useState(5);

  const loadTravelLink = () => {
    setIsLoadingEntityLink(true);

    requests
      .getTripLink(identifier, identifierLink)
      .then((res) => setEntityLink(res))
      .catch(() => (window.location.href = '/public/tms/trip/invalid/link/invalid/share'))
      .finally(() => setIsLoadingEntityLink(false));
  };

  const loadCurrentDriverPosition = () => {
    requests.getCurrentDriverPosition(identifier).then((res) => setCurrentDriverPosition(res));
  };

  const loadTripPercentage = async () => {
    requests.tripPercentage(identifier).then((res) => setTripPercentage(res));
  };

  const getMetrics = (identifier) => {
    requests.metricsTrip(identifier).then((response) => {
      setAdditionalInformation(response);
    });
  };

  const renderMapAndInfo = () => (
    <Flex gap="10px" direction="column" textColor="#0F0A1D" className="up-anim">
      <DriverInfo entity={entityLink} viewport={viewport} isLoading={isLoadEntityLink} />

      <TripStatus
        entity={entityLink}
        setCenter={setCenter}
        setZoom={setZoom}
        tripPercentage={tripPercentage}
        isLoading={isLoadEntityLink}
        additionalInformation={additionalInformation}
      />
    </Flex>
  );

  useEffect(() => {
    loadTravelLink();
    loadCurrentDriverPosition();
    loadTripPercentage();

    if(identifier) {
      getMetrics(identifier);
    }
  }, [identifier, identifierLink]);

  return (
    <>
      <Box as="header" position="fixed" top="0" zIndex="1399" left="0" right="0" height="63px">
        <Flex w="full" h="full" bgColor="primary" align="center" justify="center">
          <Image src={logo} alt="logo vendemmia" />
        </Flex>
      </Box>

      {viewport > '1450' ? (
        <Grid h="calc(100vh - 20px)" mx="20px" gap="10px" pt="83px" templateColumns="700px 1fr">
          {renderMapAndInfo()}

          {!isLoadEntityLink && entityLink && (
            <MapTrajectory
              type="public"
              viewport={viewport}
              w="full"
              currentDriverPosition={currentDriverPosition}
              entity={entityLink}
              isLoadingEntity={isLoadEntityLink}
              events={null}
              checkpoints={null}    
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
            />
          )}
        </Grid>
      ) : (
        <Flex direction="column" mx="20px" pt="83px" pb="20px" gap="10px">
          {!isLoadEntityLink && entityLink && (
            <MapTrajectory
              h="500px"
              type="public"
              viewport={viewport}
              isLoadingEntity={isLoadEntityLink}
              entity={entityLink}
              currentDriverPosition={currentDriverPosition}
              setCenter={setCenter}
              center={center}
              setZoom={setZoom}
              zoom={zoom}
              events={null}
              checkpoints={null}
            />
          )}

          {renderMapAndInfo()}
        </Flex>
      )}
    </>
  );
};

export default TravelDetailPublicPage;
