import { requests } from '../requests';

/**
 * Retorna a URL completa baseada na entrada.
 * Se for uma URL relativa, adiciona a base API_URL.
 * @param {string} url - URL a ser processada.
 * @param {string} queryString - Query string opcional.
 * @returns {string} - URL completa.
 */
export const generateUrl = (url, queryString) => {
  //Environment
  const API_URL = requests.getApiUrl();

  // Define a URL completa
  const isFullUrl = /^https?:\/\//.test(url);
  return isFullUrl ? `${url}?${queryString}` : `${API_URL}${url}?${queryString}`;
};
