import md5 from 'md5';

import apiClient from './apiClient';
import { isAuthenticated } from './utils/auth';
import { generateQueryString } from './utils/filterUtils';
import { generateUrl } from './utils/generateUrl';
import { sanitizeUrl } from './utils/sanitizeUrl';

/**
 * Função para realizar chamadas dinâmicas à API.
 * @param {string} method - Método HTTP (GET, POST, PUT, DELETE, etc.).
 * @param {string} url - URL do endpoint.
 * @param {object} [options] - Opções adicionais para a chamada.
 * @param {object} [options.data] - Dados para enviar na requisição (usado para POST, PUT, etc.).
 * @param {boolean} [options.shouldCache] - Indica se a resposta deve ser armazenada em cache.
 * @param {string} [options.cacheKey] - Chave personalizada para o cache (opcional).
 * @param {boolean} [options.fullResponse] - Retornar a resposta completa em vez dos dados.
 * @param {boolean} [options.skipAuthorization] - Ignorar a inclusão do token de autorização.
 * @param {object} [options.config] - Configurações adicionais para a requisição.
 * @returns {Promise<any>} - Retorna os dados ou a resposta completa.
 */

export const apiRequest = async (methodConfig, options = {}) => {
  const {
    url: URL,
    data = null,
    filters = [],
    page = 1,
    pageSize = 15,
    sort = '',
    headers = {},
    responseType = 'json',
    shouldCache = false,
    cacheKey = md5(URL),
    cancelTokenSource = null,
    skipAuthorization = false,
    endpoint = false,
    customToken = null,
    config = {},
  } = options;

  // Gera a query string
  const queryString = generateQueryString(filters, page, pageSize, sort);

  // Gera a URL completa
  const url = generateUrl(URL, queryString);

  // Verifica se deve usar cache
  if (shouldCache) {
    const cachedData = getCache(cacheKey);
    if (cachedData) return cachedData;
  }

  try {
    // Faz a chamada API
    const response = await apiClient.request({
      url: sanitizeUrl(url),
      method: methodConfig.type,
      data, // Para métodos como POST e PUT
      responseType,
      headers: skipAuthorization
        ? headers
        : {
            Authorization: `${isAuthenticated(customToken)}`,
            ...headers,
          },
      cancelToken: cancelTokenSource?.token,
      ...config,
    });

    // Salva no cache, se necessário
    if (shouldCache) {
      setCache(cacheKey, response);
    }

    return response;
  } catch (error) {
    console.error(`API Request Error [${methodConfig.type} ${url}]:`, error);
    throw error;
  }
};

export const requests = {
  getApiUrl: () => process.env.REACT_APP_API_URL,
  getAuthUrl: () => process.env.REACT_APP_AUTH_URL,

  // Métodos genéricos utilizando apiRequest
  get: (options) => apiRequest({ type: 'GET' }, options),
  post: (options) => apiRequest({ type: 'POST' }, options),
  put: (options) => apiRequest({ type: 'PUT' }, options),
  delete: (options) => apiRequest({ type: 'DELETE' }, options),
  patch: (options) => apiRequest({ type: 'PATCH' }, options),
};
