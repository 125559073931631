import { useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import plane from '../../../../assets/images/aviao.svg';
import vessel from '../../../../assets/images/navio.svg';
import assume from '../../../../assets/svgs/assume.svg';
import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
import DefaultModal from '../../../../components/Modal/Template/DefaultModal';

// Mantido para documentação
const _docReference = commonListConfigDocumentation;

// Setup
export const commonListConfig = ({ handleChangesolicitor }) => {
  const [load, setLoad] = useState(false);

  let profileRole = localStorage.getItem('profile-role');
  let profileName = localStorage.getItem('profile-name');

  /**
   * Componente que exibe a referência com tooltip caso seja longa.
   */
  const ReferenceText = ({ reference }) => {
    if (!reference) return null;

    return reference.length <= 15 ? (
      <Text>{reference}</Text>
    ) : (
      <Tooltip label={reference}>
        <Text>{reference.substring(0, 10)}...</Text>
      </Tooltip>
    );
  };

  /**
   * Componente que encapsula a lógica do link do processo.
   */
  const ProcessLink = ({ processIdentifier, children }) => {
    if (!processIdentifier) return children;

    return (
      <Link to={`/comex/process/detail/${processIdentifier}`} target="_blank" style={{ color: '#6C48C2' }}>
        {children}
      </Link>
    );
  };

  /**
   * Renderiza o conteúdo da coluna "Referência" com ou sem link.
   */
  const RenderHTMLProcessIdentifier = (item) => {
    if (!item) return null;

    return (
      <ProcessLink processIdentifier={item.processIdentifier}>
        <Flex direction="column">
          <ReferenceText reference={item.reference} />
          <Text fontSize="sm">{`(${item.name})`}</Text>
        </Flex>
      </ProcessLink>
    );
  };

  return {
    title: '',
    categories: [
      {
        title: 'Status',
        value: 'status',
        variantTag: 'softDashed',
        align: 'center',
      },
      {
        title: 'Referência',
        value: 'renderHTMLProcessIdentifier',
        align: 'center',
        renderHTML: RenderHTMLProcessIdentifier,
      },
      {
        title: 'Modal',
        value: 'transportModalitySlug',
        align: 'center',
      },
      {
        title: 'Processo',
        value: 'processCode',
        defaultValue: 'Sem processo',
        type: 'label',
        align: 'center',
      },
      {
        title: 'Data',
        subtitle: 'Carga disponível',
        value: 'availableDateFormatted',
      },
      {
        title: 'Origem',
        value: 'renderHTMLOrigem',
        renderHTML: (item) => <>{item?.originPortName ? item?.originPortName + ' (' + item?.originPortCountry + ')' : '-'}</>,
      },
      {
        title: 'Destino',
        value: 'renderHTMLDestino',
        renderHTML: (item) => <>{item?.destinyPortName ? item?.destinyPortName + ' (' + item?.destinyPortCountry + ')' : '-'}</>,
      },
      {
        title: 'Valor invoice',
        value: 'invoiceValueFormatted',
        align: 'center',
      },
      {
        title: 'Data de cadastro',
        value: 'createdAtFormatted',
        align: 'center',
      },
      {
        title: 'Solicitante',
        value: 'renderHTMLNomeEntrega',
        renderHTML: (item) => (
          <>
            <Flex justify="space-between" direction="column">
              {item.requiredByName}

              {(profileRole === 'ROLE_OPERATOR' || profileRole === 'ROLE_COMERCIAL' || profileRole === 'ROLE_SYSTEM_ADMIN') &&
                item.requiredByName != profileName && (
                  <DefaultModal
                    ctaButton={
                      <Button textColor="#6C48C2" h="30px" opacity="1" borderRadius="9px" bgColor="#D0B0FD36" gap="10px" p="8px 24px ">
                        Assumir <Image src={assume} />
                      </Button>
                    }
                    title={'Assumir cotação'}
                    loading={load}
                    subtitle={
                      <>
                        Você tem certeza que deseja assumir essa cotação?
                        <br />
                        Ao assumir você se torna o solicitante dessa cotação.
                      </>
                    }
                    label={null}
                    txtButton="Confirmar"
                    loadingText="Cancelar"
                    callback={async (decision) => {
                      if (decision) {
                        handleChangesolicitor(`/quote/${item.identifier}/bind-solicitor-user`);
                      }
                    }}
                  />
                )}
            </Flex>
          </>
        ),
      },
      {
        title: 'Responsável',
        subtitle: 'Logística',
        value: 'ufDestinatario',
        renderHTML: (item) => (
          <Flex flexDirection={'column'}>
            {item?.responsibleLogisticUserName ? item.responsibleLogisticUserName : 'Sem responsável'}
            {(profileRole === 'ROLE_LOGISTIC' || profileRole === 'ROLE_SYSTEM_ADMIN') &&
              item.responsibleLogisticUserName != profileName && (
                <DefaultModal
                  ctaButton={
                    <Button textColor="#6C48C2" h="30px" opacity="1" borderRadius="9px" bgColor="#D0B0FD36" gap="10px" p="8px 24px ">
                      Assumir <Image src={assume} />
                    </Button>
                  }
                  title={'Assumir cotação'}
                  loading={load}
                  subtitle={
                    <>
                      Você tem certeza que deseja assumir essa cotação?
                      <br />
                      Ao assumir você se torna o responsável pela análise logística desse documento.
                    </>
                  }
                  label={null}
                  txtButton="Confirmar"
                  loadingText="Cancelar"
                  callback={async (decision) => {
                    if (decision) {
                      handleChangesolicitor(`/quote/${item.identifier}/bind-logistic-user`);
                    }
                  }}
                />
              )}
          </Flex>
        ),
      },
      {
        title: 'Ações',
        defaultValue: '',
        align: 'center',
      },
    ],
    mainCategoryMobile: 'Referência',
    hasCollapsed: false,
    hasPagination: true,
    tags: ['status'],
    hasDetailsButton: true,
    detailsRedirectEndpoint: '/vendemmia/quote/detail/${identifier}',
    statusPreset: [
      { color: '#333333', label: 'Finalizado', value: 'FINALIZED' },
      { color: '#2ECC71', label: 'Aprovado', value: 'APPROVED' },
      { color: '#F1C40F', label: 'Análise logística', value: 'ON_REVIEW_LOGISTICS' },
      { color: '#F1C40F', label: 'Análise operacional', value: 'ON_REVIEW_OPERATIONAL' },
      { color: '#F1C40F', label: 'Análise cliente', value: 'ON_REVIEW_CLIENT' },
      { color: '#E74C3C', label: 'Rejeitado', value: 'REJECTED' },
      { color: '#E74C3C', label: 'Cancelado', value: 'CANCELLED' },
    ],
    images: ['transportModalitySlug'],
    imagePreset: [
      { value: 'air', label: 'Cotação de frete aéreo', image: plane, width: 30, height: 30 },
      { value: 'sea', label: 'Cotação de frete marítimo', image: vessel, width: 30, height: 30 },
    ],
    emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
    maxLengthResume: 16,
    resizeRows: '140px 120px 50px 120px 120px 1fr 1fr 135px 140px 1fr 1fr 60px',
  };
};
