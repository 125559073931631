import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Flex, Image, Tag, Text } from '@chakra-ui/react';

import permissions from '../../../../../services/permissions';
import { descriptionStatusTrip } from '../../../../../utils/translate';

import KanbanCard from './KanbanCard';
import tripStatusChange from './utils/tripStatusChange';

const KanbanColumn = ({
  entity,
  title,
  status,
  icon,
  color,
  tab,
  meta,
  update,
  setUpdate,
  setLoad,
  showMoreItems,
  isLoadingItems,
  reloadList,
}) => {
  let navigate = useNavigate();

  const onItemDrop = (identifier, startColumnId, endColumnId, modality) => {
    if (startColumnId === endColumnId) {
      return;
    }

    setLoad(true);

    tripStatusChange(startColumnId, endColumnId, identifier, modality)
      .then(() => {
        setUpdate(!update);

        toast.success(
          `Status da viagem alterado de ${descriptionStatusTrip(startColumnId)} para ${descriptionStatusTrip(endColumnId)}`
        );
        reloadList();

        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
        toast.error(`${e.message}`);
      });
  };

  const handleDrop = (event, endColumnId) => {
    event.preventDefault();
    const jsonData = event.dataTransfer.getData('application/json');

    if (!jsonData) {
      return;
    }

    try {
      const data = JSON.parse(jsonData);
      const identifier = data.identifier;
      const startColumnId = data.columnId;
      const modality = data.modality;

      if (endColumnId === 'ACCEPTED_BY_DRIVER' && modality !== 'MANUAL') {
        toast.error('Somente motoristas podem aceitar viagens.');
        return;
      }

      if (endColumnId === 'REFUSED_BY_DRIVER' && modality !== 'MANUAL') {
        toast.error('Somente motoristas podem recusar viagens.');
        return;
      }

      if (endColumnId !== 'CREATED' && (modality === 'FRETE_RASTREAVEL' || modality === 'FRETE_NAO_RASTREAVEL')) {
        toast.error('Esta modalidade de viagem não permite a alteração deste status');
        return;
      }

      onItemDrop(identifier, startColumnId, endColumnId, modality);
    } catch (error) {
      console.error('Erro ao processar os dados de drop:', error);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    const jsonData = event.dataTransfer.getData('application/json');

    if (!jsonData) {
      return;
    }

    if (endColumnId === 'ACCEPTED_BY_DRIVER' || endColumnId === 'REFUSED_BY_DRIVER') {
      event.preventDefault();
      return;
    }
  };

  const loadMoreItems = () => {
    showMoreItems();
  };

  useEffect(() => {
    !permissions.logisticTripFullManagement &&
      !permissions.logisticTripCreate &&
      !permissions.logisticTripEdit &&
      !permissions.logisticTripChangeStatus &&
      !permissions.logisticTripView &&
      navigate('/');
  }, []);

  return (
    <Flex h="full" w="full">
      {entity &&
        entity.map((card, key) => (
          <Flex
            key={key}
            w="100%"
            minW="280px"
            direction="column"
            alignItems="center"
            borderRight="#70707036 solid 1px"
            borderBottom="#70707036 solid 1px"
            style={{ transformOrigin: 'left' }}
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(event, status)}>
            <Flex
              bg="white"
              p="15px"
              w="100%"
              alignItems="center"
              gap="15px"
              justify="space-between"
              borderBottom="#70707036 solid 1px">
              <Image src={require(`../../../../../assets/svgs/${icon}`)} />

              <Text fontSize="0.8rem" color="#422C76" fontWeight="medium">
                {title}
              </Text>

              <Text fontSize="0.8rem" color="#422C76" fontWeight="medium">
                <Tag>{meta.total_count}</Tag>
              </Text>
            </Flex>

            <Flex
              direction="column"
              bg="white"
              gap="15px"
              p="15px"
              alignItems="center"
              overflowY="auto"
              w="100%"
              h="calc(100vh - 350px)">
              {card?.length > 0 &&
                card.map((trip) => (
                  <KanbanCard
                    key={trip.identifier}
                    entity={trip}
                    color={color}
                    tab={tab}
                    update={update}
                    setUpdate={setUpdate}
                    columnId={status}
                    reloadList={reloadList}
                  />
                ))}

              {meta?.total_pages > 1 && meta?.current_page < meta?.total_pages && (
                <Button
                  h="30px"
                  minH="30px"
                  w="full"
                  mt="5px"
                  mb="15px"
                  textAlign="center"
                  isLoading={isLoadingItems}
                  onClick={() => {
                    loadMoreItems();
                  }}>
                  Ver mais
                </Button>
              )}
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
};

export default KanbanColumn;
