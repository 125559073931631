import { memo, useCallback } from 'react';

import { ErrorMessage, Field, useFormikContext } from 'formik';
import Select from 'react-select';

import { FormControl, FormLabel, Text } from '@chakra-ui/react';

/**
 * Componente de campo de seleção (Select) reutilizável para formulários.
 * Usa Formik para controle do estado e Chakra UI para estilização.
 *
 * @param {object} props - Propriedades do campo.
 * @param {string} name - Nome do campo no Formik.
 * @param {string} placeholder - Texto placeholder do select.
 * @param {Array} list - Lista de opções para o select.
 * @param {string} keyProp - A chave que será usada como a chave única (key) para cada opção.
 * @param {string} displayProp - A chave que será exibida como o valor da opção.
 * @param {boolean} isMulti - Se `true`, permite seleção múltipla.
 * @returns {JSX.Element} Um campo de seleção estilizado e validado.
 */
const MemoizedDynamicSelectFilterField = ({ props }) => {
  const { setFieldValue } = useFormikContext();
  const { name, placeholder, list = [], keyProp, displayProp, isMulti = false } = props;

  /**
   * Manipula a mudança de valor no campo de seleção.
   * Atualiza o valor do campo no Formik.
   *
   * @param {Event} e - Evento de mudança (onChange) do select.
   */
  const handleChange = useCallback(
    (selectedOption) => {
      if (isMulti) {
        setFieldValue(name, selectedOption ? selectedOption?.map((opt) => opt[keyProp]) : []);
      } else {
        setFieldValue(name, selectedOption ? selectedOption[keyProp] : '');
      }
    },
    [name, setFieldValue, keyProp, isMulti]
  );

  return (
    <FormControl mb={4}>
      {placeholder && <FormLabel htmlFor={name}>{placeholder}</FormLabel>}

      <Field name={name}>
        {({ field }) => {
          // Encontra os valores atuais selecionados no formato correto para exibição no React Select
          const selectedValue = isMulti
            ? list?.filter((option) => field.value?.includes(option[keyProp]))
            : list?.find((option) => option[keyProp] === field?.value || option[keyProp] === field?.value?.identifier) || null;

          return (
            <Select
              id={name}
              name={name}
              options={list}
              getOptionLabel={(option) => option[displayProp]}
              getOptionValue={(option) => option[keyProp]}
              placeholder={placeholder}
              isMulti={isMulti} // Permite múltiplas seleções se for true
              value={selectedValue}
              onChange={handleChange}
              isClearable={true} // Adiciona um botão para limpar a seleção
              noOptionsMessage={() => 'Nenhuma opção encontrada'}
            />
          );
        }}
      </Field>

      <ErrorMessage name={name} component={Text} className="error-message-error" />
    </FormControl>
  );
};

// Exporta o componente memorizado para evitar renderizações desnecessárias
export const DynamicSelectFilterField = memo(MemoizedDynamicSelectFilterField);
