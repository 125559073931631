import React from 'react';

import { Route } from 'react-router-dom';

import CurrentTripsPage from '../pages/logistic/CurrentTrips/CurrentTripsPage';
import DriverManagementPage from '../pages/logistic/DriverManagement/DriverManagement';
import DriverProfilePage from '../pages/logistic/DriverProfile/DriverProfilePage';
import { LiveVehicleTrackingPage } from '../pages/logistic/LiveVehicleTracking/LiveVehicleTrackingPage';
import TravelDetailPage from '../pages/logistic/TravelDetail/TravelDetailPage';
import TravelDetailPublicPage from '../pages/logistic/TravelDetail/TravelDetailPublicPage';
import TravelManagementPage from '../pages/logistic/TravelManagement/TravelManagement';
import VehicleManagementPage from '../pages/logistic/VehicleManagement/VehicleManagementPage';

export const LogisticRoutes = (
  <>
    <Route exact path="/logistic/trip" element={<TravelManagementPage />} />
    <Route exact path="/logistic/trip/detail/:identifier" element={<TravelDetailPage />} />
    <Route exact path="/logistic/trip/timeline" element={<CurrentTripsPage />} />
    <Route exact path="/logistic/drivers" element={<DriverManagementPage />} />
    <Route exact path="/logistic/driver/profile/:identifier" element={<DriverProfilePage />} />
    <Route exact path="/logistic/fleet" element={<VehicleManagementPage />} />
    <Route exact path="/public/tms/trip/:identifier/link/:identifierLink/share" element={<TravelDetailPublicPage />} />
    <Route exact path="/logistic/trip/live-vehicle-tracking" element={<LiveVehicleTrackingPage />} />
  </>
);
