import { Formik } from 'formik';

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { CustomSubmitButton } from '../../components/CommonForm/components/CustomSubmitButton';

import { DrawerFormikStyles } from './styles/DrawerFormikStyles';

/**
 * Renderiza um Drawer (gaveta) reutilizável que pode conter um formulário ou conteúdo personalizado.
 *
 * @param {('top'|'right'|'bottom'|'left')} placement - Define a posição da gaveta (top, right, bottom, left).
 * @param {string} heading - Título que aparece no cabeçalho do Drawer.
 * @param {string} headingFlag - Exibe alguma headingFlag no cabeçalho direito do Drawer.
 * @param {JSX.Element} children - Conteúdo que será renderizado dentro do Drawer (pode ser um formulário ou outros componentes).
 * @param {function} callback - Função de callback chamada quando o formulário for submetido.
 * @param {JSX.Element} footer - Conteúdo personalizado para o rodapé do Drawer (quando não há formulário).
 * @param {string} buttonName - Nome que será exibido no botão de submissão do formulário.
 * @param {Object} initialValues - Valores iniciais para o formulário do Formik.
 * @param {boolean} isOpen - Define se o Drawer está aberto (controlado por uma prop externa).
 * @param {function} onOpen - Função chamada quando o Drawer é aberto (controlado externamente).
 * @param {function} onClose - Função chamada para fechar o Drawer (controlado externamente).
 * @param {boolean} hasForm - Indica se o Drawer contém um formulário ou não.
 * @param {string} loadingText - Texto exibido quando o botão de submissão está carregando.
 * @param {boolean} isLoading - Indica se o botão de submissão do formulário está em estado de carregamento.
 * @param {Object} formik - Props adicionais para o Formik.
 * @param {('xs'|'sm'|'md'|'lg'|'xl'|'full')} size - Define o tamanho opcional do Drawer.
 * @param {Object} props - Outras props que podem ser passadas para o componente Drawer.
 *
 * @returns {JSX.Element} Retorna o componente Drawer que pode conter um formulário ou conteúdo personalizado.
 *
 * @example
 * <CommonDrawer
 *   placement="right"
 *   heading="Título do Drawer"
 *   buttonName="Salvar"
 *   initialValues={{ name: '' }}
 *   isOpen={isDrawerOpen}
 *   onClose={handleCloseDrawer}
 *   hasForm
 * />
 */

export const CommonDrawer = ({
  placement,
  heading,
  headingFlag,
  children,
  callback,
  footer,
  buttonName,
  initialValues,
  isOpen: propIsOpen,
  onOpen: propOnOpen,
  onClose: propOnClose,
  hasForm = false,
  loadingText,
  isLoading,
  formik,
  width = null,
  size = 'xl',
  showFooter = false,
  closeOnEsc = true,
  ...props
}) => {
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure();
  // useDisclosure é um hook do Chakra UI para gerenciar o estado de abertura e fechamento do Drawer internamente.

  const handleClose = () => {
    if (propOnClose) {
      propOnClose(); // Se uma função onClose for passada via props, ela será chamada ao fechar o Drawer.
    } else {
      drawerOnClose(); // Caso contrário, usa o controle interno do estado de fechamento.
    }
  };

  return (
    <Drawer
      placement={placement}
      isOpen={propIsOpen || drawerIsOpen}
      onClose={handleClose}
      size={width ?? size}
      closeOnEsc={closeOnEsc}>
      <DrawerOverlay />

      <DrawerContent w={`${width} !important`} minW="none" p="25px !important">
        <DrawerHeader
          display="Flex"
          alignItems="center"
          gap="10px"
          p="25px"
          borderBottomWidth="1px"
          borderColor="#dfdfdf"
          paddingBottom="20px">
          {heading && (
            <Text
              textStyle="secondaryTitle"
              fontSize={{ sm: '23px', md: '28px' }}
              borderLeft={'6px solid #A885F9'}
              paddingLeft={'18px'}
              textColor="#0F0A1D"
              className="up-anim"
              color="primary">
              {heading} {/* Exibe o título do Drawer, se fornecido. */}
            </Text>
          )}

          {headingFlag && (
            <Text fontSize={{ sm: '23px', md: '28px' }} color="linkColor" className="up-anim">
              {headingFlag}
            </Text>
          )}

          <DrawerCloseButton color="red" size="lg" />
        </DrawerHeader>

        {hasForm ? (
          <Formik
            initialValues={initialValues} // Define os valores iniciais do formulário.
            onSubmit={(values) => {
              callback && callback(values); // Quando o formulário for submetido, chama a função callback com os valores do formulário.
            }}
            {...formik} // Passa props adicionais para o Formik, se houver.
          >
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  height: '100%',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                {/* Renderiza o formulário, usando o Formik. */}

                <DrawerFormikStyles />
                {/* DrawerFormikStyles aplica estilos personalizados ao formulário dentro do Drawer. */}

                <DrawerBody p="25px">{children}</DrawerBody>
                {/* DrawerBody renderiza o conteúdo principal do Drawer (geralmente campos de formulário ou outros componentes). */}

                <DrawerFooter p="25px 0px 0px 0px" borderTopWidth="1px" borderColor={'#dfdfdf'}>
                  {/* DrawerFooter contém o rodapé do Drawer, aqui usamos o botão de submissão do formulário. */}

                  <CustomSubmitButton name={buttonName} loadingText={loadingText} isLoading={isLoading} />
                  {/* CustomSubmitButton é um botão de submissão personalizado. */}
                </DrawerFooter>
              </form>
            )}
          </Formik>
        ) : (
          <DrawerBody p="0">{children}</DrawerBody>
        )}
      </DrawerContent>
    </Drawer>
  );
};
