import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { ErrorMessage, Field, useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { MdDehaze } from 'react-icons/md';
import { toast } from 'react-toastify';

import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { useDebounce } from '../../../hooks/useDebounce';

/**
 * Renderiza um campo de seleção com debounce para busca de opções, integrando com Formik.
 *
 * @param {string} name - Nome do campo no Formik.
 * @param {string} placeholder - Placeholder exibido no input.
 * @param {Array} [list=[]] - Lista de opções exibidas no dropdown.
 * @param {string} keyProp - Propriedade do objeto usada como chave única.
 * @param {string} displayProp - Propriedade do objeto exibida no dropdown.
 * @param {function} fetchOptionsSearch - Função de busca de opções com base no termo digitado.
 * @param {number} [debounceTime=800] - Tempo de debounce em milissegundos.
 * @param {...Object} props - Propriedades adicionais para o componente Input do Chakra UI.
 */
const MemoizedDynamicDebouncedSelectField = ({ props }) => {
  const {
    name,
    placeholder = '',
    list = [],
    keyProp,
    displayProp,
    fetchOptionsSearch = () => {},
    debounceTime = 800,
    icon = <MdDehaze />,
    callback = () => {},
    reset = () => {},
    index = null,
    ...restProps
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState(Array.isArray(list) ? list : []);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputPosition, setInputPosition] = useState(null);
  const [noMatchError, setNoMatchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Captura do contexto do Formik
  const { setFieldValue } = useFormikContext();

  // Função para remover caracteres especiais do termo de busca
  const sanitizeSearchTerm = (value) => value.replace(/[^\w\s]/gi, '');

  const debouncedHandleInputChange = useDebounce((value) => {
    const sanitizedValue = sanitizeSearchTerm(value); // Remove caracteres especiais

    if (sanitizedValue.length <= 2) {
      toast.warning('A pesquisa deve conter no mínimo 3 caracteres');
      setIsLoading(false); // Desativa o loading
    } else {
      setIsLoading(true); // Ativa o loading
      setSearchTerm(value); // Mantém o termo de busca original para exibição
      fetchOptionsSearch(sanitizedValue); // Usa o valor sanitizado para a API
    }
  }, debounceTime);

  // Função para capturar a seleção de uma opção
  const handleOptionSelect = (selectedOption) => {
    const value = selectedOption[displayProp];

    setSearchTerm(value);
    setFieldValue(name, value);
    closeDropdown();
    resetNoMatchError();

    // Callback que passa o objeto inteiro do fornecedor
    callback(selectedOption, index);
  };

  // Função para abrir o dropdown
  const openDropdown = () => setIsDropdownOpen(true);

  // Função para fechar o dropdown
  const closeDropdown = () => setIsDropdownOpen(false);

  // Função para resetar o erro de correspondência
  const resetNoMatchError = () => setNoMatchError(false);

  // Fecha o dropdown ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Atualiza a posição do input para exibir o dropdown corretamente
  useEffect(() => {
    if (inputRef.current) {
      const { top, left, width, height } = inputRef.current.getBoundingClientRect();
      setInputPosition({ top: top + height, left, width, height });
    }
  }, [inputRef.current]);

  // Atualiza as opções sempre que a lista muda
  useEffect(() => {
    setOptions(Array.isArray(list) ? list : []);
    setIsLoading(false); // Desativa o loading quando as opções forem carregadas
  }, [list]);

  // Atualiza o termo de busca com o valor inicial de Formik
  useEffect(() => {
    if (props.initialValue) {
      setSearchTerm(props.initialValue);
    }
  }, [props.initialValue]);

  // Reseta o erro de correspondência e verifica a nova busca
  const handleInputChange = useCallback(
    (value, e) => {
      setSearchTerm(value);
      setIsDropdownOpen(true);
      debouncedHandleInputChange(value);
      setFieldValue(name, value);
      setNoMatchError(false);

      callback(e, index);
    },
    [debouncedHandleInputChange, callback, name, setFieldValue]
  );

  // Verifica se o termo de busca corresponde a alguma opção na lista
  useEffect(() => {
    if (searchTerm.length >= 2 && options.length === 0 && !isLoading) {
      setNoMatchError(true); // Mostra o erro se não houver opções correspondentes
    } else {
      resetNoMatchError(); // Limpa o erro se houver correspondências
    }
  }, [searchTerm, options, isLoading]);

  return (
    <Box position={'relative'}>
      <Flex direction="column" mb={4} position="relative" zIndex={9999}>
        <FormLabel>{placeholder}</FormLabel>
        <Field name={name}>
          {({ field }) => (
            <InputGroup>
              <Input
                ref={inputRef}
                {...field}
                placeholder={placeholder}
                onFocus={() => {
                  if (!searchTerm) {
                    setOptions(Array.isArray(list) ? list : []);
                    openDropdown();
                  }
                }}
                onChange={(e) => handleInputChange(e.target.value, e)}
                p="12px 46px 12px 20px"
                {...restProps}
              />
              <InputRightElement top="50%" transform="translateY(-50%)" right="4px">
                <IconButton
                  aria-label={searchTerm ? 'Clear input' : 'Open dropdown'}
                  icon={searchTerm ? <CloseIcon /> : icon}
                  size="sm"
                  onClick={() => {
                    if (searchTerm) {
                      setSearchTerm('');
                      setFieldValue(name, '');
                      reset(index);
                      fetchOptionsSearch('');
                      setOptions(Array.isArray(list) ? list : []);
                      openDropdown();
                    } else {
                      setIsDropdownOpen((prev) => !prev);
                      setOptions(Array.isArray(list) ? list : []);
                    }
                  }}
                />
              </InputRightElement>
            </InputGroup>
          )}
        </Field>
        {/* Exibe mensagem de erro, se houver, com base no nome do campo */}
        <ErrorMessage name={name} component={Text} className="error-message-error" />
      </Flex>
      <AnimatePresence>
        {isDropdownOpen && inputPosition && (
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            style={{
              position: 'absolute',
              top: placeholder ? `${inputPosition.height + 28}px` : `${inputPosition.height}px`,
              width: `${inputPosition.width}px`,
              zIndex: 10000,
            }}>
            <Box bg="white" p={3} boxShadow="md" borderRadius="md" border="1px solid #70707036">
              {isLoading ? ( // Exibe o indicador de carregamento
                <Flex justifyContent="center" p={3}>
                  <Spinner size="md" />
                </Flex>
              ) : noMatchError ? ( // Exibe mensagem de erro se não houver correspondência
                <Text mt={2}>Nenhuma correspondência encontrada</Text>
              ) : (
                <List overflow="auto" maxH="400px">
                  {options?.map((option) => (
                    <ListItem
                      key={option[keyProp]}
                      p={2}
                      mr="8px"
                      _hover={{ bg: '#a885f9', color: 'white', cursor: 'pointer' }}
                      onClick={() => handleOptionSelect(option)}>
                      {option[displayProp]}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export const DynamicDebouncedSelectField = memo(MemoizedDynamicDebouncedSelectField);
