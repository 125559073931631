import { Text } from '@chakra-ui/react';

//setup
export const commonListConfigManufacturer = {
  // Estrutura da tabela
  title: ' ',
  categories: [
    {
      title: 'Fabricante',
      subtitle: 'Código',
      value: 'sisComexCodigo',
      defaultValue: '-',
      renderHTML: (item) => <Text>{item?.operadorEstrangeiro?.sisComexCodigo}</Text>,
    },

    {
      title: 'Nome',
      value: 'nome',
      defaultValue: '-',
      renderHTML: (item) => <Text>{item?.operadorEstrangeiro?.nome}</Text>,
    },
    {
      title: 'TIN',
      value: 'tin',
      defaultValue: '-',
      renderHTML: (item) => <Text>{item?.operadorEstrangeiro?.tin}</Text>,
    },

    {
      title: 'País',
      value: 'codigoPais',
      renderHTML: (item) => <Text>{item?.operadorEstrangeiro?.codigoPais}</Text>,
    },
    {
      title: 'Cidade',
      value: 'nomeCidade',
      defaultValue: '-',
      renderHTML: (item) => <Text>{item?.operadorEstrangeiro?.nomeCidade}</Text>,
    },
    {
      title: 'Logradouro',
      value: 'logradouro',
      variantTag: 'softDashed',
      renderHTML: (item) => <Text>{item?.operadorEstrangeiro?.logradouro}</Text>,
    },
    {
      title: 'Situação',
      value: 'situacao',
      variantTag: 'softDashed',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],

  mainCategoryMobile: 'sisComexCodigo',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  tags: ['situacao', 'renderHTMLSituacao'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'Ativado', value: 'ATIVADO' },
    { color: '#E74C3C', label: 'Desativado', value: 'DESATIVADO' },
  ],
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 100,
  // Estilo
  resizeRows: '100px 1fr 150px 30px 1fr 1fr 110px 120px 140px',
};
