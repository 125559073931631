import React from 'react';

import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { MdHelp } from 'react-icons/md';

import { Box, Button, Flex, Grid, Text, Tooltip } from '@chakra-ui/react';

const Paginate = ({ setMetadata, metadata, showDetails = true, action, setAction, scroll, ...props }) => {
  function scrollToElement(id) {
    var element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, 0);
    }
  }

  const setNewMeta = (newPage) => {
    if (metadata.current_page === newPage) return; // Evita chamadas repetidas

    setMetadata((prev) => ({
      ...prev,
      current_page: newPage,
    }));

    if (typeof setAction === 'function') {
      setAction((prev) => prev + 1);
    }

    if (scroll && scroll.length > 0) {
      scrollToElement(scroll);
    }
  };

  const hasNextPage = metadata.current_page < metadata.total_pages;
  const hasPreviousPage = metadata.current_page > 1;

  return (
    <Flex direction="column" align={{ base: 'center', lg: 'flex-start' }} p="10px" {...props}>
      <Grid
        alignItems="center"
        justifyContent="center"
        templateColumns={{ base: '1fr', lg: '220px 1fr 220px' }}
        gap={{ base: '10px', lg: '0' }}
        w="full">
        <Flex gap="15px" justifyContent={{ base: 'center', lg: 'flex-start' }}>
          <Button isDisabled={!hasPreviousPage} onClick={() => setNewMeta(1)}>
            <AiOutlineDoubleLeft size={12} />
          </Button>

          <Button isDisabled={!hasPreviousPage} onClick={() => setNewMeta(metadata.current_page - 1)}>
            <AiOutlineLeft size={12} />
          </Button>

          <Button isDisabled={!hasNextPage} onClick={() => setNewMeta(metadata.current_page + 1)}>
            <AiOutlineRight size={12} />
          </Button>

          <Button isDisabled={!hasNextPage} onClick={() => setNewMeta(metadata.total_pages)}>
            <AiOutlineDoubleRight size={12} />
          </Button>
        </Flex>

        {showDetails && metadata.item_count > 0 && (
          <Flex gap="5px" justifyContent={'center'} alignItems={'center'}>
            {metadata.total_pages > 0 && (
              <>
                <Text>
                  Página {metadata.current_page} de {metadata.total_pages}
                </Text>

                <Tooltip label={`Itens na página: ${metadata.item_count} | Total de itens: ${metadata.total_count}`}>
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              </>
            )}
          </Flex>
        )}

        <Flex display={{ base: 'none', lg: 'flex' }}> </Flex>
      </Grid>
    </Flex>
  );
};

export default Paginate;
