import React from 'react';

import { Route } from 'react-router-dom';

import Error404Page from '../pages/error/Error404';

export const ErrorRoutes = (
  <>
    <Route path="*" element={<Error404Page />} />
  </>
);
