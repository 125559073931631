import { useContext } from 'react';

import { colors } from '../../styles/colors';

import { CommonListContext } from './contexts/CommonListContext';

const CommonListStyles = ({ children, ...props }) => {
  // Utiliza o contexto para acessar as propriedades
  const { radioBgColor, viewport, cellRowSize } = useContext(CommonListContext);

  const innerWidth = viewport - 50;
  const desktop = 'min-width: 768px';
  const maxDesktop = 'max-width: 1200px';

  const rowSize = cellRowSize && cellRowSize != 'initial' ? cellRowSize : 'initial';

  return (
    <style>
      {`

        /* .commonlist {
          margin: 0 10px;
        } */

        .chakra-modal__content-container .commonlist {
          display: table;
        }

        .commonListWrapper {
          overflow: auto;
        }

        .chakra-modal__content-container .commonListWrapper {
          overflow: initial;
        }

        .chakra-heading {
          padding: 5px 15px;
          font-size: 20px;
          color: ${colors.primary};
          line-height: 30px;
          font-weight: 600;
          font-family: Hanken Grotesk;
          border-bottom: ${colors['gray-300']}36 solid 1px;
        }

        .chakra-collapse{
          border-top: 1px solid ${colors['gray-100']};
          margin-top: 10px;
        }

        [role="region"] div{
          background-color: ${colors.white};
        }

        [data-email-break="true"]{
          word-break: break-all;
        } 

        body [data-custom-field-select] {
          font-size: 14px;
          border-radius: 6px;
          border-color: ${colors['gray-300']}33;
          color: #6C48C2;
          height: 30px;
        }

        .chakra-card h3 {
          color: #BEBEBE;
          padding: 10px 15px 0;
        }

        .chakra-collapse .chakra-card{
          background-color: ${colors['gray-bg']};
          box-shadow: none;
          border: 0;
        }

        .chakra-card [type="button"]{
          white-space: initial;
          min-height: 36px;
          font-weight: 500;
          align-items: center;
        }
        
        .chakra-card [type="button"] button,
        [data-screen-width] p [type="button"]{
          background-color: ${colors.bgActionButton};
          color: ${colors.linkColor};
          width: initial;
          text-transform: uppercase;
          font-size: 14px;
        } 

        .chakra-card [type="button"] button:hover {
          background-color: ${colors.lightViolet};
        } 

        .chakra-card .chakra-text:last-child {
          display: flex; 
          align-items: center;
          word-break: break-word;
        }

        body .commonMenu button[type="button"] {
          box-shadow: none;
          border: 0;
          gap: 10px;
          width: 100%;
          justify-content: flex-start;
          text-transform: initial;
          padding: 0 20px;
        }

        body [data-content-button] [type="button"]{
          background-color: transparent;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
          border: 1px solid #70707033;
          justify-content: center;
          font-weight: 700;
        }

        body [data-content-button] [type="button"]:hover{
          background-color: #D0B0FD36;
        }

        body [data-content-button] {
          justify-content: flex-end;
          gap: 10px;
        }

        body .chakra-collapse [data-content-button]{
          justify-content: left !important;
        }

        .chakra-accordion__button, [role="list"] .chakra-select__icon-wrapper {
          background: transparent !important;
        }

        [role="list"] li button [data-screen-width]{
          //width: ${innerWidth + 'px'};
          word-break: break-word;
        }

        [role="list"] li button [data-delete-modal]{
          width: initial;
        }
        
        [role="list"] li [data-screen-width] p{
          min-width: ${rowSize};
        }

        .chakra-accordion__item:nth-child(even),
        [role="list"] li:nth-child(even) div, 
        [role="list"] li .chakra-collapse div{
          background-color: ${colors.background};
        }

        .chakra-accordion__item:nth-child(even) .chakra-collapse,
        [role="list"] li:nth-child(even) .chakra-collapse{
          background-color: transparent;
        }

        [role="list"] li div [role="list"] li div {
          background-color: ${colors.white};
        }

        [data-stycky] {
          border-bottom: 1px solid ${colors['gray-300']}36;
          padding:10px 25px;
          gap:15px;
          background: white;
          position: relative;
          z-index: 100;
          width: 100%;
        }

        [data-stycky] strong {
          align-items: center;
          min-width: ${rowSize};
        }

        [data-collapsed] svg {
          position: absolute;
          top: 30px;
          right: 12px;
          background: ${colors.primary};
          border-radius: 10px;
          transform: translate(-25%, -50%);
          height: 20px;
          width: 20px;
          color: ${colors.white};
        }

        [data-status-tag] {
          font-size: 0.75rem;
          align-items: center;
          text-align:center;
          padding: 1px 0.75rem;
          color: ${colors.white};
        }

        [data-type="label"] p.chakra-text{
          border: 1px solid ${colors.linkColor};
          padding: 0px 0.75rem;
          border-radius: 7px;
          font-size: 14px;
          color: ${colors.linkColor};
          width: fit-content;
          text-align: center;
        }

        .chakra-switch__track[data-checked]{
          background: ${radioBgColor ? radioBgColor.turnIn : 'teal'} !important
        }

        .chakra-switch__track[aria-hidden]{
          background: ${radioBgColor ? radioBgColor.turnOff : 'gray'}
        }

        @media (${desktop}) {
          [data-collapsed] svg {
            top: 50%;
            left: -3px;
          }

          [data-status-tag] {
            height: initial;
            overflow: initial;
            text-indent: initial;
            border-radius: 0.438rem;
            padding: 0 5px;
            min-height: 35px;
            font-size: 1rem;
            width: 100%;
            max-width: 200px;
            justify-content: center;
            min-width: initial;
          }

          [data-stycky] {
            position: sticky !important;
            top: -15px;
          }

          .chakra-collapse [data-stycky] {
            position: relative;
            top: 0;
            z-index: 3;
          }

          body [data-custom-field-select] {
            height: 40px;
          }

          [contentButton] .chakra-card [type="button"]{
            border: 1px solid #dddddd;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
          } 
        }

        @media (${maxDesktop})  {

          [data-status-tag] {
            height: 20px;
            width: 20px;
            overflow: hidden;
            min-height: initial;
            border-radius: 100%;
            color: transparent;
            cursor: pointer;
          }
          
          [data-status-tag] div{
            background-color: transparent !important;
          }
        }

      `}
    </style>
  );
};

export default CommonListStyles;
