import React from 'react';

import { Grid } from '@chakra-ui/react';

import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';

export const DynamicFormCreditAnalysis = () => {
  return (
    <Grid borderWidth="1px" borderRadius="md" borderColor={'#70707036'} p={4} mb={4} mt="10px">
      <Grid gridTemplateColumns="1fr 1fr" columnGap="40px">
        <DynamicInputField props={{ name: 'cnpj', placeholder: 'CNPJ', maxLength: 18, mask: 'cnpj' }} />
      </Grid>
    </Grid>
  );
};
