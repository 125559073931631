import plane from '../../../../assets/images/aviao.svg';
import vessel from '../../../../assets/images/navio.svg';
import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';

// Mantido para documentação
const _docReference = commonListConfigDocumentation;

// Setup
export const commonListConfig = ({ handleChangesolicitor }) => {
  return {
    title: '',
    categories: [
      {
        title: 'Status',
        value: 'status',
        variantTag: 'softDashed',
        align: 'center',
      },
      {
        title: 'Código',
        value: 'code',
        align: 'center',
        type: 'label',
      },
      {
        title: 'Endereço de Origem',
        value: 'addressOrigin',
        align: 'center',
      },
      {
        title: 'Endereço de Destino',
        value: 'addressDestination',
        align: 'center',
      },
      {
        title: 'Valor Total',
        value: 'totalMonetary',
        align: 'center',
      },
      {
        title: 'Data de Cadastro',
        value: 'createdAtFormatted',
        align: 'center',
      },
      {
        title: 'Solicitante',
        value: 'renderHTMLSolicitante',
        renderHTML: (item) => <>{item.requester?.name}</>,
      },
      {
        title: 'Responsável',
        value: 'renderHTMLResponsável',
        renderHTML: (item) => <>{item.responsible?.name}</>,
      },
      {
        title: 'Viagem',
        value: 'renderHTMLViagem',
        renderHTML: (item) => <>{item.trip?.code}</>,
      },
      {
        title: 'Processo',
        value: 'renderHTMLProcesso',
        renderHTML: (item) => <>{item.process?.code}</>,
      },
      {
        title: 'Ações',
        defaultValue: '',
        align: 'center',
      },
    ],
    mainCategoryMobile: 'Referência',
    hasCollapsed: false,
    hasPagination: true,
    tags: ['status'],
    /* hasDetailsButton: true,
    detailsRedirectEndpoint: '/vendemmia/quote/detail/${identifier}', */
    statusPreset: [
      { color: '#555', label: 'Rascunho', value: 'RASCUNHO' },
      { color: '#333333', label: 'Finalizado', value: 'FINALIZED' },
      { color: '#2ECC71', label: 'Aprovado', value: 'APPROVED' },
      { color: '#F1C40F', label: 'Análise logística', value: 'ON_REVIEW_LOGISTICS' },
      { color: '#F1C40F', label: 'Análise operacional', value: 'ON_REVIEW_OPERATIONAL' },
      { color: '#F1C40F', label: 'Análise cliente', value: 'ON_REVIEW_CLIENT' },
      { color: '#E74C3C', label: 'Rejeitado', value: 'REJECTED' },
      { color: '#E74C3C', label: 'Cancelado', value: 'CANCELLED' },
    ],
    images: ['transportModalitySlug'],
    imagePreset: [
      { value: 'air', label: 'Cotação de frete aéreo', image: plane, width: 30, height: 30 },
      { value: 'sea', label: 'Cotação de frete marítimo', image: vessel, width: 30, height: 30 },
    ],
    emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
    maxLengthResume: 18,
    resizeRows: '140px 120px 1fr 1fr 120px 1fr 1fr 1fr 1fr 1fr 120px',
  };
};
