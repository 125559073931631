import { memo } from 'react';

import { ErrorMessage, Field, useFormikContext } from 'formik';
import { MdHelp } from 'react-icons/md';
import { withMask } from 'use-mask-input';

import { Box, FormControl, FormLabel, Input, InputGroup, Tooltip } from '@chakra-ui/react';

import { getMaskByKey } from '../../../utils/fields/masks/getMaskByKey';

/**
 * Componente de campo de formulário reutilizável com suporte para máscara e validação.
 * Usa Formik para controle do estado e Chakra UI para estilização.
 *
 * @param {object} props - Propriedades do campo.
 * @param {string} name - Nome do campo no Formik.
 * @param {string} placeholder - Texto placeholder do input.
 * @param {object} inputProps - Propriedades adicionais do input.
 * @param {string|null} mask - Máscara opcional a ser aplicada ao valor do campo.
 * @param {string|null} description - Descrição que será exibida em tooltip ao lado da label.
 * @param {ReactNode|null} leftIcon - Ícone a ser exibido ao lado do input.
 * @returns {JSX.Element} Um campo de formulário estilizado e validado.
 */
const MemoizedDynamicInputField = ({ props }) => {
  const {
    name,
    label,
    placeholder = null,
    mask = null,
    autocomplete = false,
    description = null,
    leftIcon = {},
    ...restProps
  } = props;

  const { setFieldValue } = useFormikContext();

  // Obter a máscara caso uma chave seja fornecida
  const resolvedMask = mask ? getMaskByKey(mask) : null;

  const finalProps = autocomplete ? { ...restProps } : { ...restProps, ref: resolvedMask ? withMask(resolvedMask) : null };

  const iconStyle = leftIcon?.border && {
    borderRadius: 25,
    border: `2px solid ${leftIcon?.color}`,
    mr: 3,
  };

  return (
    <FormControl mb={4}>
      {/* Label + Tooltip de descrição (caso exista) */}
      {placeholder && (
        <FormLabel htmlFor={name} display="flex" alignItems="center">
          {label ?? placeholder}
          {description && (
            <Tooltip label={description} fontSize="sm">
              <span style={{ marginLeft: '4px', cursor: 'help' }}>
                <MdHelp size={16} />
              </span>
            </Tooltip>
          )}
        </FormLabel>
      )}

      <Field name={name}>
        {({ field }) => (
          <>
            <InputGroup display="flex" alignItems="center">
              {leftIcon && <Box {...iconStyle}>{leftIcon.icon}</Box>}
              <Input
                {...field}
                {...finalProps}
                type={name}
                value={field.value}
                placeholder={resolvedMask ? resolvedMask : placeholder}
                onChange={(e) => setFieldValue(name, e.target.value)}
              />
            </InputGroup>

            {/* Exibe mensagem de erro, se houver, com base no nome do campo */}
            <ErrorMessage component="p" className="error-message-error" name={name} />
          </>
        )}
      </Field>
    </FormControl>
  );
};

export const DynamicInputField = memo(MemoizedDynamicInputField);
