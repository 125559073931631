import { useContext } from 'react';

import { Text } from '@chakra-ui/react';

import CustomFields from '../../../components/CommonList/components/CustomFields';
import { CommonListContext } from '../contexts/CommonListContext';

const CustomFieldsRenderer = (props) => {
  // Obtém a configuração de campos customizados do contexto
  const { customFields } = useContext(CommonListContext);
  const { data, listIndex } = props;

  // Se não há campos customizados, retorna nulo
  if (!customFields || customFields.length === 0) return null;

  return customFields.map((field, key) => {
    // Define o valor inicial do campo com base nos dados da API
    const initialValue = data[listIndex][field.name];

    return (
      <Text key={`${field.name}-${key}`}>
        <CustomFields field={field} selected={initialValue} {...props} />
      </Text>
    );
  });
};

export default CustomFieldsRenderer;
