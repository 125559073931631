import { toast } from 'react-toastify';

/**
 * Executa uma requisição de API e lida com sucesso ou erro.
 * @param {Function} action - Método de chamada à API (obrigatório).
 * @param {string} msgSuccess - Mensagem de sucesso a ser exibida (opcional).
 * @param {string} msgError - Mensagem de erro a ser exibida (opcional).
 * @param {Function} setIsLoading - Função para controlar o estado de carregamento (opcional).
 * @param {Function} triggerRefresh - Função para atualizar a lista após sucesso (opcional).
 * @param {Function} callback - Função de callback após execução bem-sucedida (opcional).
 * @param {Function} errorCallback - Função de callback após erro (opcional).
 */
export const executeRequest = async ({
  action,
  msgSuccess = '',
  msgError = '',
  setIsLoading = () => {},
  triggerRefresh = () => {},
  callback = () => {},
  errorCallback = () => {},
}) => {
  setIsLoading(true);

  try {
    const response = await action();

    // Verifica se a resposta é bem-sucedida
    if (isSuccessfulResponse(response)) {
      handleSuccess(msgSuccess, triggerRefresh, callback, response);
      setIsLoading(false);
      return response;
    } else {
      // Se não for bem-sucedida, trata como erro
      handleError(msgError, 'Erro na operação', response, errorCallback);
      setIsLoading(false);
      return null;
    }
  } catch (error) {
    // Captura exceções durante a execução da action
    handleError(msgError, error, null, errorCallback);
    setIsLoading(false);
    return null;
  }
};

/**
 * Verifica se a resposta da API é considerada bem-sucedida.
 * @param {object} response - A resposta da API.
 * @returns {boolean} - Retorna true se a resposta for bem-sucedida.
 */
const isSuccessfulResponse = (response) => {
  // Se a resposta for null ou undefined, não é sucesso
  if (!response) return false;

  // Verifica o status HTTP para determinar sucesso
  if (response.status !== undefined) {
    return response.status === 204 || (response.status >= 200 && response.status < 300);
  }

  // Para APIs que não retornam status HTTP padrão, verifica outras propriedades
  // que possam indicar sucesso (pode ser adaptado conforme a API específica)
  if (response.success === true) return true;
  if (response.error === true) return false;

  // Se chegou até aqui e tem um objeto de resposta, consideramos sucesso
  return true;
};

/**
 * Lida com o sucesso da requisição.
 * @param {string} msgSuccess - Mensagem de sucesso.
 * @param {Function} triggerRefresh - Função para atualizar a lista após sucesso.
 * @param {Function} callback - Função de callback após sucesso.
 * @param {object} response - A resposta da API para passar ao callback.
 */
const handleSuccess = (msgSuccess, triggerRefresh, callback, response) => {
  if (msgSuccess) {
    toast.success(msgSuccess);
  }

  triggerRefresh?.();
  callback?.(response); // Passa a resposta para o callback
};

/**
 * Lida com erros da requisição.
 * @param {string} msgError - Mensagem de erro.
 * @param {object|string} error - O erro capturado ou mensagem.
 * @param {object} response - A resposta da API (caso disponível).
 * @param {Function} errorCallback - Callback opcional para erros.
 */
const handleError = (msgError, error, response, errorCallback) => {
  // Exibe a mensagem de erro específica ou uma mensagem padrão
  const errorMessage = msgError || (typeof error === 'string' ? error : 'Ocorreu um erro na operação');
  toast.error(errorMessage);

  // Executa o callback de erro, se existir, passando detalhes do erro
  errorCallback?.(error, response);
};
