import React, { useEffect, useState } from 'react';

import { Grid } from '@chakra-ui/react';

import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';
import { DynamicSelectFilterField } from '../../../../components/CommonForm/Fields/DynamicSelectFilterField';
import requests from '../../../../services/requests';

const DynamicFormVehicleManagement = ({ values }) => {
  const [drivers, setDrivers] = useState([]);

  // Opções para o campo 'ownership'
  const ownershipOptions = [
    { value: 'VENDEMMIA', name: 'Vendemmia' },
    { value: 'PROPRIO', name: 'Próprio' },
    { value: 'TERCEIRO', name: 'Terceiro' },
  ];

  // Opções para o campo 'typeCar'
  const modelVehicleOptions = [
    { value: 'Caminhão', name: 'Caminhão' },
    { value: 'Carreta', name: 'Carreta' },
    { value: 'Caminhonete', name: 'Caminhonete' },
  ];

  // Mapeamento dos labels
  const labels = {
    licensePlate: 'Placa',
    brand: 'Marca (Opcional)',
    color: 'Cor (Opcional)',
    modelVehicle: 'Tipo de Veículo',
    typeCar: 'Tipo de carreta (Opcional)',
    ownership: 'Propriedade',
    capacity: 'Capacidade (Opcional)',
    yearManufacture: 'Ano de Fabricação (Opcional)',
    model: 'Modelo (Opcional)',
    yearModel: 'Ano do Modelo (Opcional)',
    userDriver: 'Motorista (Opcional)',
  };

  useEffect(() => {
    const callListDrivers = async () => {
      try {
        const response = await requests.listDriversAll(
          {
            filters: {
              selectedStatus: ["ATIVO"],
              role: ["ROLE_DRIVER"],
            },
          },
          0,
          99999
        );

        const options = response.data.map((driver) => ({
          value: driver.identifier,
          name: driver.name,
        }));

        setDrivers(options);
      } catch (error) {
        console.error("Erro ao buscar motoristas:", error);
      }
    };

    callListDrivers();
  }, []);

  // Função para formatar o rótulo (label) de cada campo
  const formatLabel = (key) => labels[key] || key;

  // Mapeamento dos tipos de campo
  const fieldTypes = {
    ownership: 'select',
    modelVehicle: 'select',
    userDriver: 'select',
  };

  // Função para determinar o tipo do campo (input ou select)
  const getFieldType = (key) => fieldTypes[key] || 'input';

  // Função para renderizar o campo adequado, dependendo do tipo
  const renderDynamicField = (key, item = '') => {
    const type = getFieldType(key);
    const label = formatLabel(key);

    if (type === 'select') {
      // Se for um campo do tipo 'select', chama DynamicSelectField
      let list = [];
      if (key === "ownership") {
        list = ownershipOptions;
      } else if (key === "modelVehicle") {
        list = modelVehicleOptions;
      } else if (key === "userDriver") {
        list = drivers; // Usa os motoristas carregados
      }

      return (
        <React.Fragment key={key}>
          <DynamicSelectFilterField
            props={{
              name: key,
              placeholder: label,
              list: list,
              isMulti: false,
              keyProp: 'value', // Substitua pela chave correta da lista
              displayProp: 'name', // Substitua pelo valor a ser exibido
              value: item, // Preenche o valor (padrão será '') }} />
            }}
          />
        </React.Fragment>
      );
    }

    if (key == 'licensePlate') {
      item = { ...item, maxLength: 8 };
    }

    // Se for um campo do tipo 'input', chama DynamicInputField
    return (
      <React.Fragment key={key}>
        <DynamicInputField props={{ name: key, placeholder: label, value: item }} />
      </React.Fragment>
    );
  };

  // Se `values` for nulo ou undefined, inicializa com objeto vazio
  const safeValues = values || {};
  // Cria campos para todas as chaves do labels, usando `values` quando disponível
  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="12px 25px" p="25px">
      {Object.keys(labels).map((key) => {
        return renderDynamicField(key, safeValues[key]);
      })}
    </Grid>
  );
};

export default DynamicFormVehicleManagement;
