import React from 'react';

import { Button, Flex } from '@chakra-ui/react';

const PanelTabsRoot = ({ children, ...props }) => {
  return (
    <Flex
      h="min-content"
      boxShadow="0px 3px 15px #00000017"
      align="center"
      flexWrap="wrap"
      borderRadius="10px"
      overflow="hidden"
      {...props}>
      {children}
    </Flex>
  );
};

const PanelTabsLink = React.memo(({ tab, tabActive, action, text }) => {
  return (
    <Button
      variant={tab === tabActive ? 'panelTabsButton' : 'secondary'}
      borderRadius="0"
      p="0 25px"
      height="49px"
      outline="none"
      border="0 !important"
      textColor="linkColor"
      _hover={{ bgColor: 'lightViolet' }}
      onClick={action}>
      {text}
    </Button>
  );
});

PanelTabsLink.displayName = 'PanelTabsLink';

const PanelTabsController = ({ tabs = [], tab, setTab }) => {
  return (
    <PanelTabsRoot>
      {tabs.map(({ key, text }) => (
        <PanelTabsLink key={key} tab={tab} tabActive={key} action={() => setTab(key)} text={text} />
      ))}
    </PanelTabsRoot>
  );
};

export default PanelTabsController;
