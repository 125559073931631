import React, { useEffect, useState } from 'react';

import { MdOutlineHistory, MdOutlineModeEditOutline, MdOutlineReportProblem, MdUploadFile } from 'react-icons/md';

import { Button } from '@chakra-ui/button';
import { Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import CommonPage from '../../../components/CommonPage/CommonPage';
import { PageContainer } from '../../../components/PageContainer/PageContainer';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import { requests } from '../../../services/v2/requests';
import closeFloatActionButton from '../../../utils/actions/modal/closeFloatActionButton';
import { filterOptionsForeignOperator } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import commonListConfigForeignOperator from './components/config/commonListConfigForeignOperator';

const customToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3NDAzOTk5MjQsImV4cCI6MTgwMjYwNzkyNCwicm9sZXMiOlsiUk9MRV9BRE1JTiJdLCJ1c2VybmFtZSI6IlNoWkc5U0UifQ.fZFxvOacla9R-soDEBJMaa-POKhaHvS0yYvpJpWueNo65yjrI7BFCvqe2BSBCy8GDQHepqub8sc5HDEWEUyj_5-Ql2vpxATKeuaRaA6XNmTOJS3uMOOjJNDp9xIfCfPYhcIsY6IKFCmG-7qVFkN6eXjG06me7fyIXZbzg2h3zzuz0_5hpXuAXkgV7nIBLOsm59uor1-VJBvA-bW1_fdEY4E0wrirOvf2ttFApFXkJLMiDPqUSmOHONEk3cO6bFoIR3PKS-TZjDEswE5SUC8dTAQsNIAQUtoWATj4s_RkGONi_buIJgYNl_bhMHzl6WeS_tOTbIx-3s-YOTu4HnF9jnC1vKn1hi6WdHYU8Cr2jJ5MPB6lBOVnZ9pX4sVZMA6wScUQw8t5lUi2ONrzR96iZnOWjM9evpgb_0jJgSdJZxLoWRr4hjpJvMTMXdOra_RW161x-_ZNZtm8TO9DBoCRMENfFE6psBuXHdX2DFNR3-JJIYr3YfqIkH0O7lc30sK8gDhJ7LEzPHk7s9yTP4KvtdtUqTLD7amzZRhmrBXECfTXg6oJXjfYbjDQ40b8xgAO5nhGdf-XnkoO10g91hBRMKDLO2Ey_64zfitWrhGY2ZAZWcYz4APa6epWTmQ88ybYL3abUFPKykSeY2YJKybmooci5G5aaYAD3ae75eK1E_Q';

const ImportFilesPage = () => {
  const hasPermission = permissions.vendemmiaProductCatalog;

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [LogHistoryList, setLogHistoryList] = useState([]);

  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const { isOpen: isLogHistoryDrawerOpen, onOpen: openLogHistoryDrawer, onClose: closeLogHistoryDrawer } = useDisclosure();

  const getFilesList = async (filters, _key, page) => {
    localStorage.setItem('customToken', customToken);

    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/product/file/list`,
          endpoint: true,
          customToken: 'customToken',
          filters,
          page,
        }),
      setIsLoading,
    });

    setList(res?.data?.data);
    setMetadata(res?.data?.meta);
  };

  const getLogHistory = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/product/file/${identifier}/log/list`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setLogHistoryList(res.data);
  };

  const handleInconsistenciesDrawer = async (identifier) => {
    await getLogHistory(identifier);

    openLogHistoryDrawer();
  };

  const customButtons = [
    {
      main: [
        {
          label: 'Histórico de alterações',
          tooltip: 'Ver histórico de alterações',
          icon: <MdOutlineHistory size={20} />,
          action: handleInconsistenciesDrawer,
        },
        {
          label: 'Arquivo modificado',
          tooltip: 'Baixar arquivo modificado',
          icon: <MdOutlineReportProblem color="red" size={20} />,
          action: () => {},
        },
        {
          label: 'Arquivo enviado',
          tooltip: 'Substituir arquivo enviado',
          icon: <MdOutlineModeEditOutline size={20} />,
          action: () => {},
        },
      ],
      config: [{ maxVisibleButtons: 845654 }],
    },
  ];

  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(getFilesList);

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsForeignOperator());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <CommonPage
      screen="vendemmia-import-files"
      title="Gestão de arquivos importação"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Nome ou Modalidade"
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={getFilesList}
      list={list}
      metadata={metadata}
      isLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      showPeriodFilter={false}
      allowEmptySearchPeriod={false}
      refreshSeconds={false}
      FAB={[
        {
          action: (
            <Button
              h="50px"
              variant="primary"
              borderRadius="50px"
              leftIcon={<MdUploadFile color="#FFFFFF" size={20} />}
              onClick={() => {
                closeFloatActionButton();
              }}>
              Importação de arquivo (arquivo xlsx + modalidade)
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <PageContainer>
        <CommonList
          list={list}
          rawData={list}
          //
          metadata={metadata}
          setMetadata={setMetadata}
          action={action}
          setAction={setAction}
          sorting={sorting}
          handleSort={handleSort}
          //
          customButtons={customButtons}
          isLoading={isLoading}
          {...commonListConfigForeignOperator}
        />
      </PageContainer>

      <CommonDrawer
        heading="Histórico de alterações"
        placement="left"
        isOpen={isLogHistoryDrawerOpen}
        onOpen={openLogHistoryDrawer}
        onClose={closeLogHistoryDrawer}>
        <Table className="table-default" w="full" mt="10px">
          <Thead>
            <Tr>
              <Th>Descrição</Th>
              <Th>Versão</Th>
              <Th>Usuário</Th>
              <Th>Efetuada em</Th>
            </Tr>
          </Thead>

          <Tbody>
            {LogHistoryList.length > 0 ? (
              LogHistoryList.map((entity, key) => (
                <Tr key={key}>
                  <Td>{entity.description}</Td>

                  <Td>{entity.versao}</Td>

                  <Td>{entity.userName}</Td>

                  <Td w="140px">
                    <Text fontSize="14px">{entity.createdAtFormatted}</Text>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="4">
                  <Text textAlign="center">Nenhuma alteração registrada.</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </CommonDrawer>
    </CommonPage>
  );
};

export default ImportFilesPage;
