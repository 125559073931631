import { useContext } from 'react';

import { Button, Flex, Tooltip } from '@chakra-ui/react';

import { CommonListContext } from './contexts/CommonListContext';

export const CommonListActionButtons = ({ children, ...props }) => {
  const { actionButtons } = useContext(CommonListContext);

  if (!actionButtons || actionButtons.length === 0) return null;

  return (
    <Flex gap="10px" justifyContent={'flex-end'}>
      {actionButtons.map(({ label, icon, onClick, tooltip, variant }, index) => {
        // Exibe o ícone ao lado do texto se houver label
        const styles = label?.trim() ? { leftIcon: icon } : {};

        return (
          <Tooltip label={tooltip} key={label}>
            <Button key={index} p={'15px'} variant={variant ?? 'primary'} onClick={onClick} {...styles}>
              {label || icon}
            </Button>
          </Tooltip>
        );
      })}
    </Flex>
  );
};
