import React from 'react';

import { BrowserRouter, Routes } from 'react-router-dom';

// Importações de rotas
import { AuthRoutes } from './AuthRoutes';
import { ComexRoutes } from './ComexRoutes';
import ScrollToTop from './components/ScrollToTop';
import { DashboardRoutes } from './DashboardRoutes';
import { ErrorRoutes } from './ErrorRoutes';
import { LogisticRoutes } from './LogisticRoutes';
import { MetricsRoutes } from './MetricsRoutes';
import { PublicRoutes } from './PublicRoutes';
import { VendemmiaRoutes } from './VendemmiaRoutes';
import { WarehouseRoutes } from './WarehouseRoutes';

const Pages = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* Login */}
        {AuthRoutes}
        {/* Dashboard */}
        {DashboardRoutes}
        {/* Importação */}
        {ComexRoutes}
        {/* Armazém */}
        {WarehouseRoutes}
        {/* Transporte */}
        {LogisticRoutes}
        {/* Métricas */}
        {MetricsRoutes}
        {/* Vendemmia/Gestão */}
        {VendemmiaRoutes}
        {/* Errors */}
        {ErrorRoutes}
        {/* Publics */}
        {PublicRoutes}
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
