import { MdOpenInFull } from 'react-icons/md';

import { Box, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';

import { CommonModal } from '../../CommonModal/CommonModal';
import CommonList from '../CommonList';

export const ExpandableRowContent = ({ children, ...props }) => {
  const { isOpen: modalExpandIsOpen, onOpen: modalExpandOnOpen, onClose: modalExpandOnClose } = useDisclosure();

  const { hasHeading, customFilters } = props;
  const alignPosition = hasHeading ? { right: '5px' } : { left: '3px' };
  const alignTopPosition = customFilters ? { top: '85px' } : { top: '0' };

  return (
    <Box
      position="absolute"
      top="0px"
      opacity=".5"
      w="fit-content"
      h="fit-content"
      zIndex="800"
      transform="scale(.8)"
      transition={'.3s'}
      transformOrigin="center center"
      _hover={{ transform: 'scale(1.2)', opacity: '.9' }}
      {...alignTopPosition}
      {...alignPosition}>
      <Tooltip label="Expandir tabela" placement="top-end">
        <IconButton
          bg="none"
          p="0"
          icon={<MdOpenInFull size={20} color="#422C76" />}
          onClick={modalExpandOnOpen}
          _hover={{ bg: 'tranparent' }}
          _focusVisible={{ boxShadow: 'none' }}
          _active={{ bg: 'tranparent' }}
        />
      </Tooltip>

      <CommonModal isOpen={modalExpandIsOpen} onOpen={modalExpandOnOpen} onClose={modalExpandOnClose} fullpage={true}>
        <CommonList expandableRow={false} resetStyles {...props} />
      </CommonModal>
    </Box>
  );
};
