import { MdArrowBack } from 'react-icons/md';

import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';

export const HeaderModalDetails = ({ children, ...props }) => {
  return (
    <Flex gap={'35px'}>
      <Button
        variant="primary"
        display={'flex'}
        p={'0 15px'}
        leftIcon={<MdArrowBack />}
        onClick={(e) => {
          e.stopPropagation();
          props.closeModal();
          props.setIdentifierProduct('');
        }}>
        Voltar
      </Button>
      {children}
    </Flex>
  );
};
