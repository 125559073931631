import { MdHelp } from 'react-icons/md';

import { Box, Flex, Grid, Text, Tooltip } from '@chakra-ui/react';

import CustomButtonsRenderer from './components/CustomButtonsRenderer';
import CustomFieldsRenderer from './components/CustomFieldsRenderer';
import { CustomValueRenderer } from './components/CustomValueRenderer';
import { useCategoriesProps } from './Hooks/useCategoriesProps';

export const CommonListItemLineMobile = ({
  item,
  content,
  identifier,
  categories,
  hasDetailsButton,
  detailsRedirectEndpoint,
  customButtons,
  maxLengthResume,
  listIndex,
  data,
  rawItem,
}) => {
  return (
    <Grid
      gridTemplateColumns="1fr"
      alignItems="center"
      border="1px solid #70707036"
      borderRadius="10px"
      columnGap="12px"
      p="20px 20px 10px"
      lineHeight="40px"
      boxShadow="sm"
      data-screen-width>
      {/* Container principal para o conteúdo do item */}
      <Flex flexDirection="column">
        {/* Mapeia cada propriedade do item e renderiza o valor formatado */}
        <Flex gap="15px" flexDir="column" align="flex-start">
          {Object.entries(item).map(([property, value], index) => {
            // Obtém as propriedades formatadas para cada categoria
            const {
              linkValue,
              currentCategory: category,
              formattedValue,
              align,
              type,
              variantTag,
              renderHTML,
              showMore,
            } = useCategoriesProps(categories, property, index, item, value);

            return (
              <Text
                key={`${property}-${index}`}
                lineHeight="20px"
                display="grid"
                gridTemplateColumns={`auto auto ${category.tooltip ? 'auto' : ''}`}
                alignItems="center"
                gap="10px"
                fontSize="14px"
                data-type={type}>
                {/* Exibe o título da categoria */}
                <Text as="strong" fontSize="14px">
                  {category.title}
                </Text>
                {/* Renderiza o valor formatado conforme as propriedades da categoria */}
                <CustomValueRenderer
                  property={property}
                  value={value}
                  index={index}
                  identifier={identifier}
                  maxLengthResume={maxLengthResume}
                  rawItem={rawItem}
                  linkValue={linkValue}
                  category={category}
                  formattedValue={formattedValue}
                  align={align}
                  type={type}
                  variantTag={variantTag}
                  renderHTML={renderHTML}
                  showMore={showMore}
                />
                {/* Exibe tooltip se a categoria possuir descrição */}
                {category.tooltip && (
                  <Tooltip label={category.tooltip}>
                    <Box ml="0.325rem">
                      <MdHelp size={15} color="#422C76" />
                    </Box>
                  </Tooltip>
                )}
              </Text>
            );
          })}
        </Flex>

        {/* Renderiza campos customizados, se houver */}
        <CustomFieldsRenderer data={data} listIndex={listIndex} identifier={identifier} categories={categories} rawItem={rawItem} />

        {/* Renderiza botões customizados, se houver */}
        <CustomButtonsRenderer
          hasDetailsButton={hasDetailsButton}
          detailsRedirectEndpoint={detailsRedirectEndpoint}
          customButtons={customButtons}
          rawItem={rawItem}
          identifier={identifier}
          content={content}
        />
      </Flex>
    </Grid>
  );
};
