import React from 'react';

import { ErrorMessage, Form, Formik } from 'formik';
import Select from 'react-select';

import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FormControl, FormLabel, Text } from '@chakra-ui/react';

import { requests } from '../../../../services/v2/requests';
import { executeRequest } from '../../../../utils/requests/executeRequest';

const DynamicFormManufacturer = ({ list, identifier, getManufacturerList, closeManufacturerModal }) => {
  const yup = require('yup');

  const selectCustomStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      minHeight: '47px',
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: '200px',
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px',
      overflowY: 'auto',
    }),
  };

  const handleManufacturerProductSubmit = async (values) => {
    const data = new FormData();

    values.identifiers.forEach((operator, index) => {
      data.append(`identifiers[${index}]`, operator);
    });

    await executeRequest({
      action: () =>
        requests.post({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${identifier}/manufacturer/add`,
          data: data,
          endpoint: true,
          customToken: 'customToken',
        }),
      msgSuccess: 'Fornecedor vinculado com sucesso!',
      callback: () => {
        closeManufacturerModal();
        getManufacturerList(identifier);
      },
    });
  };

  return (
    <Formik
      initialValues={{
        identifiers: [],
      }}
      validationSchema={yup.object().shape({
        identifiers: yup.string().required('Campo obrigatório'),
      })}
      onSubmit={handleManufacturerProductSubmit}>
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormControl h="300px">
            <FormLabel>Operador estrangeiro</FormLabel>

            <Select
              styles={selectCustomStyles}
              name="identifiers"
              className="outline-none transform-none text-black"
              placeholder="Selecione um ou mais operador estrangeiro"
              isMulti
              options={list}
              value={list.find((option) => option.value === values.value)}
              onChange={(options) => {
                setFieldValue(
                  'identifiers',
                  options.map((opt) => opt.value)
                );
              }}
            />

            <ErrorMessage name={'identifiers'} component={Text} className="error-message-error" />
          </FormControl>

          <Flex mt="20px" justify="flex-end">
            <Button h="47px" w="fit-content" variant="submit" type="submit">
              Salvar
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default DynamicFormManufacturer;
