const commonListConfigForeignOperator = {
  title: 'Listagem de operador estrangeiro',
  // Estrutura da tabela
  categories: [
    {
      title: 'PUCOMEX',
      subtitle: 'Código',
      value: 'sisComexCodigo',
      defaultValue: '-',
    },
    {
      title: 'PUCOMEX',
      subtitle: 'Versão',
      value: 'sisComexVersao',
      defaultValue: '0',
    },
    {
      title: 'Nome',
      value: 'nome',
      defaultValue: '-',
    },
    {
      title: 'TIN',
      value: 'tin',
      defaultValue: '-',
    },
    {
      title: 'País',
      value: 'codigoPais',
    },
    {
      title: 'Cidade',
      value: 'nomeCidade',
      defaultValue: '-',
    },
    {
      title: 'Logradouro',
      value: 'logradouro',
      variantTag: 'softDashed',
    },
    {
      title: 'Situação',
      value: 'situacao',
      variantTag: 'softDashed',
      align: 'center',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'sisComexCodigo',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  tags: ['situacao'],
  statusPreset: [
    { color: '#2ECC71', label: 'Ativado', value: 'ATIVADO' },
    { color: '#E74C3C', label: 'Desativado', value: 'DESATIVADO' },
  ],
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 120,
  // Estilo
  resizeRows: '120px 120px 2fr 120px 120px 1fr 2fr 110px 120px 140px',
};

export default commonListConfigForeignOperator;
