export const generateFilterString = (data) => {
  if (!data) return '';

  let str = '';

  // Search Handling
  if (data.search?.trim()) {
    const searchWithoutHashtag = data.search.replace(/#/g, '');
    str += `search=${encodeURIComponent(searchWithoutHashtag)}&`;

    if (data.sortProcessComment) {
      str += `sort=${encodeURIComponent(data.sortProcessComment)}&`;
    }
  }

  if (data.searchOveridesFilters) {
    str += 'searchOveridesFilters=true&';
  }

  // Period Handling
  if (data.period?.startsAt) str += `startsAt=${data.period.startsAt}&`;
  if (data.period?.endsAt) str += `endsAt=${data.period.endsAt}&`;

  // Generic Filters
  if (data.filters) {
    delete data.filters.period;
    Object.entries(data.filters).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        const field = key === 'selectedStatus' ? 'status' : key;
        if (Array.isArray(value)) {
          value.forEach((item) => (str += `${field}[]=${encodeURIComponent(item)}&`));
        } else {
          str += `${field}=${encodeURIComponent(value)}&`;
        }
      }
    });
  }

  // Additional Parameters
  const additionalParams = [
    'client',
    'cteNumber',
    'statusUser',
    'nfeNumber',
    'type_storage',
    'periodPhotos',
    'statusPedido',
    'statusGrafico',
    'additionalStatusGrafico',
    'situation',
    'dashboard',
    'documentNumber',
    'codeTaxInquiry',
    'statusTaxInquiry',
  ];

  additionalParams.forEach((param) => {
    if (data[param]) {
      str += `${param}=${data[param]}&`;
    }
  });

  // Sorting
  if (data.sort) {
    str += `sort=${data.sort.column || ''}&direction=${data.sort.direction || ''}&`;
  }

  if (data.sortColumn) str += `sort=${data.sortColumn}&`;
  if (data.sortDirection) str += `direction=${data.sortDirection}&`;

  if (data.order) {
    if (data.order.order_campo) str += `order_campo=${data.order.order_campo}&`;
    if (data.order.order) str += `order=${data.order.order}&`;
  }

  return str.replace(/&$/, '');
};

export const generatePaginationString = (page) => (page ? `page=${page}&` : '');

export const generatePageSizeString = (pageSize) => (pageSize ? `page_size=${pageSize}&` : '');

export const generateSortString = (sort) => (sort ? `sort=${sort.sort}&direction=${sort.direction}&` : '');

/**
 * Gera a string de query para filtros e paginação.
 * @param {object} filters - Filtros aplicados à consulta.
 * @param {number} page - Página atual para paginação.
 * @param {number} pageSize - Tamanho da página para paginação.
 * @returns {string} - A string de consulta gerada com filtros e paginação.
 */
export const generateQueryString = (filters = {}, page = 1, pageSize = 25, sort = {}) => {
  const filterString = generateFilterString(filters);
  const paginationString = generatePaginationString(page);
  const pageSizeString = generatePageSizeString(pageSize);
  const sortString = generateSortString(sort);

  // Junta todas as partes da query removendo valores vazios
  return [filterString, paginationString, pageSizeString, sortString]
    .filter(Boolean) // Remove strings vazias
    .join('&'); // Junta os parâmetros corretamente com '&'
};
