import React, { useContext } from 'react';

import { Card, Flex, Grid, Heading, Text } from '@chakra-ui/react';

import { CommonListActionButtons } from './CommonListActionButtons';
import { Controller } from './components/Controller';
import { CommonListContext } from './contexts/CommonListContext';

export const CommonListCard = ({ children, ...props }) => {
  const context = useContext(CommonListContext) || {};
  const { title, subtitle, resetStyles, customFilters = [] } = context;

  const hasFilters = customFilters.length > 0;
  const templateColumns = customFilters[0]?.template || 'minmax(0, 1fr) auto';

  const cardStyles = resetStyles
    ? { bg: 'transparent' }
    : {
        bg: 'white',
        border: '1px solid',
        borderColor: '#7070703D',
      };

  return (
    <Card h="auto" overflow="hidden" direction="column" inlineSize={'100%'} {...props} {...cardStyles}>
      {(title || subtitle || hasFilters) && (
        <Grid
          justifyContent="space-between"
          alignItems="center"
          p="10px 15px"
          templateColumns={{ sm: '1fr', md: templateColumns }}
          gap="35px">
          {(title || subtitle) && (
            <Grid w="full">
              {title && (
                <Heading>
                  {title} <CommonListActionButtons />
                </Heading>
              )}
              {subtitle && <Text as="h3">{subtitle}</Text>}
            </Grid>
          )}
          {hasFilters && (
            <Flex gap="10px">
              <Controller />
            </Flex>
          )}
        </Grid>
      )}
      {children}
    </Card>
  );
};
