import api from '../../../../assets/svgs/warehouse/api.svg';
import ftp from '../../../../assets/svgs/warehouse/ftp.svg';
import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: '',
  // Estrutura da tabela
  categories: [
    {
      title: 'Importação',
      /* field: 'e.originModality', */
      value: 'originImport',
      align: 'center',
    },
    {
      title: 'ID NF (Sênior)',
      field: 'e.idNotafiscal',
      value: 'idNotafiscal',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Status',
      field: 'e.statusNf',
      value: 'statusNf',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Faturado',
      value: 'RenderHTMLInvoiced',
      renderHTML: (item) => (item.nuNota === item.idPedido ? 'Sim' : 'Não'),
      align: 'center',
    },
    {
      title: 'Usuário',
      field: 'e.usuarioConferencia',
      value: 'usuarioConferencia',
      align: 'center',
    },
    {
      title: 'Armazém',
      field: 'e.ufArmazem',
      value: 'stateCodeFormatted',
      align: 'center',
    },
    {
      title: 'Nota fiscal',
      field: 'e.nuNota',
      value: 'nuNota',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Série',
      field: 'e.serieNf',
      value: 'serieNf',
      align: 'center',
    },
    {
      title: 'Pedido',
      field: 'e.idPedido',
      value: 'idPedido',
      defaultValue: 'não encontrado',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Canal',
      field: 'e.classTpPedido',
      value: 'classTpPedido',
      align: 'center',
    },
    {
      title: 'Destinatário',
      field: 'e.nomeEntrega',
      value: 'nomeEntrega',
    },
    {
      title: 'UF Destinatário',
      field: 'e.ufDestinatario',
      value: 'ufDestinatario',
      align: 'center',
    },
    {
      title: 'Cidade destinatário',
      field: 'e.recipientCity',
      value: 'recipientCity',
      align: 'center',
    },
    {
      title: 'Depositante',
      field: 'e.nomeDepositante',
      value: 'nomeDepositante',
    },
    {
      title: 'Transportadora',
      field: 'e.nomeTransportadora',
      value: 'nomeTransportadora',
    },
    {
      title: 'Data de cadastro',
      field: 'e.dtCadastro',
      value: 'dtCadastroFormatted',
      /* formatted: (value) => moment(value, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm'), */
      align: 'center',
    },
    {
      title: 'Início da separação',
      field: 'e.dtInicioSeparacao',
      value: 'dtInicioSeparacaoFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Término da separação',
      field: 'e.dtFimSeparacao',
      value: 'dtFimSeparacaoFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Início da conferência',
      field: 'e.dtInicioConferencia',
      value: 'dtInicioConferenciaFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Término da conferência',
      field: 'e.dtFimConferencia',
      value: 'dtFimConferenciaFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Faturado em',
      field: 'e.dtFaturamento',
      value: 'dtFaturamentoFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Volumes',
      field: 'e.qtVolumes',
      value: 'qtVolumes',
      align: 'center',
    },
    {
      title: 'Cub.',
      field: 'e.cubagemM3',
      value: 'cubagemM3Formatted',
      align: 'center',
    },
    {
      title: 'Valor total da NF',
      field: 'e.valorNf',
      value: 'valorNfFormatted',
      align: 'center',
    },
    {
      title: 'Peso bruto',
      field: 'e.weight',
      value: 'weightFormatted',
      align: 'center',
    },
    {
      title: 'Observação',
      field: 'e.observacao',
      value: 'observacao',
    },
    {
      title: 'Última atualização',
      field: 'e.updatedAt',
      value: 'updatedAtFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  subCategories: [
    {
      title: 'Código',
      value: 'codProduto',
      type: 'label',
      defaultValue: 'não possui',
      align: 'center',
    },
    {
      title: 'Descrição',
      value: 'descricaoProduto',
    },
    {
      title: 'Quantidade',
      value: 'qtdeExpedidaFormatted',
      align: 'center',
    },
    {
      title: 'Valor',
      value: 'totalValueFormatted',
      align: 'center',
    },
    {
      title: 'Indústria',
      value: 'loteIndustrial',
      align: 'center',
    },
    {
      title: 'Número de série',
      value: 'infoEspecifica',
      align: 'center',
    },
    {
      title: 'Curva ABC',
      value: 'inAbc',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'idNotafiscal',
  // Funcionalidades
  hasCollapsed: true,
  hasPagination: true,
  collapsedContentType: 'table',
  tags: ['statusNf', 'inAbc'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#422C76', label: 'Importado', value: 'IMPORTADO' },
    { color: '#422C76', label: 'Ag. Formação de romaneio/onda', value: 'AG. FORMAÇÃO DE ROMANEIO/ONDA' },
    { color: '#422C76', label: 'Ag. Separação', value: 'AG. SEPARAÇÃO' },
    { color: '#2EC5CC', label: 'Separação iniciada', value: 'SEPARAÇÃO INICIADA' },
    { color: '#2EA0CC', label: 'Separação concluída', value: 'SEPARAÇÃO CONCLUÍDA' },
    { color: '#CCA42E', label: 'Conferência concluída', value: 'CONFERÊNCIA CONCLUÍDA' },
    { color: '#CCA42E', label: 'Enviado para faturamento', value: 'ENVIADO PARA FATURAMENTO' },
    { color: '#9B2ECC', label: 'Faturado', value: 'FATURADO' },
    { color: '#CC2E96', label: 'Conferência iniciada', value: 'CONFERÊNCIA INICIADA' },
    { color: '#CC2E96', label: 'Coleta iniciada', value: 'COLETA INICIADA' },
    { color: '#E74C3C', label: 'Cancelado', value: 'CANCELADO' },
    { color: '#707070', label: 'Digitado', value: 'DIGITADO' },
    { color: '#CC782E', label: 'Gerada com corte', value: 'GERADA COM CORTE' },
    { color: '#CCA42E', label: 'Quarentena', value: 'QUARENTENA' },
    { color: '#422ECC', label: 'Processado', value: 'PROCESSADO' },
    { color: '#2ECC71', label: 'A', value: 'A' },
    { color: '#F1C40F', label: 'B', value: 'B' },
    { color: '#E74C3C', label: 'C', value: 'C' },
  ],
  images: ['originImport'],
  imagePreset: [
    { value: 'API', label: 'Importação via API Sênior', image: api },
    { value: 'FTP', label: 'Importação via FTP Sênior', image: ftp },
    { value: 'OUTROS', label: 'Importação via FTP Sênior', image: ftp },
  ],
  pathSublist: 'itens',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 16,
  maxLengthResumeChildren: 80,
  /* hasDeleteButton: true, */
  // Estilo
  resizeRows:
    '100px 120px 180px 100px 120px 120px 120px 80px 135px 120px 250px 100px 250px 250px 250px 180px 180px 180px 200px 200px 200px 120px 110px 200px 220px 180px 200px 80px',
  resizeRowsChildren: '180px 500px 120px 120px 120px 120px 120px',
};
