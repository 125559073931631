import React, { useContext } from 'react';

import { useFormikContext } from 'formik';
import { MdAdd, MdAttachMoney } from 'react-icons/md';

import { Box, Button, Grid, Heading, Text } from '@chakra-ui/react';

import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';
import { DynamicMultiSelectField } from '../../../../components/CommonForm/Fields/DynamicMultiSelectField';
import DynamicFormSectionToggle from '../../../../components/CommonForm/Fields/DynamicSectionToggle';
import { TruckingRateQuoteContext } from '../TruckingRateQuotePage';

export const DynamicFormTruckingRateQuote = ({ children, ...props }) => {
  // Hooks
  const { values, setFieldValue } = useFormikContext();
  const truckingRateQuoteContext = useContext(TruckingRateQuoteContext);

  const {
    selectOptions,
    openClientManagementDrawer,
    clientsOptions,
    isLoading,
    getProcessList,
    getTripList,
    processOptions,
    tripOptions,
    isFetching,
    cityOrigin,
    setCityOrigin,
    cityDestination,
    setCityDestination,
    fetchCitiesList,
  } = truckingRateQuoteContext;

  // Função para redefinir o campo de cidade
  const resetCityField = (field) => {
    setFieldValue(field, '');
  };

  const isCityOriginDisabled = !values.stateOrigin;
  const isCityDestinationDisabled = !values.stateDestination;

  const responsible = selectOptions.responsible?.data;
  /* const trips = selectOptions.trips?.data?.data; */
  const clients = clientsOptions?.data?.data;
  const cities = selectOptions.cities?.data?.data;
  const states = selectOptions.states?.data?.data;
  const vehicles = selectOptions.vehicles;

  return (
    <Grid borderWidth="1px" borderRadius="md" borderColor={'#70707036'} p={4} mb={4} mt="10px">
      <Grid gridTemplateColumns="repeat(5, 1fr)" columnGap="40px" p={'25px 45px'}>
        {/* Select para Responsável */}
        <DynamicMultiSelectField
          props={{
            name: 'responsible',
            label: 'Responsável',
            placeholder: 'Selecione um responsável',
            options: responsible,
            formatted: { label: 'name', value: 'name' },
            isMulti: false,
            isLoading,
          }}
        />
        {/* Select para Código da viagem */}
        <DynamicMultiSelectField
          props={{
            name: 'process',
            label: 'Processos relacionados (opcional)',
            placeholder: 'Digite ou selecione...',
            options: processOptions,
            formatted: { label: 'code', value: 'code' },
            apiConfig: { fetchOptions: getProcessList, minCharacters: 3 },
          }}
        />
        {/* Select para Código da viagem */}
        <DynamicMultiSelectField
          props={{
            name: 'trip',
            label: 'Código da viagem (opcional)',
            placeholder: 'Selecione o código da viagem...',
            options: tripOptions,
            formatted: { label: 'code', value: 'code' },
            apiConfig: { fetchOptions: getTripList, minCharacters: 4 },
            isMulti: false,
          }}
        />
        <Text>&nbsp;</Text>
        {/* Select para Status */}
        {/* <DynamicMultiSelectField
          props={{
            name: 'status',
            label: 'Status',
            options: freightStatus,
            formatted: { label: 'label', value: 'label' },
            isMulti: false,
            isLoading,
          }}
        /> */}
      </Grid>
      <DynamicFormSectionToggle label={'Empresa e Rota'} gap={'10px'} highlight defaultOpen>
        <Box as={'section'}>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={'25px'}>
            <Grid>
              {/* Select para CNPJ origem */}
              <DynamicMultiSelectField
                props={{
                  name: 'companyOrigin',
                  label: 'CNPJ origem *',
                  placeholder: 'Digite o CNPJ',
                  options: clients,
                  formatted: (company) => ({
                    label: company.name + ` - (${company.documentNumberFormatted})`,
                    value: 'name',
                  }),
                  filterFunction: (company) => company.type !== 'Matriz',
                  mask: '99.999.999/9999-99',
                  styles: { margemBottom: 0 },
                  isMulti: false,
                  isLoading,
                }}
              />
              <Text fontSize={'12px'} color={'#C2C2C2'} p={'5px 0 8px'}>
                Não encontrou o CNPJ? Adicione um novo
              </Text>
              <Button variant="add" type="button" maxW="250px" onClick={openClientManagementDrawer}>
                <MdAdd /> Cadastrar nova empresa
              </Button>
            </Grid>

            {/* Campo de CEP origem */}
            <DynamicInputField props={{ name: `cepOrigin`, label: 'CEP', placeholder: 'CEP' }} />
            {/* Select para Endereço */}
            <DynamicMultiSelectField
              props={{
                name: 'stateOrigin',
                label: 'Estado',
                options: states,
                formatted: { label: 'name', value: 'code' },
                isMulti: false,
                callbackOnChange: (values) => {
                  fetchCitiesList(setCityOrigin, values);
                  resetCityField('cityOrigin');
                },
                isLoading,
              }}
            />
            {/* Select para Cidade */}
            <DynamicMultiSelectField
              props={{
                name: 'cityOrigin',
                label: 'Cidade',
                options: cityOrigin,
                formatted: { label: 'name', value: 'code' },
                isMulti: false,
                isDisabled: isCityOriginDisabled,
              }}
            />
            {/* Campo de Endereço */}
            <DynamicInputField props={{ name: `addressOrigin`, label: 'Endereço', placeholder: 'Rua' }} />
          </Grid>
        </Box>
        <Box as={'section'}>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={'25px'}>
            {/* Select para CNPJ origem */}
            <Grid>
              <DynamicMultiSelectField
                props={{
                  name: 'companyDestination',
                  label: 'CNPJ destino *',
                  placeholder: 'Digite o CNPJ',
                  options: clients,
                  formatted: (company) => ({
                    label: company.name + ` - (${company.documentNumberFormatted})`,
                    value: 'name',
                  }),
                  filterFunction: (company) => company.type !== 'Matriz',
                  mask: '99.999.999/9999-99',
                  styles: { margemBottom: 0 },
                  isMulti: false,
                  isLoading,
                }}
              />
              <Text fontSize={'12px'} color={'#C2C2C2'} p={'5px 0 8px'}>
                Não encontrou o CNPJ? Adicione um novo
              </Text>
              <Button variant="add" type="button" maxW="250px" onClick={openClientManagementDrawer}>
                <MdAdd /> Cadastrar nova empresa
              </Button>
            </Grid>

            {/* Campo de CEP origem */}
            <DynamicInputField props={{ name: `cepDestination`, label: 'CEP', placeholder: 'CEP' }} />
            {/* Select para Endereço */}
            <DynamicMultiSelectField
              props={{
                name: 'stateDestination',
                label: 'Estado',
                options: states,
                formatted: { label: 'name', value: 'code' },
                isMulti: false,
                callbackOnChange: (values) => {
                  fetchCitiesList(setCityDestination, values);
                  resetCityField('cityDestination');
                },
                isLoading,
              }}
            />
            {/* Select para Cidade */}
            <DynamicMultiSelectField
              props={{
                name: 'cityDestination',
                label: 'Cidade',
                options: cityDestination,
                formatted: { label: 'name', value: 'code' },
                isMulti: false,
                isDisabled: isCityDestinationDisabled,
              }}
            />
            {/* Campo de Endereço */}
            <DynamicInputField props={{ name: `addressDestination`, label: 'Endereço', placeholder: 'Rua' }} />
          </Grid>
        </Box>
        <Box as={'section'}>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={'25px'}>
            <DynamicInputField props={{ name: `distance`, label: 'Quilometragem', placeholder: 'KM' }} />
          </Grid>
        </Box>
      </DynamicFormSectionToggle>
      <Heading fontWeight="normal" fontSize={'16px !important'} p={'0 0 20px 15px !important'}>
        Informações detalhadas
      </Heading>
      <DynamicFormSectionToggle label={'Valores Base'} gap={'10px'} defaultOpen>
        <Box as={'section'}>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={'25px'}>
            {/* Campo de CEP origem */}
            <DynamicInputField
              props={{
                name: `goodsValue`,
                label: 'Valor mercadoria',
                placeholder: '00,00',
                leftIcon: {
                  icon: <MdAttachMoney size={28} color="#70D499" />,
                  color: '#70D499',
                  border: true,
                },
              }}
            />
            {/* Campo de CEP origem */}
            <DynamicInputField props={{ name: `goodsWeight`, label: 'Peso da carga', placeholder: 'Peso' }} />
            {/* Campo de CEP origem */}
            <DynamicInputField
              props={{
                name: `freightWeightMonetary`,
                label: 'Frete peso',
                placeholder: '00,00',
                leftIcon: {
                  icon: <MdAttachMoney size={28} color="#70D499" />,
                  color: '#70D499',
                  border: true,
                },
              }}
            />
            {/* Campo de Endereço */}
            <Grid gridTemplateColumns="repeat(2, 1fr)" gap={'25px'}>
              <DynamicInputField props={{ name: `freightRiskPercent`, label: 'ADV %', placeholder: '00,00' }} />
              <DynamicInputField
                props={{ name: `freightValueMonetary`, label: 'ADV', description: 'AD Valorem', placeholder: '00,00' }}
              />
            </Grid>
            <Grid gridTemplateColumns="repeat(2, 1fr)" gap={'25px'}>
              <DynamicInputField props={{ name: `freightValuePercent`, label: 'Gris %', placeholder: '00,00' }} />
              <DynamicInputField
                props={{ name: `freightRiskMonetary`, label: 'Gris', description: 'Gerenciamento de Risco', placeholder: '00,00' }}
              />
            </Grid>
          </Grid>
        </Box>
      </DynamicFormSectionToggle>
      <DynamicFormSectionToggle label={'Pedágio'} gap={'10px'} defaultOpen>
        <Box as={'section'}>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={'25px'}>
            {/* Select para tipo de veículo */}
            <DynamicMultiSelectField
              props={{
                name: 'vehicleModality',
                label: 'Tipo de veículos',
                placeholder: 'Selecione um veiculo...',
                options: vehicles,
                isMulti: false,
                formatted: { label: 'label', value: 'value' },
              }}
            />
            <DynamicInputField
              props={{
                name: `truckAxleQuantity`,
                label: 'Quantidade de eixos',
                placeholder: 'Quantidade de eixos',
              }}
            />
            <DynamicInputField props={{ name: `truckAxleMonetary`, label: 'Custo por eixo', placeholder: 'Custo por eixo' }} />
            {/* Campo de Endereço */}
            <DynamicInputField
              props={{
                name: `tollMonetary`,
                label: 'Valor total do Pedágio',
                placeholder: '00,00',
                leftIcon: {
                  icon: <MdAttachMoney size={28} color="#70D499" />,
                  color: '#70D499',
                  border: true,
                },
                leftIconBorder: true,
              }}
            />
          </Grid>
        </Box>
      </DynamicFormSectionToggle>
      <DynamicFormSectionToggle label={'Prestação de serviços'} gap={'10px'} defaultOpen>
        <Box as={'section'}>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={'25px'}>
            {/* Campo de CEP origem */}
            <DynamicInputField
              props={{
                name: `servicesMonetary`,
                label: 'Valor do serviço',
                placeholder: '00,00',
                leftIcon: {
                  icon: <MdAttachMoney size={28} color="#70D499" />,
                  color: '#70D499',
                  border: true,
                },
              }}
            />
          </Grid>
        </Box>
      </DynamicFormSectionToggle>
      <DynamicFormSectionToggle label={'Impostos'} gap={'10px'} defaultOpen>
        <Box as={'section'}>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={'25px'}>
            {/* Campo de Endereço */}
            <Grid gridTemplateColumns="1fr" gap={'25px'}>
              <DynamicInputField
                props={{
                  name: `pisCofinsBasePercent`,
                  label: 'Base PIS/COFINS %',
                  placeholder: 'Base PIS/COFINS %',
                }}
              />
              <DynamicInputField
                props={{
                  name: `pisCofinsBaseMonetary`,
                  label: 'Base PIS/COFINS',
                  description: 'AD Valorem',
                  placeholder: 'Base PIS/COFINS',
                }}
              />
            </Grid>
            <Grid gridTemplateColumns="1fr" gap={'25px'}>
              <DynamicInputField props={{ name: `pisPercent`, label: 'PIS %', placeholder: 'PIS' }} />
              <DynamicInputField props={{ name: `pisMonetary`, label: 'PIS', description: 'PIS', placeholder: 'PIS' }} />
            </Grid>
            <Grid gridTemplateColumns="1fr" gap={'25px'}>
              <DynamicInputField props={{ name: `cofinsPercent`, label: 'COFINS %', placeholder: 'Cofins' }} />
              <DynamicInputField
                props={{ name: `cofinsMonetary`, label: 'COFINS', description: 'AD Valorem', placeholder: 'Cofins' }}
              />
            </Grid>
            <Grid gridTemplateColumns="1fr" gap={'25px'}>
              <DynamicInputField
                props={{
                  name: `icmsBasePercent`,
                  label: 'Base ICMS %',
                  placeholder: 'Base ICMS/ISS',
                }}
              />
              <DynamicInputField
                props={{ name: `icmsBaseMonetary`, label: 'Base ICMS', description: 'Base ICMS', placeholder: 'Base ICMS' }}
              />
            </Grid>
            <Grid gridTemplateColumns="1fr" gap={'25px'}>
              <DynamicInputField
                props={{
                  name: `icmsPercent`,
                  label: 'Imposto ICMS/ISS %',
                  placeholder: 'Base ICMS/ISS',
                }}
              />
              <DynamicInputField
                props={{
                  name: `icmsMonetary`,
                  label: 'Imposto ICMS/ISS',
                  description: 'AD Valorem',
                  placeholder: 'Base ICMS/ISS',
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DynamicFormSectionToggle>
      <DynamicFormSectionToggle label={'Totalizador'} gap={'10px'} defaultOpen>
        <Box as={'section'}>
          <Grid gridTemplateColumns="repeat(5, 1fr)" gap={'25px'}>
            {/* Campo de CEP origem */}
            <DynamicInputField
              props={{
                name: `totalMonetary`,
                label: 'Valor final',
                placeholder: '00,00',
                leftIcon: {
                  icon: <MdAttachMoney size={28} color="#70D499" />,
                  color: '#70D499',
                  border: true,
                },
              }}
            />
          </Grid>
        </Box>
      </DynamicFormSectionToggle>
    </Grid>
  );
};
