import React, { useEffect, useState } from 'react';

import { MdAdd, MdOutlineHistory, MdOutlineModeEditOutline, MdOutlineReportProblem } from 'react-icons/md';

import { Button } from '@chakra-ui/button';
import { Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicForm } from '../../../components/CommonForm';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import CommonPage from '../../../components/CommonPage/CommonPage';
import { PageContainer } from '../../../components/PageContainer/PageContainer';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import { requests } from '../../../services/v2/requests';
import closeFloatActionButton from '../../../utils/actions/modal/closeFloatActionButton';
import { filterOptionsForeignOperator } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import commonListConfigForeignOperator from './components/config/commonListConfigForeignOperator';
import initialValuesForeignOperatorForm from './components/config/initialValuesForeignOperatorForm';
import DynamicFormForeignOperator from './components/DynamicFormForeignOperator';

const customToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3NDAzOTk5MjQsImV4cCI6MTgwMjYwNzkyNCwicm9sZXMiOlsiUk9MRV9BRE1JTiJdLCJ1c2VybmFtZSI6IlNoWkc5U0UifQ.fZFxvOacla9R-soDEBJMaa-POKhaHvS0yYvpJpWueNo65yjrI7BFCvqe2BSBCy8GDQHepqub8sc5HDEWEUyj_5-Ql2vpxATKeuaRaA6XNmTOJS3uMOOjJNDp9xIfCfPYhcIsY6IKFCmG-7qVFkN6eXjG06me7fyIXZbzg2h3zzuz0_5hpXuAXkgV7nIBLOsm59uor1-VJBvA-bW1_fdEY4E0wrirOvf2ttFApFXkJLMiDPqUSmOHONEk3cO6bFoIR3PKS-TZjDEswE5SUC8dTAQsNIAQUtoWATj4s_RkGONi_buIJgYNl_bhMHzl6WeS_tOTbIx-3s-YOTu4HnF9jnC1vKn1hi6WdHYU8Cr2jJ5MPB6lBOVnZ9pX4sVZMA6wScUQw8t5lUi2ONrzR96iZnOWjM9evpgb_0jJgSdJZxLoWRr4hjpJvMTMXdOra_RW161x-_ZNZtm8TO9DBoCRMENfFE6psBuXHdX2DFNR3-JJIYr3YfqIkH0O7lc30sK8gDhJ7LEzPHk7s9yTP4KvtdtUqTLD7amzZRhmrBXECfTXg6oJXjfYbjDQ40b8xgAO5nhGdf-XnkoO10g91hBRMKDLO2Ey_64zfitWrhGY2ZAZWcYz4APa6epWTmQ88ybYL3abUFPKykSeY2YJKybmooci5G5aaYAD3ae75eK1E_Q';

const yup = require('yup');

const validationProductScheme = yup.object().shape({
  cpfCnpjRaiz: yup.string().required('Campo obrigatório'),
  nome: yup.string().required('Campo obrigatório'),
  situacao: yup.string().required('Campo obrigatório'),
  logradouro: yup.string().required('Campo obrigatório'),
  nomeCidade: yup.string().required('Campo obrigatório'),
  codigoSubdivisaoPais: yup.string().required('Campo obrigatório'),
  codigoPais: yup.string().required('Campo obrigatório'),
});

const ForeignOperatorPage = () => {
  const hasPermission = permissions.vendemmiaProductCatalog;

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [LogHistoryList, setLogHistoryList] = useState([]);
  const [inconsistenciesList, setInconsistenciesList] = useState([]);

  const [initialValues, setInitialValues] = useState(initialValuesForeignOperatorForm);

  const [editingOperator, setEditingOperator] = useState(null);

  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const { isOpen: isInconsistenciesOpen, onOpen: openInconsistenciesDrawer, onClose: closeInconsistenciesDrawer } = useDisclosure();
  const { isOpen: isLogHistoryDrawerOpen, onOpen: openLogHistoryDrawer, onClose: closeLogHistoryDrawer } = useDisclosure();

  const {
    isOpen: isForeignOperatorDrawerOpen,
    onOpen: openForeignOperatorDrawer,
    onClose: closeForeignOperatorDrawer,
  } = useDisclosure();

  const getForeignOperatorList = async (filters, _key, page) => {
    localStorage.setItem('customToken', customToken);

    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/foreign-operator/list`,
          endpoint: true,
          customToken: 'customToken',
          filters,
          page,
        }),
      setIsLoading,
    });

    setList(res?.data?.data);
    setMetadata(res?.data?.meta);
  };

  const handleForeignOperatorNew = async (values) => {
    await executeRequest({
      action: () =>
        requests.post({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/foreign-operator/new`,
          endpoint: true,
          customToken: 'customToken',
          data: values,
        }),
      setIsLoading,
      triggerRefresh,
      msgSuccess: 'Operador estrangeiro cadastrado com sucesso! 🎉',
      msgError: 'Falha ao cadastrar o operador estrangeiro. Por favor, verifique os dados informados e tente novamente.',
      callback: () => {
        setEditingOperator(null);
        closeForeignOperatorDrawer();
      },
    });
  };

  const handleForeignOperatorShow = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/foreign-operator/${identifier}/show`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setEditingOperator(res.data);

    setInitialValues({
      cpfCnpjRaiz: res.data?.cpfCnpjRaiz,
      tin: res.data?.tin,
      nome: res.data?.nome,
      situacao: res.data?.situacao,
      logradouro: res.data?.logradouro,
      nomeCidade: res.data?.nomeCidade,
      codigoSubdivisaoPais: res.data?.codigoSubdivisaoPais,
      codigoPais: res.data?.codigoPais,
      cep: res.data?.cep,
      email: res.data?.email,
      dataReferencia: null,
      codigoInterno: res.data?.codigoInterno,
    });

    openForeignOperatorDrawer();
  };

  const handleForeignOperatorEdit = async (values) => {
    await executeRequest({
      action: () =>
        requests.put({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/foreign-operator/${editingOperator?.identifier}/edit`,
          endpoint: true,
          customToken: 'customToken',
          data: values,
        }),
      setIsLoading,
      triggerRefresh,
      msgSuccess: 'Operador estrangeiro atualizado com sucesso! ✅',
      msgError: 'Erro ao atualizar operador estrangeiro. Por favor, tente novamente.',
      callback: () => {
        setEditingOperator(null);
        closeForeignOperatorDrawer();
      },
    });
  };

  const handlePortalUnicoSend = async (identifier) => {
    await executeRequest({
      action: () =>
        requests.put({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/foreign-operator/${identifier}/portal-unico/send`,
          endpoint: true,
          customToken: 'customToken',
        }),
      setIsLoading,
      msgSuccess: 'Operador estrangeiro enviado com sucesso para o Portal Único.',
      msgError: 'Problemas ao enviar o operador estrangeiro para o portal único.',
      triggerRefresh,
      errorCallback: () => {
        triggerRefresh();
      },
    });
  };

  const handleInconsistenciesDrawer = async (identifier) => {
    await getInconsistencies(identifier);

    openInconsistenciesDrawer();
  };

  const handleLogHistoryDrawer = async (identifier) => {
    await getLogHistory(identifier);

    openLogHistoryDrawer();
  };

  const getInconsistencies = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/foreign-operator/${identifier}/portal-unico/report`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setInconsistenciesList(res.data);
  };

  const getLogHistory = async (identifier) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/foreign-operator/${identifier}/log/list`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setLogHistoryList(res.data);
  };

  const customFields = [
    {
      isFieldDisabled: (item) => item.rawItem.sisComexEnvioStatus === 'ENVIADO' ?? item.rawItem.hasPortalUnicoErrors,
      type: 'radio',
      name: 'sisComexEnvioStatus',
      align: 'left',
      modalText: 'Você tem certeza que deseja enviar esse operador estrangeiro para o portal único?',
      roles: [
        { label: 'Sincronizado', condition: 'ENVIADO', status: true },
        { label: 'Sincronizar', condition: 'PENDENTE', status: false },
        { label: 'Sincronizar', condition: 'CADASTRADO', status: false },
      ],
      bgColor: { turnIn: '#6C48C2', turnOff: '#C5C5C5' },
      action: handlePortalUnicoSend,
    },
  ];

  const customButtons = [
    {
      main: [
        {
          isButtonVisible: (item) => item.hasPortalUnicoErrors,
          label: 'Inconsistências',
          tooltip: 'Ver inconsistências',
          icon: <MdOutlineReportProblem color="red" size={20} />,
          action: handleInconsistenciesDrawer,
        },
        {
          label: 'Histórico de alterações',
          tooltip: 'Ver histórico de alterações',
          icon: <MdOutlineHistory size={20} />,
          action: handleLogHistoryDrawer,
        },
        {
          label: 'Editar fabricante',
          tooltip: 'Editar fabricante',
          icon: <MdOutlineModeEditOutline size={20} />,
          action: handleForeignOperatorShow,
        },
      ],
      config: [{ maxVisibleButtons: 845654 }],
    },
  ];

  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(getForeignOperatorList);

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsForeignOperator());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    if (isForeignOperatorDrawerOpen === false) {
      setInitialValues(initialValuesForeignOperatorForm);
    }
  }, [isForeignOperatorDrawerOpen]);

  return (
    <CommonPage
      screen="vendemmia-foreign-operator"
      title="Gestão de operador estrangeiro"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Código ou nome"
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={getForeignOperatorList}
      list={list}
      metadata={metadata}
      isLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      showPeriodFilter={false}
      allowEmptySearchPeriod={false}
      FAB={[
        {
          action: (
            <Button
              h="50px"
              variant="primary"
              borderRadius="50px"
              leftIcon={<MdAdd color="#FFFFFF" size={20} />}
              onClick={() => {
                openForeignOperatorDrawer();
                closeFloatActionButton();
              }}>
              Novo operador estrangeiro
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <PageContainer>
        <CommonList
          list={list}
          rawData={list}
          //
          metadata={metadata}
          setMetadata={setMetadata}
          action={action}
          setAction={setAction}
          sorting={sorting}
          handleSort={handleSort}
          //
          customFields={customFields}
          customButtons={customButtons}
          isLoading={isLoading}
          {...commonListConfigForeignOperator}
        />
      </PageContainer>

      <CommonDrawer
        heading={editingOperator ? 'Editar operador estrangeiro' : 'Cadastro de operador estrangeiro'}
        placement="left"
        isOpen={isForeignOperatorDrawerOpen}
        onOpen={openForeignOperatorDrawer}
        onClose={() => {
          setEditingOperator(null);
          closeForeignOperatorDrawer();
        }}>
        <DynamicForm
          config={{
            initialValues: editingOperator || initialValues,
            validationSchema: validationProductScheme,
            callback: editingOperator ? handleForeignOperatorEdit : handleForeignOperatorNew,
          }}>
          <DynamicFormForeignOperator editingOperator={editingOperator} />
        </DynamicForm>
      </CommonDrawer>

      <CommonDrawer
        heading="Histórico de inconsistências"
        placement="left"
        isOpen={isInconsistenciesOpen}
        onOpen={openInconsistenciesDrawer}
        onClose={closeInconsistenciesDrawer}>
        <Table className="table-default" w="full" mt="10px">
          <Thead>
            <Tr>
              <Th>Descrição</Th>
            </Tr>
          </Thead>

          <Tbody>
            {inconsistenciesList && inconsistenciesList.length > 0 ? (
              inconsistenciesList.map((message, key) => (
                <Tr key={key}>
                  <Td>{message}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="1">Nenhum erro encontrado no histórico.</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </CommonDrawer>

      <CommonDrawer
        heading="Histórico de alterações"
        placement="left"
        isOpen={isLogHistoryDrawerOpen}
        onOpen={openLogHistoryDrawer}
        onClose={closeLogHistoryDrawer}>
        <Table className="table-default" w="full" mt="10px">
          <Thead>
            <Tr>
              <Th>Descrição</Th>
              <Th>Versão</Th>
              <Th>Usuário</Th>
              <Th>Efetuada em</Th>
            </Tr>
          </Thead>

          <Tbody>
            {LogHistoryList.length > 0 ? (
              LogHistoryList.map((entity, key) => (
                <Tr key={key}>
                  <Td>{entity.description}</Td>

                  <Td>{entity.versao}</Td>

                  <Td>{entity.userName}</Td>

                  <Td w="140px">
                    <Text fontSize="14px">{entity.createdAtFormatted}</Text>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="4">
                  <Text textAlign="center">Nenhuma alteração registrada.</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </CommonDrawer>
    </CommonPage>
  );
};

export default ForeignOperatorPage;
