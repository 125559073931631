import React, { memo, useEffect, useRef, useState } from 'react';

import { ErrorMessage, useFormikContext } from 'formik';
import Select from 'react-select';

import { Flex, FormControl, FormLabel, Skeleton, Spinner, Text } from '@chakra-ui/react';

const MemoizedDynamicMultiSelectField = ({ props }) => {
  const {
    name,
    label,
    placeholder = 'Selecione...',
    options = [],
    isLoading,
    isClearable = true,
    isDisabled = false,
    styles = { margemBottom: 4 },
    formatted,
    filterFunction,
    apiConfig = {},
    isMulti = true,
    callbackOnChange = () => {},
    ...restProps
  } = props;

  const { setFieldValue, values } = useFormikContext();
  const [formattedOptions, setFormattedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { fetchOptions, minCharacters = 2 } = apiConfig || {};
  const [optionsMessage, setOptionsMessage] = useState('Nenhum resultado encontrado');
  const cacheRef = useRef({}); // Cache para armazenar resultados

  useEffect(() => {
    const safeOptions = Array.isArray(options) ? options : [];
    const filteredOptions = filterFunction ? safeOptions.filter(filterFunction) : safeOptions;

    const formattedOpt = filteredOptions?.map((option) => {
      if (option.label && option.value) return option;
      if (typeof formatted === 'function') {
        const { label, value } = formatted(option);
        return { ...option, label, value };
      } else if (formatted && formatted.label && formatted.value) {
        return { ...option, label: option[formatted.label], value: option[formatted.value] };
      }

      return option;
    });
    setFormattedOptions(formattedOpt);
  }, [options, formatted, filterFunction]);

  const handleInputChange = (value, { action }) => {
    if (action !== 'input-change') return;

    setInputValue(value);

    if (value.length < minCharacters) {
      setOptionsMessage(`Insira ao menos ${minCharacters} dígitos para fazer a busca`);
      return;
    }

    if (cacheRef.current[value]) {
      setFormattedOptions(cacheRef.current[value]); // Reutiliza cache completo
      return;
    }

    if (fetchOptions) {
      if (isFetching) return; // Evita chamadas duplicadas enquanto outra está em andamento

      setIsFetching(true);
      fetchOptions(
        value,
        (newOptions) => {
          cacheRef.current[value] = newOptions; // Armazena com a chave completa
          setFormattedOptions(newOptions);
          setIsFetching(false);
        },
        setOptionsMessage
      );
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: '47px !important',
      backgroundColor: state.isDisabled ? '#E0E0E0' : '#fbfbfb',
      border: state.isFocused || state.isActive ? '1px solid #6C48C2' : '1px solid #ccc',
      paddingLeft: '10px',
      boxShadow: state.isFocused || state.isActive ? '0 0 0 1px #6C48C2' : 'none',
      '&:hover': {
        border: state.isDisabled ? '1px solid #ccc' : '1px solid #6C48C2',
      },
      cursor: state.isDisabled ? 'not-allowed' : 'default',
      opacity: state.isDisabled ? 0.5 : 1,
    }),
    // Adiciona estilo para o menu
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Valor alto para garantir que fique acima de outros elementos
    }),
    menu: (base) => ({
      ...base,
      // Certifica-se que o menu tenha estilo apropriado
      zIndex: 9999,
    }),
  };

  const handleChange = (selectedOption) => {
    setFieldValue(name, selectedOption);
    callbackOnChange?.({ inputName: name, ...selectedOption });
  };

  const selectedValues = Array.isArray(formattedOptions)
    ? formattedOptions?.filter((option) =>
        Array.isArray(values?.[name])
          ? values[name]?.some((val) => val.value === option.value)
          : values?.[name]?.value === option.value
      )
    : [];

  return (
    <FormControl {...styles}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {isLoading ? (
        <Skeleton height="47px" width="100%" startColor="gray.100" endColor="gray.300" borderRadius="0.375rem" />
      ) : (
        <Select
          {...restProps}
          name={name}
          placeholder={placeholder}
          isMulti={isMulti}
          isClearable={isClearable}
          isDisabled={isDisabled}
          options={formattedOptions || []}
          value={selectedValues}
          onChange={handleChange}
          onInputChange={handleInputChange}
          styles={customStyles}
          isLoading={isFetching}
          noOptionsMessage={() => optionsMessage}
          loadingMessage={() => 'Carregando...'}
          menuPortalTarget={document.body} // Renderiza o menu no body do documento
          menuPosition="fixed" // Fixa a posição do menu
          components={{
            LoadingIndicator: () =>
              isFetching ? (
                <Flex align="center" mr={2}>
                  <Spinner size="md" color="purple.500" />
                </Flex>
              ) : null,
          }}
        />
      )}
      <ErrorMessage name={name} component={Text} className="error-message-error" />
    </FormControl>
  );
};

export const DynamicMultiSelectField = memo(MemoizedDynamicMultiSelectField);
