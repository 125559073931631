import requests from './requests';

const isClient = typeof window === 'object';

// Utilitário para acessar valores do localStorage com fallback
const getLocalStorageItem = (key, isJSON = false) => {
  if (!isClient) return null;
  const value = localStorage.getItem(key);
  return isJSON ? JSON.parse(value || 'null') : value;
};

// Atualiza o perfil do usuário no localStorage
const updateProfile = async () => {
  try {
    const profile = await requests.getProfile();

    // Salva os dados no localStorage
    Object.entries(profile).forEach(([key, value]) => {
      const storedValue = typeof value === 'object' ? JSON.stringify(value) : value;
      localStorage.setItem(`profile-${key}`, storedValue);
    });
  } catch (error) {
    console.error('Failed to update profile:', error);
  }
};

// Objeto de perfil
const profile = {
  identifier: getLocalStorageItem('profile-identifier'),
  name: getLocalStorageItem('profile-name'),
  email: getLocalStorageItem('profile-email'),
  cellphone: getLocalStorageItem('profile-cellphone'),
  phone: getLocalStorageItem('profile-phone'),
  role: getLocalStorageItem('profile-role'),
  roleHumanReadable: getLocalStorageItem('profile-role-human-readable'),
  picture: getLocalStorageItem('profile-picture'),
  priorities: getLocalStorageItem('profile-process-priority', true) || [],
  permissions: getLocalStorageItem('profile-permissions', true) || [],
  update: updateProfile,
};

export default profile;
