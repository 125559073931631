const yup = require('yup');

const mainDataValidation = (values = {}) => {
  let validation = yup.object().shape({
    clients: yup.string().nullable(),
    driverFreightFiles: yup.string().nullable(),
    modality: yup.string().required('Campo obrigatório'),
    typeTransport: yup.string().required('Campo obrigatório'),
    typeMandatoryFile: yup.string().required('Campo obrigatório'),
    typeExternalCode:
      values?.modality?.value === 'FRETE_RASTREAVEL' ? yup.string().required('Campo obrigatório') : yup.string().nullable(),
    externalCode:
      values?.modality?.value === 'FRETE_RASTREAVEL' ||
      (values?.modality?.value === 'APP_MOTORISTA' && values?.typeExternalCode?.value == 'OPENTECH')
        ? yup
            .string()
            .required()
            .matches(/^[0-9]+$/, 'Validação de dígitos')
            .min(8, `Código precisa ter 8 dígitos`)
            .max(8, `Código precisa ter 8 dígitos`)
        : yup.string().nullable(),
    shippingCompanyName: yup.string().required('Campo obrigatório'),
    shippingCompanyNameDescription: !!(values?.shippingCompanyName?.value && !['vendemmia_frt', 'vendemmia_agr'].includes(values?.shippingCompanyName?.value?.toLowerCase()))
      ? yup.string().required('Campo obrigatório')
      : yup.string().nullable(),
  });

  return validation;
};

export default mainDataValidation;
