import { useContext } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Button, Collapse, Flex, useDisclosure } from '@chakra-ui/react';

import CommonListItemLine from './CommonListItemLine';
import { CommonListContext } from './contexts/CommonListContext';
import CommonListTable from './templates/CommonListTable';

const CommonListItemContent = ({ ...props }) => {
  const { isOpen, onToggle } = useDisclosure();

  const {
    categories,
    data,
    hasDetailsButton,
    detailsRedirectEndpoint,
    customButtons,
    hasCollapsed,
    collapsedContentType,
    pathSublist,
    maxLengthResume,
    resizeRows,
  } = useContext(CommonListContext);

  // Corrige a definição do gridTemplateLength para evitar self-reference
  const gridTemplateLength = resizeRows || 'auto';

  // Extrai as propriedades necessárias de props
  const { item, listIndex, children: content, rawItem, identifier } = props;

  // Extrai os botões primários e secundários do contexto, se existirem
  const customButtonsPrimary = customButtons?.[0]?.main;
  const customButtonsSecundary = customButtons?.[0]?.collapsed;

  // Determina os itens da sublista
  const sublistPathItens = pathSublist !== '' ? data[listIndex][pathSublist] : [data[listIndex]];
  const sublistLength = sublistPathItens?.length > 0;

  // Componente comum que exibe a linha do item
  const commonItem = (
    <CommonListItemLine
      item={item}
      content={content}
      gridTemplateLength={gridTemplateLength}
      identifier={identifier}
      categories={categories}
      hasDetailsButton={hasDetailsButton}
      detailsRedirectEndpoint={detailsRedirectEndpoint}
      customButtons={customButtonsPrimary}
      maxLengthResume={maxLengthResume}
      listIndex={listIndex}
      data={data}
      rawItem={rawItem}
    />
  );

  return (
    <>
      {hasCollapsed && sublistLength ? (
        // Renderiza com colapso: envolve o commonItem num botão com ação de toggle
        <Button variant="reset" textAlign="left" color="black-400" lineHeight="40px" onClick={onToggle} position="relative">
          {commonItem}
          <Flex data-collapsed>{isOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}</Flex>
        </Button>
      ) : (
        // Renderiza sem colapso
        commonItem
      )}

      {
        //escolha da exibição do template do colapso
        hasCollapsed && (
          <Collapse in={isOpen} animateOpacity>
            {collapsedContentType === 'table' && (
              //template de tabela
              <CommonListTable
                content={content}
                customButtons={customButtonsSecundary}
                pathSublist={sublistPathItens}
                sublistPathItens={sublistPathItens}
                listIndex={listIndex}
              />
            )}
          </Collapse>
        )
      }
    </>
  );
};

export default CommonListItemContent;
