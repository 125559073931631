import { Text } from '@chakra-ui/layout';

import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfigProduct = {
  title: 'Listagem de produtos',
  // Estrutura da tabela
  categories: [
    {
      title: 'Item',
      value: 'sequence',
      defaultValue: '-',
      field: 'e.sequence',
    },
    {
      title: 'Pucomex',
      subtitle: 'Código',
      value: 'sisComexCodigo',
      defaultValue: '-',
    },
    {
      title: 'PUCOMEX',
      subtitle: 'Versão',
      value: 'sisComexVersao',
      defaultValue: '0',
    },
    {
      title: 'ERP',
      subtitle: 'Código',
      value: 'codigoInternoERP',
      defaultValue: '-',
    },
    {
      title: 'Part number',
      subtitle: 'Código',
      value: 'codigoInterno',
      defaultValue: '-',
    },
    {
      title: 'Produto',
      subtitle: 'Descrição',
      value: 'descricao',
      defaultValue: '-',
    },
    {
      title: 'NCM',
      renderHTML: (item) => <Text>{item.ncm?.codeFormatted}</Text>,
      value: 'renderHTMLCodeFormatted',
      defaultValue: '-',
    },
    {
      title: 'Fabricante',
      subtitle: 'Código',
      value: 'codigoFabricante',
      defaultValue: 'Não encontrado',
      showMore: true,
    },
    {
      title: 'Modalidade',
      value: 'modalidade',
    },
    {
      title: 'Situação',
      value: 'situacao',
      variantTag: 'softDashed',
      align: 'center',
      field: 'e.situacao',
    },
    {
      title: ' ',
      defaultValue: '',
      align: 'center',
    },

    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'ncm',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  tags: ['situacao'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#707070', label: 'Rascunho', value: 'RASCUNHO' },
    { color: '#2ECC71', label: 'Ativado', value: 'ATIVADO' },
    { color: '#E74C3C', label: 'Desativado', value: 'DESATIVADO' },
  ],
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 120,
  // Estilo
  resizeRows: '50px 70px 70px 120px 90px auto 100px 180px 105px 110px 100px 140px',
};
