import React, { useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
import { ToastContainer } from 'react-toastify';

import { Box, useDisclosure } from '@chakra-ui/react';

import permissions from '../services/permissions';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import ScreenLoader from './ScreenLoader/ScreenLoader';

const Layout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isShowTwoFactor, setIsShowTwoFactor] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [twoFactor, setTwoFactor] = useState({});

  const redirectToLogin = () => {
    let pathname = window.location.pathname;

    window.localStorage.clear(); // Clears any login information

    if (pathname !== '/') {
      // Stores page to redirect user after login
      window.localStorage.setItem('redirectTo', pathname);

      // Stores max age to redirect user after login
      let expiresAt = new Date();
      expiresAt.setDate(expiresAt.getDate() + 1);
      window.localStorage.setItem('redirectToExpiresAt', expiresAt);
    }

    window.location.href = '/'; // Redirects to login page
  };

  const load = () => {
    setIsLoading(true);

    let token = window.localStorage.getItem('session-token');
    if (!token || token.length === 0) {
      redirectToLogin(); // Sem token
      return;
    }

    let decoded = jwt_decode(token);
    let dateToExpires = new Date(decoded.exp * 1000);
    if (Date.now() > dateToExpires) {
      redirectToLogin(); // Token expirado
      return;
    }

    // const twoFactorToExpires = window.localStorage.getItem('expiration-2fa');
    // if (!twoFactorToExpires || twoFactorToExpires.length === 0) {
    //   requests
    //     .generateCodeTwoFactor()
    //     .then((response) => {
    //       if (typeof response === 'object' && Object.keys(response).length > 0) {
    //         setTwoFactor(response);
    //         onOpen();
    //       }

    //       setIsLoading(false);
    //     })
    //     .catch(() => {
    //       setIsLoading(false);
    //     })
    //   ;
    // }

    if (location.pathname === '/dashboard') {
      if (!permissions.comex) {
        if (permissions.warehouse) {
          if (permissions.warehouseReceipt) {
            window.location.href = '/warehouse/receipt';
            return;
          }
          if (permissions.warehouseStock) {
            window.location.href = '/warehouse/stock';
            return;
          }
          if (permissions.warehousePickingAndPacking) {
            window.location.href = '/warehouse/picking-packing';
            return;
          }
          if (permissions.warehouseExpedition) {
            window.location.href = '/warehouse/expedition';
            return;
          }
          if (permissions.warehouseExpeditionDocumentsManagement || permissions.warehouseExpeditionDocumentsView) {
            window.location.href = '/warehouse/expedition/documents';
            return;
          }
        }

        if (permissions.logistic) {
          if (permissions.logisticRealtime) {
            window.location.href = '/logistic/trip/timeline';
            return;
          }
          if (permissions.logisticTripFullManagement) {
            window.location.href = '/logistic/trip';
            return;
          }
          if (permissions.logisticDriverManagement) {
            window.location.href = '/logistic/drivers';
            return;
          }
          if (permissions.logisticFleetManagement) {
            window.location.href = '/logistic/fleet';
            return;
          }
        }

        if (permissions.metrics) {
          if (permissions.metricsComex) {
            window.location.href = '/metrics/analytics';
            return;
          }
          if (permissions.metricsWarehouse) {
            window.location.href = '/metrics/warehouse';
            return;
          }
          if (permissions.metricsKpiComex) {
            window.location.href = '/metrics/kpi-comex';
            return;
          }
          if (permissions.metricsKpiWarehouse) {
            window.location.href = '/metrics/kpi-warehouse';
            return;
          }
        }

        if (permissions.vendemmia) {
          if (permissions.vendemmiaFreightQuote) {
            window.location.href = '/vendemmia/quote';
            return;
          }
          if (permissions.vendemmiaOperationSpecificationManagement) {
            window.location.href = '/vendemmia/operation-specification';
            return;
          }
          if (permissions.vendemmiaMIA) {
            window.location.href = '/vendemmia/ai-assistant';
            return;
          }
          if (permissions.vendemmiaSQLReportManagement) {
            window.location.href = '/vendemmia/report-generator';
            return;
          }
          if (permissions.vendemmiaSQLReportView) {
            window.location.href = '/vendemmia/report-view';
            return;
          }
          if (permissions.vendemmiaCertificate) {
            window.location.href = '/vendemmia/certificate';
            return;
          }
        }

        if (permissions.metricsKpiCarbon) {
          window.location.href = '/metrics/kpi-carbon';
          return;
        }
      } else if (!permissions.comexProcess || !permissions.comexKanban || !permissions.comexAgenda) {
        window.location.href = '/comex/mapping';
        return;
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <ScreenLoader isLoading={isLoading}>
      <Header />

      <Box as="main" bgColor="background" minH="calc(100vh - 160px)" pt="10px" pb="5px">
        {/*<RequestTwoFactor list={twoFactor} isOpen={isOpen} onClose={onClose} />*/}
        {children}
      </Box>

      <Footer />

      <ToastContainer />
    </ScreenLoader>
  );
};

export default Layout;
