// Valores iniciais para o formulário
export const initialFreightData = {
  // Informações gerais
  responsible: null,
  process: [],
  trip: null,

  // Empresa e Rota - Origem
  companyOrigin: null,
  cepOrigin: '',
  stateOrigin: null,
  cityOrigin: null,
  addressOrigin: '',

  // Empresa e Rota - Destino
  companyDestination: null,
  cepDestination: '',
  stateDestination: null,
  cityDestination: null,
  addressDestination: '',

  // Distância
  distance: '',

  // Valores Base
  goodsValue: '',
  goodsWeight: '',
  freightWeightMonetary: '',
  freightRiskPercent: '',
  freightValueMonetary: '',
  freightValuePercent: '',
  freightRiskMonetary: '',

  // Pedágio
  vehicleModality: null,
  truckAxleQuantity: '',
  truckAxleMonetary: '',
  tollMonetary: '',

  // Prestação de serviços
  servicesMonetary: '',

  // Impostos
  pisCofinsBasePercent: '',
  pisCofinsBaseMonetary: '',
  pisPercent: '',
  pisMonetary: '',
  cofinsPercent: '',
  cofinsMonetary: '',
  icmsBasePercent: '',
  icmsBaseMonetary: '',
  icmsPercent: '',
  icmsMonetary: '',

  // Totalizador
  totalMonetary: '',
};
