import React, { useContext } from 'react';

import { MdHelp } from 'react-icons/md';

import { Box, Grid, GridItem, Tooltip } from '@chakra-ui/react';

import SortableListHeader from './components/SortableListHeader';
import { CommonListContext } from './contexts/CommonListContext';

const CommonListCategories = ({ children, ...props }) => {
  // Utiliza o contexto para acessar as propriedades
  const { sorting, handleSort, isMobile, resizeRows, cellRowSize, isLoading } = useContext(CommonListContext);

  const categories = props?.categories;
  const gridTemplateLength = props?.gridTemplateLength;

  return (
    !isMobile && (
      <Grid gridTemplateColumns={`${gridTemplateLength}`} data-stycky>
        {categories &&
          categories.map((category, key) => {
            // Verifica se o category possui um grupo
            const hasGroup = category.group && category.group.length > 0;
            const showOnlyVisibleCategory = category.title;

            return (
              <React.Fragment key={key}>
                {hasGroup && (
                  <style>
                    {`
                      .has-group-${key}::before {
                        content: "";  
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                        width: calc(100% + 15px);
                        height: 2px;
                        background: ${category.group[0].color ?? '0'};
                      }

                      body .has-group-${key}::last-child::before {
                        width: 100%;
                      }
                    `}
                  </style>
                )}

                {showOnlyVisibleCategory && (
                  <GridItem
                    as={'strong'}
                    display={'flex'}
                    textTransform={'uppercase'}
                    fontSize={'13px'}
                    position={'relative'}
                    justifyContent={category.align ?? 'flex-start'}
                    textAlign={category.align ?? 'initial'}
                    className={hasGroup && `has-group-${key}`}>
                    {hasGroup ? (
                      <SortableListHeader
                        markGroupName={category.group[0].name}
                        markGroupColor={category.group[0].color}
                        column={category.field}
                        label={category.title}
                        headerDescription={category.subtitle}
                        sorting={sorting}
                        handleSort={handleSort}
                      />
                    ) : (
                      <SortableListHeader
                        column={category.field}
                        label={category.title}
                        headerDescription={category.subtitle}
                        sorting={sorting}
                        handleSort={handleSort}
                      />
                    )}

                    {category.tooltip && (
                      <Tooltip label={category.tooltip}>
                        <Box ml="0.325rem">
                          <MdHelp size={15} color="#422C76" />
                        </Box>
                      </Tooltip>
                    )}
                  </GridItem>
                )}
              </React.Fragment>
            );
          })}
      </Grid>
    )
  );
};

export default CommonListCategories;
