const commonListConfigForeignOperator = {
  title: 'Listagem de arquivos para importação',
  // Estrutura da tabela
  categories: [
    {
      title: 'Nome',
      value: 'nome',
      defaultValue: '-',
    },
    {
      title: 'Modalidade',
      value: 'modalidade',
      defaultValue: '-',
    },
    {
      title: 'Data',
      value: 'data',
      defaultValue: '-',
    },
    {
      title: 'Responsável',
      value: 'upload',
      defaultValue: '-',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'sisComexCodigo',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  // Estilo
  resizeRows: '1fr 1fr 1fr 1fr 1fr',
};

export default commonListConfigForeignOperator;
