import React, { useCallback, useEffect, useState } from 'react';

import { ErrorMessage, useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import Select from 'react-select';

import { Grid } from '@chakra-ui/layout';
import { Button, DrawerFooter, FormControl, FormLabel } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import { requests } from '../../../../services/v2/requests';
import { executeRequest } from '../../../../utils/requests/executeRequest';

const DynamicFormForeignOperator = ({ editingOperator }) => {
  const { values, handleChange, setFieldValue } = useFormikContext();

  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [documentNumberOptions, setDocumentNumberOptions] = useState([]);

  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [isLoadingCities, setIsLoadingCities] = useState(false);

  const situationOptions = [
    { label: 'Ativado', value: 'ATIVADO' },
    { label: 'Desativado', value: 'DESATIVADO' },
  ];

  const selectCustomStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      minHeight: '47px',
    }),
  };

  const getCountry = useCallback(
    debounce((inputValue) => {
      if (inputValue.length >= 2) {
        setIsLoadingCountries(true);
        executeRequest({
          action: () =>
            requests.get({
              url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/country/list?search=${inputValue}&`,
              pageSize: 999,
              endpoint: true,
              customToken: 'customToken',
            }),
        })
          .then((response) => {
            setCountryOptions(response?.data?.data?.map((c) => ({ label: c.name, value: c.code })) || []);
          })
          .catch(() => {
            setCountryOptions([]);
          })
          .finally(() => {
            setIsLoadingCountries(false);
          });
      }
    }, 1000),
    []
  );

  const getSubdivisions = useCallback(
    debounce(async (countryCode) => {
      if (!countryCode) return;
      setIsLoadingCities(true);

      try {
        const response = await executeRequest({
          action: () =>
            requests.get({
              url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/country/${countryCode}/list/sub-division`,
              pageSize: 999,
              endpoint: true,
              customToken: 'customToken',
            }),
        });

        setCityOptions(response?.data?.map((c) => ({ label: `${c.code} (${c.name})`, value: c.code })) || []);
      } catch (error) {
        setCityOptions([]);
      } finally {
        setIsLoadingCities(false);
      }
    }, 1000), // Delay de 1 segundo
    []
  );

  const fetchCpfCnpjList = async () => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/company/certificate/list`,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    setDocumentNumberOptions(
      res?.data?.map((item) => ({ label: `${item.documentNumber} (${item.name})`, value: item.documentNumber.toString() })) || []
    );
  };

  useEffect(() => {
    fetchCpfCnpjList();
  }, [values.cpfCnpjRaiz]);

  useEffect(() => {
    if (values.codigoPais) {
      getCountry(values.codigoPais);
      getSubdivisions(values.codigoPais);
    }
  }, [values.codigoPais]);

  const formFields = [
    { name: 'codigoInterno', label: 'Código Interno (opcional)', type: 'text' },
    { name: 'nome', label: 'Nome', type: 'text' },
    { name: 'tin', label: 'TIN (opcional)', type: 'text' },
    { name: 'email', label: 'E-mail (opcional)', type: 'text' },
    { name: 'situacao', label: 'Situação', type: 'select', options: situationOptions },
    { name: 'cpfCnpjRaiz', label: 'CPF/CNPJ', type: 'select', options: documentNumberOptions },
    {
      name: 'codigoPais',
      label: 'País',
      type: 'select',
      options: countryOptions,
      onSearch: getCountry,
      isLoading: isLoadingCountries,
      onChange: (option) => {
        setFieldValue('codigoPais', option.value);
        setCityOptions([]);
        getSubdivisions(option.value);
      },
    },
    {
      name: 'codigoSubdivisaoPais',
      label: 'Código Subdivisão País',
      type: 'select',
      options: cityOptions,
      isLoading: isLoadingCities,
      onChange: (option) => {
        setFieldValue('nomeCidade', option.label);
        setFieldValue('codigoSubdivisaoPais', option.value);
      },
      isDisabled: !values.codigoPais,
    },
    { name: 'logradouro', label: 'Logradouro', type: 'text' },
    { name: 'cep', label: 'Código postal (opcional)', type: 'number' },
  ];

  return (
    <>
      <Grid gridTemplateColumns="repeat(2, 1fr)" p="25px" gap="20px">
        {formFields.map((field) => (
          <FormControl key={field.name}>
            <FormLabel>{field.label}</FormLabel>

            {field.type === 'select' ? (
              <Select
                name={field.name}
                placeholder={`${field.label}`}
                styles={selectCustomStyles}
                value={field.options.find((selected) => selected.value === values[field.name]) || null}
                options={field.options}
                onChange={field.onChange ? field.onChange : (option) => setFieldValue(field.name, option.value)}
                onInputChange={field.onSearch || undefined}
                isLoading={field.isLoading || false}
                isDisabled={field.isDisabled || false}
              />
            ) : (
              <InputDefault
                name={field.name}
                placeholder={field.label}
                type={field.type}
                value={values[field.name]}
                onChange={handleChange}
              />
            )}

            <ErrorMessage component="p" className="error-message-error" name={field.name} />
          </FormControl>
        ))}
      </Grid>

      <DrawerFooter borderTop="1px" borderColor="#7070702E" p="25px 0px 0px 0px">
        <Button h="47px" w="fit-content" variant="submit" type="submit">
          {editingOperator ? 'Atualizar' : 'Salvar'}
        </Button>
      </DrawerFooter>
    </>
  );
};

export default DynamicFormForeignOperator;
