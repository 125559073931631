import { MdArrowBack } from 'react-icons/md';

import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { Flex, Grid, Text } from '@chakra-ui/layout';

import { catalogNote } from '../../../utils/exports';
import { resetUrlParams } from '../../../utils/url/resetUrlParams';

/**
 * Componente de cabeçalho para modais de detalhes.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Function} [props.closeModal] - Função para fechar o modal.
 * @param {string} props.description - Descrição exibida no cabeçalho.
 * @param {string} props.title - Título do cabeçalho.
 * @param {Function} [props.setIdentifier] - Função opcional para resetar um identificador.
 * @param {boolean} [props.resetParams=true] - Define se os parâmetros da URL devem ser resetados ao fechar o modal.
 * @param {React.ReactNode} [props.children] - Elementos filhos que serão renderizados abaixo do cabeçalho.
 */
export const HeaderModalDetails = ({ children, ...props }) => {
  const { closeModal = () => {}, description, title, setIdentifier, resetParams = true } = props || {};

  return (
    <Flex gap="35px" alignItems={'center'}>
      <Button
        variant="primary"
        display="flex"
        p="0 15px"
        leftIcon={<MdArrowBack />}
        onClick={(e) => {
          e.stopPropagation();
          closeModal();
          setIdentifier && setIdentifier('');
          resetParams && resetUrlParams();
        }}>
        Voltar
      </Button>
      <Flex gap="28px">
        <Image src={catalogNote} alt="Ícone de nota" />

        <Grid>
          <Text color="#0F0A1D99" fontSize="14px">
            {description}
          </Text>

          <Text as="strong" color="#0F0A1D" fontSize="24px">
            {title}
          </Text>
        </Grid>
      </Flex>
      {children}
    </Flex>
  );
};
