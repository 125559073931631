import React, { useEffect, useState } from 'react';

import { MdOutlineEdit } from 'react-icons/md';
import { TbCameraPlus } from 'react-icons/tb';
import { useParams } from 'react-router-dom';

import {
  Avatar,
  Box,
  Card,
  Flex,
  Icon,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicForm, DynamicFormButton, DynamicFormFooter } from '../../../components/CommonForm';
import LabelWithText from '../../../components/Generic/LabelWithText';
import Tag from '../../../components/Generic/Tag';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import ModalUploadPhoto from '../../../components/Modal/Upload/ModalUploadPhoto';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import requests from '../../../services/requests';
import { driver } from '../../../utils/exports';
import { executeRequest } from '../../../utils/requests/executeRequest';
import { limitChars } from '../../../utils/strings';
import { convertColorUser, descriptionStatusUser } from '../../../utils/translate';

import DocumentsTab from './components/Documents/DocumentsTab';
import DynamicFormDriverProfile from './components/DynamicFormDriverProfile';
import PersonalDataTab from './components/PersonalData/PersonalDataTab';
import ProfileButtons from './components/ProfileButtons';
import ReportsTab from './components/Reports/ReportsTab';
import TravelsTab from './components/Travels/TravelsTab';
import VehiclesTab from './components/Vehicles/VehiclesTab';

const yup = require('yup');

const DriverProfilePage = () => {
  const { identifier } = useParams();
  const [entity, setEntity] = useState([]);
  const [load, setLoad] = useState(false);
  const [lastAcess, setLastAcess] = useState(null);
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const { isOpen: isOpenPhoto, onOpen: onOpenPhoto, onClose: onClosePhoto } = useDisclosure();
  const [initialValues, setInitialValues] = useState(null);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);

  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    documentNumber: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório'),
    cellphone: yup.string().required('Campo obrigatório'),
  });

  const listUser = (init = false) => {
    setLoad(true);
    requests.showUserDriver(identifier).then((response) => {
      setEntity(response);
      updateInitialValues(response);
      requests.lastAccessProfile(identifier).then((response) => {
        if (response?.date != null) {
          setLastAcess(response.date);
        }
      });
      setLoad(false);

      if (init && response?.profilePictureUri != '/profile/picture/empty') {
        setProfilePicture(
          `${process.env.REACT_APP_API_URL}${
            profilePicture ?? response?.profilePictureUri?.replace('//profile', '/profile')
          }?t=${Date.now()}`
        );
      }
    });
  };

  const updateInitialValues = (entity) => {
    let updatedValues = {
      type: entity.type,
      name: entity.name,
      documentNumber: entity.documentNumber,
      email: entity.email,
      phone: entity.phone,
      cellphone: entity.cellphone,
      additional: {
        RG: undefined,
        CNH: undefined,
        BIRTHDAY: undefined,
        ADDRESS_STREET: undefined,
        ADDRESS_NUMBER: undefined,
        ADDRESS_COMPLEMENT: undefined,
        ADDRESS_NEIGHBORHOOD: undefined,
        ADDRESS_CITY: undefined,
        ADDRESS_STATE: undefined,
        ADDRESS_ZIPCODE: undefined,
        IDWALL: undefined,
      },
    };

    entity?.additional?.map(async (additional) => {
      switch (additional?.modality) {
        case 'RG':
          updatedValues.additional.RG = additional.description;
          break;
        case 'CNH':
          updatedValues.additional.CNH = additional.description;
          break;
        case 'BIRTHDAY':
          updatedValues.additional.BIRTHDAY = additional.description;
          break;
        case 'ADDRESS_STREET':
          updatedValues.additional.ADDRESS_STREET = additional.description;
          break;
        case 'ADDRESS_NUMBER':
          updatedValues.additional.ADDRESS_NUMBER = additional.description;
          break;
        case 'ADDRESS_COMPLEMENT':
          updatedValues.additional.ADDRESS_COMPLEMENT = additional.description;
          break;
        case 'ADDRESS_NEIGHBORHOOD':
          updatedValues.additional.ADDRESS_NEIGHBORHOOD = additional.description;
          break;
        case 'ADDRESS_CITY':
          updatedValues.additional.ADDRESS_CITY = additional.description;
          break;
        case 'ADDRESS_STATE':
          updatedValues.additional.ADDRESS_STATE = additional.description;
          break;
        case 'ADDRESS_ZIPCODE':
          updatedValues.additional.ADDRESS_ZIPCODE = additional.description;
          break;
      }
    });

    setInitialValues(updatedValues);
  };

  const handleOpenModalEdit = () => {
    onOpenEdit();
  };

  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(listUser);

  const generateData = (values) => {
    let additional = [];

    Object.entries(values?.additional)?.forEach(([key, value]) => {
      if (value != '' && value != undefined) {
        additional.push({ modality: key, description: value });
      }
    });

    return {
      ...values,
      additional: additional,
    };
  };

  const handleEditDriver = async (values) => {
    const valuesFormatted = generateData(values);
    await executeRequest({
      action: () => requests.editDriver(identifier, valuesFormatted),
      msgSuccess: 'Dados do motorista editado com sucesso',
      msgError: 'Erro ao editar os dados do motorista, tente novamente.',
      setIsLoadingEdit,
      triggerRefresh,
      callback: () => onCloseEdit(),
    });
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .changeDriverPicture(identifier, docs, config)
        .then((res) => {
          onClosePhoto();

          if (res?.profilePictureUri != null && res?.profilePictureUri != '/profile/picture/empty') {
            setProfilePicture(
              `${process.env.REACT_APP_API_URL}${res?.profilePictureUri?.replace('//profile', '/profile')}?t=${Date.now()}`
            );
          }

          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  useEffect(() => {
    listUser(true);
  }, []);

  return (
    <Layout>
      <Menubar
        linkTree={[
          { link: '#', title: 'Transporte' },
          { link: '/logistic/drivers', title: 'Gestão de motoristas' },
        ]}
        placeholder="Pesquisar motoristas..."
        title={`${entity && entity.name ? entity.name : 'Carregando...'}`}
        detailsTitle={`${entity && entity.name ? entity.name : 'Carregando...'}`}
        icon={driver}
        hideAllFilters={true}
      />
      <Flex minH="calc(100vh - 254px)" flexDirection={{ base: 'column', md: 'row' }}>
        <Card m="10px" p="10px" w={{ base: 'initial', md: '500px' }}>
          <Flex direction="column">
            <Flex alignSelf="flex-end">
              <Tooltip label="Editar motorista">
                <IconButton
                  bgColor="#D0B0FD36"
                  icon={<MdOutlineEdit size={20} color="#6C48C2" />}
                  onClick={handleOpenModalEdit}
                  isLoading={load}
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex direction="column" alignItems="center" justify="space-around">
            <Flex gap="25px">
              <Flex direction="column">
                <Box
                  position="relative"
                  width="fit-content"
                  height="fit-content"
                  cursor="pointer"
                  borderRadius="50px"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}>
                  <Avatar
                    src={`${profilePicture}`}
                    fallbackSrc={`${process.env.REACT_APP_API_URL + '/profile/picture/empty'}`}
                    size="xl"
                    border="3px solid #422C76"
                    cursor="pointer"
                    transition="opacity 0.3s"
                  />
                  {isHovered && (
                    <Box
                      position="absolute"
                      top="0"
                      right="0"
                      bottom="0"
                      left="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      bg="rgba(0, 0, 0, 0.5)"
                      borderRadius="full"
                      cursor="pointer"
                      onClick={(decision) => {
                        if (decision) {
                          onOpenPhoto();
                        }
                      }}
                      zIndex="1">
                      <Icon as={TbCameraPlus} color="white" boxSize={10} />
                    </Box>
                  )}
                </Box>
                <ProfileButtons user={entity} refresh={listUser} />
              </Flex>

              <Flex direction="column">
                {entity && entity?.name && (
                  <>
                    {entity?.name?.length > 13 ? (
                      <Tooltip label={entity?.name}>
                        <Text fontSize={{ base: '20px', md: '30px' }} fontWeight="500" lineHeight="30px" color="#422C76" mt="15px">
                          {limitChars(entity?.name, 13)}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text fontSize={{ base: '20px', md: '30px' }} fontWeight="500" lineHeight="30px" color="#422C76" mt="15px">
                        {entity?.name}
                      </Text>
                    )}
                  </>
                )}

                <Text fontSize="14px" color="#422C76">
                  {entity?.type == 'EXTERNAL' ? 'Parceiro externo' : 'Motorista Vendemmia'}
                </Text>

                {lastAcess != null && (
                  <Text fontSize="14px" color="#422C76">
                    Último acesso: {lastAcess}
                  </Text>
                )}

                {entity?.lastVersionApp != null && (
                  <Text fontSize="14px" color="#422C76">
                    Última versão app: {entity?.lastVersionApp}
                  </Text>
                )}

                <Tag mt="6" type={convertColorUser(entity?.status)}>
                  {descriptionStatusUser(entity?.status)}
                </Tag>
              </Flex>
            </Flex>

            <Tabs mt="20px" position="relative" variant="unstyled">
              <TabList justifyContent="center">
                <Tab>
                  <Text fontSize="14px" color="#6C48C2">
                    Dados de acesso
                  </Text>
                </Tab>
                <Tab>
                  <Text fontSize="14px" color="#6C48C2">
                    Dados de endereço
                  </Text>
                </Tab>
              </TabList>
              <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />
              <TabPanels>
                <TabPanel>
                  <Flex flexFlow="wrap" justifyContent="space-between" gap="15px">
                    <LabelWithText title="CPF" content={entity?.documentNumberFormatted} />
                    <LabelWithText title="E-mail" content={entity?.email} />
                    <LabelWithText title="Celular" content={entity?.cellphoneFormatted} />
                    {entity?.phoneFormatted && <LabelWithText title="Telefone" content={entity.phoneFormatted} />}
                    {!load &&
                      entity?.additional?.length > 0 &&
                      entity?.additional?.map(function (item, index) {
                        if (item.group == 'access' && item.modality != 'CPF') {
                          return <LabelWithText key={index} title={item.name} content={item.description} />;
                        }
                      })}
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex flexFlow="wrap" justifyContent="space-between" gap="15px">
                    {!load &&
                      entity?.additional?.length > 0 &&
                      entity?.additional?.map(function (item, index) {
                        if (item.group == 'address') {
                          return <LabelWithText key={index} title={item.name} content={item.description} />;
                        }
                      })}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Card>

        <Card m="10px" p="10px" w={{ base: 'initial', md: '100%' }}>
          <Tabs position="relative" variant="unstyled">
            <TabList display={{ base: 'flex', md: 'row' }} flexDirection={{ base: 'column', md: 'row' }}>
              <Tab>Documentação</Tab>
              <Tab>Dados Pessoais</Tab>
              <Tab>Viagens</Tab>
              <Tab>Veículos</Tab>
              <Tab>Relatórios</Tab>
              {/*<Tab>Localização</Tab>
                  <Tab>Checklist</Tab>*/}
            </TabList>
            <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />
            <TabPanels overflow={'scroll'}>
              <DocumentsTab user={entity} refresh={listUser} load={load} setLoad={setLoad} />
              <PersonalDataTab user={entity} refresh={listUser} />
              <TravelsTab user={entity} />
              <VehiclesTab user={entity} />
              <ReportsTab user={entity} />
              {/*<LocationTab />
                <ChecklistTab />*/}
            </TabPanels>
          </Tabs>
        </Card>

        <CommonDrawer heading="Dados do motorista" placement="left" isOpen={isOpenEdit} onOpen={onOpenEdit} onClose={onCloseEdit}>
          <DynamicForm config={{ initialValues, validationSchema, callback: handleEditDriver }}>
            <DynamicFormDriverProfile />

            <DynamicFormFooter>
              <DynamicFormButton name={'Salvar'} type="button" />
            </DynamicFormFooter>
          </DynamicForm>
        </CommonDrawer>

        <ModalUploadPhoto
          subtitle="Nova Foto"
          accept=".png, .jpeg"
          messageConfirm=" Foto de perfil alterada com sucesso!"
          isOpen={isOpenPhoto}
          onOpen={onOpenPhoto}
          onClose={onClosePhoto}
          requestAction={sendUpload}
        />
      </Flex>
    </Layout>
  );
};

export default DriverProfilePage;
