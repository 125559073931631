import profile from './profile';

// Map de permissões com categorias associadas
const PERMISSIONS_MAP = {
  // Categorias de Comex
  comexProcess: [74, 1],
  comexKanban: [74, 48],
  comexAgenda: [74, 50],
  comexMapping: [74, 71],
  canHideProcess: [63],

  // Categorias de Armazém
  warehouseReceipt: [56],
  warehouseStock: [57],
  warehouseStockPositionConsolidationUpload: [104],
  warehousePickingAndPacking: [58],
  warehouseExpedition: [59],
  warehouseExpeditionDocumentsManagement: [108],
  warehouseExpeditionDocumentsView: [108, 109],
  warehouseStockAging: [110],
  warehouseTransportSchedule: [122],
  warehouseStockTurnover: [127],

  // Categorias de Logística
  logisticRealtime: [75],
  logisticTripFullManagement: [76],
  logisticTripView: [76, 91],
  logisticTripCreate: [76, 92],
  logisticTripEdit: [76, 93],
  logisticTripChangeStatus: [76, 94],
  logisticDriverManagement: [77],
  logisticFleetManagement: [78],
  logisticDocumentView: [111, 76],
  logisticDocumentManagement: [112, 76],
  logisticTripCreateShareLink: [121],

  // Métricas
  metricsComex: [49],
  metricsWarehouse: [80],
  metricsKpiComex: [81],
  metricsKpiWarehouse: [82],
  metricsKpiCarbon: [117],

  // Vendemmia
  vendemmiaFreightQuote: [54],
  vendemmiaTruckingRateQuote: [128],
  vendemmiaOperationSpecificationManagement: [84],
  vendemmiaOperationSpecificationView: [107],
  vendemmiaCreditAnalysis: [102],
  vendemmiaContractManagement: [103],
  vendemmiaMIA: [85],
  vendemmiaSQLReportView: [96],
  vendemmiaTaxInquiry: [100],
  vendemmiaCertificate: [98],
  vendemmiaComexCashFlow: [97],
  vendemmiaProfitability: [101],
  vendemmiaBillingDashboard: [106],
  vendemmiaProtestLetters: [116],

  // Gestão Vendemmia
  vendemmiaManagementItReports: [87],
  vendemmiaManagementUserManagement: [88],
  vendemmiaManagementClientManagement: [113],
  vendemmiaManagementClientRegister: [125],
  vendemmiaManagementFaqManagement: [89],
  vendemmiaManagementPolicy: [90],
  vendemmiaSQLReportManagement: [95],
  vendemmiaAdditionalFieldsManagement: [99],
  vendemmiaNcmManagement: [101],
  vendemmiaInconsistency: [126],
  vendemmiaSuppliers: [120],
  vendemmiaProductCatalog: [145],
};

// Verifica se o usuário é admin do sistema
const isSystemAdmin = profile.role === 'ROLE_SYSTEM_ADMIN';

// Helper para verificar permissões
const hasPermission = (ids) => {
  const userPermissionIds = profile.permissions.map((perm) => perm.id);
  return isSystemAdmin || ids.some((id) => userPermissionIds.includes(id));
};

// Criação dinâmica das permissões
const permissions = Object.fromEntries(Object.entries(PERMISSIONS_MAP).map(([key, ids]) => [key, hasPermission(ids)]));

// Agrupamento por categorias
const createCategoryPermission = (...keys) => keys.some((key) => permissions[key]);

permissions.comex = createCategoryPermission('comexProcess', 'comexKanban', 'comexAgenda', 'comexMapping');

permissions.warehouse = createCategoryPermission(
  'warehouseReceipt',
  'warehouseStock',
  'warehouseStockPositionConsolidationUpload',
  'warehousePickingAndPacking',
  'warehouseExpedition',
  'warehouseExpeditionDocumentsManagement',
  'warehouseExpeditionDocumentsView',
  'warehouseStockAging',
  'warehouseTransportSchedule',
  'warehouseStockTurnover'
);

permissions.logistic = createCategoryPermission(
  'logisticRealtime',
  'logisticTripFullManagement',
  'logisticTripView',
  'logisticTripCreate',
  'logisticTripEdit',
  'logisticTripChangeStatus',
  'logisticDriverManagement',
  'logisticFleetManagement',
  'logisticTripCreateShareLink'
);

permissions.metrics = createCategoryPermission(
  'metricsComex',
  'metricsWarehouse',
  'metricsKpiComex',
  'metricsKpiWarehouse',
  'metricsKpiCarbon'
);

permissions.vendemmia = createCategoryPermission(
  'vendemmiaFreightQuote',
  'vendemmiaTruckingRateQuote',
  'vendemmiaOperationSpecificationManagement',
  'vendemmiaOperationSpecificationView',
  'vendemmiaBillingDashboard',
  'vendemmiaProfitability',
  'vendemmiaMIA',
  'vendemmiaCreditAnalysis',
  'vendemmiaContractManagement',
  'vendemmiaSQLReportManagement',
  'vendemmiaSQLReportView',
  'vendemmiaTaxInquiry',
  'vendemmiaCertificate',
  'vendemmiaComexCashFlow',
  'vendemmiaProtestLetters'
);

permissions.vendemmiaManagement = createCategoryPermission(
  'vendemmiaManagementItReports',
  'vendemmiaManagementUserManagement',
  'vendemmiaManagementFaqManagement',
  'vendemmiaManagementPolicy',
  'vendemmiaSQLReportManagement',
  'vendemmiaAdditionalFieldsManagement',
  'vendemmiaManagementClientManagement',
  'vendemmiaManagementClientRegister',
  'vendemmiaNcmManagement',
  'vendemmiaInconsistency',
  'vendemmiaSuppliers',
  'vendemmiaProductCatalog'
);

export default permissions;
