import React from 'react';

import { Grid } from '@chakra-ui/react';

import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';
import { DynamicSelectField } from '../../../../components/CommonForm/Fields/DynamicSelectField';
import { getMaskByKey } from '../../../../utils/fields/masks/getMaskByKey';

const DynamicFormClientManagement = ({ values }) => {
  // Opções para o campo 'typeCar'
  const stateOptions = [
    { value: 'AC', name: 'Acre' },
    { value: 'AL', name: 'Alagoas' },
    { value: 'AP', name: 'Amapá' },
    { value: 'AM', name: 'Amazonas' },
    { value: 'BA', name: 'Bahia' },
    { value: 'CE', name: 'Ceará' },
    { value: 'DF', name: 'Distrito Federal' },
    { value: 'ES', name: 'Espírito Santo' },
    { value: 'GO', name: 'Goiás' },
    { value: 'MA', name: 'Maranhão' },
    { value: 'MT', name: 'Mato Grosso' },
    { value: 'MS', name: 'Mato Grosso do Sul' },
    { value: 'MG', name: 'Minas Gerais' },
    { value: 'PA', name: 'Pará' },
    { value: 'PB', name: 'Paraíba' },
    { value: 'PR', name: 'Paraná' },
    { value: 'PE', name: 'Pernambuco' },
    { value: 'PI', name: 'Piauí' },
    { value: 'RJ', name: 'Rio de Janeiro' },
    { value: 'RN', name: 'Rio Grande do Norte' },
    { value: 'RS', name: 'Rio Grande do Sul' },
    { value: 'RO', name: 'Rondônia' },
    { value: 'RR', name: 'Roraima' },
    { value: 'SC', name: 'Santa Catarina' },
    { value: 'SP', name: 'São Paulo' },
    { value: 'SE', name: 'Sergipe' },
    { value: 'TO', name: 'Tocantins' },
  ];

  // Mapeamento dos labels
  const labels = {
    name: 'Nome',
    companyName: 'Razão Social (Opcional)',
    documentNumber: 'CNPJ',
    address: 'Endereço (Opcional)',
    city: 'Cidade (Opcional)',
    codeState: 'Estado (Opcional)',
    zipCode: 'CEP (Opcional)',
    conexosId: 'Código do Conexos (Opcional)',
  };

  // Função para formatar o rótulo (label) de cada campo
  const formatLabel = (key) => labels[key] || key;

  // Mapeamento dos tipos de campo
  const fieldTypes = {
    codeState: 'select',
  };

  // Função para determinar o tipo do campo (input ou select)
  const getFieldType = (key) => fieldTypes[key] || 'input';

  // Função para renderizar o campo adequado, dependendo do tipo
  const renderDynamicField = (key, item = '') => {
    const type = getFieldType(key);
    const label = formatLabel(key);
    const mask = getMaskByKey(key == 'documentNumber' ? 'cnpj' : key);
    
    if (type === 'select') {
      // Se for um campo do tipo 'select', chama DynamicSelectField
      const list = key === 'codeState' ? stateOptions : [];

      return (
        <React.Fragment key={key}>
          <DynamicSelectField
            props={{
              name: key,
              placeholder: label,
              list: list,
              keyProp: 'value', // Substitua pela chave correta da lista
              displayProp: 'name', // Substitua pelo valor a ser exibido
              value: item, // Preenche o valor (padrão será '') }} />
            }}
          />
        </React.Fragment>
      );
    }

    // Se for um campo do tipo 'input', chama DynamicInputField
    return (
      <React.Fragment key={key}>
        <DynamicInputField props={{ name: key, placeholder: label, item, mask }} /> {/* Passa o valor para o campo */}
      </React.Fragment>
    );
  };

  // Se `values` for nulo ou undefined, inicializa com objeto vazio
  const safeValues = values || {};
  // Cria campos para todas as chaves do labels, usando `values` quando disponível
  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="10px 25px" p="25px">
      {Object.keys(labels).map((key) => {
        return renderDynamicField(key, safeValues[key]);
      })}
    </Grid>
  );
};

export default DynamicFormClientManagement;
