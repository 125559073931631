import React, { useEffect, useState } from 'react';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { MdFileUpload, MdHistory, MdOutlineReportProblem, MdOutlineSave } from 'react-icons/md';
import Select from 'react-select';

import { Grid, GridItem } from '@chakra-ui/layout';
import { Button, FormControl, FormLabel, ModalFooter, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../../components/CommonDrawer/CommonDrawer';
import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';
import { DynamicSelectField } from '../../../../components/CommonForm/Fields/DynamicSelectField';
import { DynamicFormStyles } from '../../../../components/CommonForm/styles/DynamicFormStyles';
import NotificationModal from '../../../../components/CommonList/components/NotificationModal';
import FloatActionButton from '../../../../components/Generic/FloatActionButton';
import { useDebounce } from '../../../../hooks/useDebounce';
import { requests } from '../../../../services/v2/requests';
import { executeRequest } from '../../../../utils/requests/executeRequest';

const DynamicFormProduct = ({
  isEdit = false,
  identifier,
  initialValues,
  triggerRefresh,
  getInformationsShow,
  closeProductDrawer,
  sendPortalUnicoProduct,
  hasPortalUnicoErrorsProduct,
  getInconsistencies,
  getLogHistory,
  inconsistenciesList = null,
  logHistoryList = null,
}) => {
  const [ncmOptions, setNcmOptions] = useState([]);
  const [CpfCnpjOptions, setCpfCnpjOptions] = useState([]);

  const [hasFormPermission, setHasFormPermission] = useState(false);
  const [hasDocumentNumberPermission, setHasDocumentNumberPermission] = useState(false);

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const { isOpen: isInconsistenciesOpen, onOpen: openInconsistenciesDrawer, onClose: closeInconsistenciesDrawer } = useDisclosure();
  const { isOpen: isLogHistoryDrawerOpen, onOpen: openLogHistoryDrawer, onClose: closeLogHistoryDrawer } = useDisclosure();

  const yup = require('yup');

  const validationProductScheme = yup.object().shape({
    denominacao: yup.string().required('Campo obrigatório'),
    situacao: yup.string().required('Campo obrigatório'),
    modalidade: yup.string().required('Campo obrigatório'),
    codigoInterno: yup.string().required('Campo obrigatório'),
    descricao: yup.string().required('Campo obrigatório'),
    ncm: yup.string().required('Campo obrigatório'),
    cpfCnpjRaiz: yup.string().required('Campo obrigatório'),
  });

  const situationOptions = [
    { label: 'Ativado', value: 'ATIVADO' },
    { label: 'Desativado', value: 'DESATIVADO' },
    { label: 'Rascunho', value: 'RASCUNHO' },
  ];

  const modalityOptions = [
    { label: 'Importação', value: 'IMPORTACAO' },
    { label: 'Exportação', value: 'EXPORTACAO' },
  ];

  const checkCompany = async (codigoInterno, documentNumber) => {
    setHasFormPermission(false);

    if (!codigoInterno || !documentNumber) return;

    try {
      const res = await executeRequest({
        action: async () =>
          requests.put({
            url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${codigoInterno}/company/${documentNumber}/check?search=${identifier}&`,
            endpoint: true,
            customToken: 'customToken',
          }),
      });

      if (res) {
        setHasFormPermission(true);
      } else {
        setHasFormPermission(false);
      }
    } catch {
      setHasFormPermission(false);
    }
  };

  const fetchNcmOptions = async (code = '') => {
    if (code?.length < 4) {
      return;
    }

    const response = await executeRequest({
      action: () =>
        requests.get({
          url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/ncm/list?search=${code}&`,
          pageSize: 999,
          endpoint: true,
          customToken: 'customToken',
        }),
    });

    const formattedOptions = response.data.data.map((item) => ({
      label: `${item.code} - ${item.description}`,
      value: item.code,
    }));

    setNcmOptions(formattedOptions);
  };

  const onSubmitProduct = async (values, { setSubmitting }) => {
    if (isEdit) {
      const payload = {
        denominacao: values.denominacao,
        situacao: values.situacao,
        modalidade: values.modalidade,
        codigoInterno: values.codigoInterno,
        ncm: values.ncm.code ?? values.ncm,
        descricao: values.descricao,
        dataReferencia: values.dataReferencia,
        cpfCnpjRaiz: values.cpfCnpjRaiz,
        codigoInternoERP: values.codigoInternoERP,
      };

      await executeRequest({
        action: () =>
          requests.put({
            url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/${values.identifier}/edit`,
            data: payload,
            endpoint: true,
            customToken: 'customToken',
          }),
        msgSuccess: 'Produto editado com sucesso!',
        triggerRefresh,
        callback: () => {
          getInformationsShow(identifier);
        },
      });
    } else {
      await executeRequest({
        action: () =>
          requests.post({
            url: 'https://api.catalogo-de-produto.vendemmia.dev.br/adm/product/new',
            data: values,
            endpoint: true,
            customToken: 'customToken',
          }),
        msgSuccess: 'Produto cadastrado com sucesso!',
        triggerRefresh,
        callback: () => {
          setSubmitting(false);
          closeProductDrawer();
        },
      });
    }
  };

  const handleInconsistenciesDrawer = async (identifier) => {
    await getInconsistencies(identifier);

    openInconsistenciesDrawer();
  };

  const handleLogHistoryDrawer = async (identifier) => {
    await getLogHistory(identifier);

    openLogHistoryDrawer();
  };

  const { isOpen: isOpenNotificationModal, onOpen: onOpenNotificationModal, onClose: onCloseNotificationModal } = useDisclosure();

  const handleClick = (userDecision) => {
    if (userDecision) {
      sendPortalUnicoProduct(identifier);
    }
    onCloseNotificationModal();
  };

  const debouncedCheckCompany = useDebounce(checkCompany, 1000);

  useEffect(() => {
    const fetchCpfCnpjList = async () => {
      const res = await executeRequest({
        action: () =>
          requests.get({
            url: `https://api.catalogo-de-produto.vendemmia.dev.br/adm/company/certificate/list`,
            endpoint: true,
            customToken: 'customToken',
          }),
      });

      const formattedOptions = res.data.map((item) => ({
        label: `${item.documentNumber} (${item.name})`,
        value: item.documentNumber,
      }));

      setCpfCnpjOptions(formattedOptions);
    };

    fetchCpfCnpjList();
  }, []);

  return (
    <Formik initialValues={initialValues} validationSchema={validationProductScheme} onSubmit={onSubmitProduct}>
      {({ values, errors, setFieldValue, handleSubmit, isSubmitting }) => (
        useEffect(() => {
          if (values.codigoInterno && values.cpfCnpjRaiz) {
            debouncedCheckCompany(values.codigoInterno, values.cpfCnpjRaiz);
          }
        }, [values.codigoInterno, values.cpfCnpjRaiz]),
        useEffect(() => {
          if (values.codigoInterno.length > 0) {
            setHasDocumentNumberPermission(true);
          } else {
            setHasDocumentNumberPermission(false);
            setHasFormPermission(false);
          }
        }, [values.codigoInterno]),
        useEffect(() => {
          if (values.ncm) {
            fetchNcmOptions(values.ncm.code);
          }
        }, [values.ncm]),
        (
          <Form style={{ height: '100%' }} onSubmit={handleSubmit}>
            <DynamicFormStyles />

            <Grid gridTemplateColumns="repeat(2, 1fr)" p="25px" gap="20px" overflow="auto">
              <DynamicInputField
                props={{
                  name: 'codigoInterno',
                  placeholder: 'Código Interno',
                }}
              />

              <DynamicSelectField
                props={{
                  name: 'cpfCnpjRaiz',
                  placeholder: 'CPF/CNPJ',
                  list: CpfCnpjOptions,
                  keyProp: 'value',
                  displayProp: 'label',
                  isDisabled: !hasDocumentNumberPermission,
                }}
              />

              <DynamicInputField
                props={{
                  name: 'denominacao',
                  placeholder: 'Denominação do produto',
                  isDisabled: !hasFormPermission,
                }}
              />

              <DynamicInputField
                props={{
                  name: 'codigoInternoERP',
                  placeholder: 'Codigo Interno ERP',
                  isDisabled: !hasFormPermission,
                }}
              />

              <DynamicSelectField
                props={{
                  name: 'situacao',
                  placeholder: 'Situação',
                  list: situationOptions,
                  keyProp: 'value',
                  displayProp: 'label',
                  isDisabled: !hasFormPermission,
                }}
              />

              <DynamicSelectField
                props={{
                  name: 'modalidade',
                  placeholder: 'Modalidade',
                  list: modalityOptions,
                  keyProp: 'value',
                  displayProp: 'label',
                  isDisabled: !hasFormPermission,
                }}
              />

              <GridItem colSpan="2">
                <FormControl onPaste={(e) => fetchNcmOptions(e.target.value)}>
                  <FormLabel htmlFor="ncm">NCM</FormLabel>

                  <Field name="ncm">
                    {({ field }) => (
                      <>
                        <Select
                          isDisabled={!hasFormPermission}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: '47px',
                              backgroundColor: '#F5F5F5',
                              border: state.isFocused || state.isActive ? '1px solid #6C48C2' : '1px solid #ccc',
                              boxShadow: state.isFocused || state.isActive ? '0 0 0 1px #6C48C2' : 'none',
                              '&:hover': {
                                border: '1px solid #6C48C2',
                              },
                            }),
                          }}
                          name="ncm"
                          placeholder="Digite no mínimo 4 caracteres. Ex: 0101"
                          isClearable
                          value={ncmOptions.find((option) => option?.value === field?.value?.code)}
                          options={ncmOptions}
                          onChange={(option) => {
                            setFieldValue('ncm', option?.value);
                            setNcmOptions([]);
                          }}
                          onInputChange={(inputValue) => {
                            fetchNcmOptions(inputValue);
                          }}
                        />

                        <ErrorMessage name={'ncm'} component={Text} className="error-message-error" />
                      </>
                    )}
                  </Field>
                </FormControl>
              </GridItem>

              <GridItem colSpan="2">
                <DynamicInputField
                  props={{
                    name: 'descricao',
                    placeholder: 'Descrição complementar',
                    as: 'textarea',
                    minH: '230px',
                    pt: '10px',
                    bgColor: '#F5F5F5',
                    isDisabled: !hasFormPermission,
                  }}
                />
              </GridItem>
            </Grid>

            {!isEdit ? (
              <ModalFooter borderTop="1px" borderColor="#7070702E" p="25px 0px 0px 0px">
                <Button
                  h="47px"
                  w="fit-content"
                  variant="submit"
                  type="submit"
                  isLoading={isSubmitting}
                  isDisabled={!hasFormPermission || Object.keys(errors).length > 0}>
                  Salvar
                </Button>
              </ModalFooter>
            ) : (
              <FloatActionButton
                saveButton={
                  <Button
                    rightIcon={<MdOutlineSave color="#FFFFFF" size={20} />}
                    type="submit"
                    h="50px"
                    px="24px"
                    py="14px"
                    bgColor="primary"
                    borderRadius="27px"
                    color="#FFFFFF"
                    loadingText="Salvando"
                    _hover={{ bgColor: '#8067DC' }}
                    isLoading={isSubmitting}
                    isDisabled={!hasFormPermission || Object.keys(errors).length > 0}>
                    <Text>Salvar</Text>
                  </Button>
                }
                options={[
                  <Button
                    key={1}
                    type="button"
                    bgColor="green"
                    h="50px"
                    variant="primary"
                    borderRadius="50px"
                    _hover={{ bgColor: '#70D499' }}
                    leftIcon={<MdFileUpload color="#FFFFFF" size={20} />}
                    onClick={() => onOpenNotificationModal()}>
                    Enviar para o Portal Único
                  </Button>,

                  <Button
                    key={2}
                    type="button"
                    bgColor="green"
                    h="50px"
                    variant="primary"
                    borderRadius="50px"
                    _hover={{ bgColor: '#70D499' }}
                    leftIcon={<MdHistory color="#FFFFFF" size={20} />}
                    onClick={() => handleLogHistoryDrawer(identifier)}>
                    Histórico de alterações
                  </Button>,

                  hasPortalUnicoErrorsProduct && (
                    <Button
                      key={3}
                      type="button"
                      bgColor="green"
                      h="50px"
                      variant="primary"
                      borderRadius="50px"
                      _hover={{ bgColor: '#70D499' }}
                      leftIcon={<MdOutlineReportProblem color="#FFFFFF" size={20} />}
                      onClick={() => handleInconsistenciesDrawer(identifier)}>
                      Inconsistências
                    </Button>
                  ),
                ]}
                isOpen={isOpenFloatButton}
                setIsOpen={setIsOpenFloatButton}
              />
            )}

            <CommonDrawer
              heading="Inconsistências"
              placement="left"
              isOpen={isInconsistenciesOpen}
              onOpen={openInconsistenciesDrawer}
              onClose={closeInconsistenciesDrawer}>
              <Table className="table-default" w="full" mt="10px">
                <Thead>
                  <Tr>
                    <Th>Descrição</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {inconsistenciesList && Array.isArray(inconsistenciesList) ? (
                    inconsistenciesList.map((message, key) => (
                      <Tr key={key}>
                        <Td>{message}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="1">
                        <Text textAlign="center">Nenhum erro encontrado no histórico.</Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </CommonDrawer>

            <CommonDrawer
              heading="Histórico de alterações"
              placement="left"
              width="60%"
              isOpen={isLogHistoryDrawerOpen}
              onOpen={openLogHistoryDrawer}
              onClose={closeLogHistoryDrawer}>
              <Table className="table-default" w="full" mt="10px">
                <Thead>
                  <Tr>
                    <Th>Descrição</Th>
                    <Th>Versão</Th>
                    <Th>Usuário</Th>
                    <Th>Efetuada em</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {logHistoryList.length > 0 ? (
                    logHistoryList.map((entity, key) => (
                      <Tr key={key}>
                        <Td>{entity.description}</Td>

                        <Td w="75px">{entity.versao}</Td>

                        <Td w="100px">{entity.userName}</Td>

                        <Td w="120px">{entity.createdAtFormatted}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="4">
                        <Text textAlign="center">Nenhuma alteração registrada.</Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </CommonDrawer>

            <NotificationModal
              isOpen={isOpenNotificationModal}
              onOpen={onOpenNotificationModal}
              onClose={onCloseNotificationModal}
              callback={handleClick}
              modalText={'Você tem certeza que deseja enviar o produto para o portal único?'}
            />
          </Form>
        )
      )}
    </Formik>
  );
};

export default DynamicFormProduct;
