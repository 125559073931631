import React, { useEffect, useRef, useState } from 'react';

import { MdAdd, MdOutlineReceiptLong, MdOutlineViewKanban, MdOutlineViewTimeline } from 'react-icons/md';

import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsTrip, filterOptionsTripKanban } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import TripCte from './components/CTE/TripCte';
import TravelKanban from './components/Kanban/TravelKanban';
import ModalFreight from './components/TravelList/ModalFreight';
import TripList from './components/TravelList/TripList';
import TravelManagementPanelTabs from './components/TravelManagementPanelTabs';

function TravelManagementPage() {
  const [isLoading, setIsLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);
  const [filterSelected, setFilterSelected] = useState([]);

  const [list, setList] = useState([]);
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({});

  const [tab, setTab] = useState('tripList');
  const [kanbanTab, setKanbanTab] = useState('Preparação');

  const [totalCte, setTotalCte] = useState('0');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const request = useRef(0);

  const hasPermission = permissions.logisticTripFullManagement || permissions.logisticTripView;

  const load = async (filters, key, page) => {
    setIsLoading(true);

    try {
      const res = await executeRequest({
        action: () => requests.listDriverFreight(filters, page),
      });

      if (request.current && request.current > key) {
        setIsLoading(false);
        return;
      }

      request.current = key;

      const identifiers = res?.data.map((item) => item.identifier);

      const metricsData = await Promise.all(
        identifiers.map(async (id) => {
          try {
            return { id, metrics: requests.metricsTrip(id) };
          } catch (error) {
            console.error(`Erro ao buscar métricas para o identificador ${id}:`, error);
            return { id, metrics: null };
          }
        })
      );

      const updatedData = res?.data.map((item) => ({
        ...item,
        metrics: metricsData.find((metric) => metric.id === item.identifier)?.metrics || {},
      }));

      setList(updatedData);
      setMetadata(res?.meta);
      setFilterSelected(filters);
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const tabOptionsKanban = [
    { key: 'Preparação', title: 'Preparação', text: 'Preparação' },
    { key: 'Execução', title: 'Execução', text: 'Execução' },
    { key: 'Entrega', title: 'Entrega', text: 'Entrega' },
    { key: 'Faturamento', title: 'Faturamento', text: 'Faturamento' },
  ];

  const tabOptions = [
    {
      key: 'tripList',
      title: 'Viagens em lista',
      text: 'Lista de viagens',
      icon: <MdOutlineViewTimeline size={20} />,
      link: '/logistic/trip',
    },
    {
      key: 'kanban',
      title: 'Viagens em kanban',
      text: 'Quadro de viagens',
      role: 'ROLE_CLIENT',
      icon: <MdOutlineViewKanban size={20} />,
      link: '/logistic/trip',
    },
    { key: 'cteList', title: "CT-e's", text: "CT-e's", icon: <MdOutlineReceiptLong size={20} />, link: '/logistic/trip' },
  ];

  const reloadList = () => {
    setIsLoading(true);

    requests.listDriverFreight(filterSelected, metadata.current_page).then((data) => {
      setList(data.data);
      setIsLoading(false);
    });
  };

  const exportListFABAction = (filters, key) => {
    //preset
    const extension = `xlsx`;
    const endpoint = `/adm/driver-freight/export`;
    const fileName = `report-trip`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const getFilterOptions = async () => {
    if (tab === 'kanban') {
      setFilterOptions(await filterOptionsTripKanban());
    } else {
      setFilterOptions(await filterOptionsTrip());
    }
  };

  const setCurrentTab = (kanbanTab) => {
    setKanbanTab(kanbanTab);
  };

  const TabHeaderContent = ({ title, subtitle, children }) => (
    <Flex h="65px" w="full" justify="space-between" align="center">
      <Flex gap="2px" direction="column">
        <Text textStyle="cardTitle" fontWeight="bold" color="primary">
          {title}
        </Text>
        <Text textStyle="subtitle" color="#BEBEBE" fontSize={{ base: '14px', md: '16px' }}>
          {subtitle}
        </Text>
      </Flex>
      {children}
    </Flex>
  );

  const cardHeader = (tab) => {
    if (tab === 'kanban') {
      return (
        <Flex h="65px" w="full" align="center" justify="space-between">
          <TravelManagementPanelTabs tab={kanbanTab} setTab={setTab} setCurrentTab={setCurrentTab} tabs={tabOptionsKanban} isKanban />
          <TravelManagementPanelTabs tab={tab} setTab={setTab} tabs={tabOptions} />
        </Flex>
      );
    }

    const tabHeaders = {
      cteList: {
        title: "CT-e's em trânsito",
        subtitle: `Existe um total de ${totalCte} CT-e's`,
      },
      default: {
        title: 'Viagens em preparação',
        subtitle: `Existe um total de ${metadata?.total_count} viagens`,
      },
    };

    const { title, subtitle } = tabHeaders[tab] || tabHeaders.default;

    return (
      <TabHeaderContent title={title} subtitle={subtitle}>
        <TravelManagementPanelTabs tab={tab} setTab={setTab} tabs={tabOptions} />
      </TabHeaderContent>
    );
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="trip"
      title="Gestão de Viagens"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Motorista, viagem, transportadora, arquivo ou placa"
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={load}
      list={list}
      metadata={metadata}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      allowFiltersOverride={true}
      // refreshSeconds={1500}
      FAB={[
        permissions.logisticTripCreate && {
          action: (
            <Button
              h="50px"
              bg="primary"
              borderRadius="50px"
              color="white"
              _hover={{ opacity: '0.8' }}
              leftIcon={<MdAdd />}
              onClick={() => onOpen()}>
              Nova viagem
            </Button>
          ),
        },
        {
          title: 'Baixar relatório',
          text: 'Baixar relatório de Viagens (.csv)',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      <Card h="calc(100vh - 192px)" m="10px" header={cardHeader(tab)}>
        {tab === 'tripList' && (
          <TripList
            list={list}
            isLoading={isLoading}
            metadata={metadata}
            setMetadata={setMetadata}
            action={action}
            setAction={setAction}
          />
        )}

        {tab === 'kanban' && <TravelKanban filterSelected={filterSelected} kanbanTab={kanbanTab} tabOpt={tabOptionsKanban} />}

        {tab === 'cteList' && <TripCte tab={tab} filterSelected={filterSelected} setTab={setTab} setTotalCte={setTotalCte} />}
      </Card>

      <ModalFreight
        entity={false}
        isOpen={isOpen}
        onClose={onClose}
        title="Cadastro de viagem"
        subTitle="Você pode adicionar os dados e salvar sua viagem como rascunho."
        tab={tab}
        reloadList={reloadList}
      />
    </Page>
  );
}

export default TravelManagementPage;
