import React, { createContext, useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';
import { MdAdd, MdOutlineModeEditOutline, MdOutlineSave } from 'react-icons/md';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicForm, DynamicFormBody, DynamicFormButton, DynamicFormFooter } from '../../../components/CommonForm';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import { HeaderModalDetails } from '../../../components/CommonModal/components/HeaderModalDetails';
import CommonPage from '../../../components/CommonPage/CommonPage';
import FloatActionButton from '../../../components/Generic/FloatActionButton';
import { PageContainer } from '../../../components/PageContainer/PageContainer';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import { requests } from '../../../services/v2/requests';
import { filterOptionsTruckingRateQuote } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';
import addParametersToURL from '../../../utils/url/addParametersToURL ';
import hasSpecificURLParameters from '../../../utils/url/hasSpecificURLParameter';
import DynamicFormClientManagement from '../client-management/components/DynamicFormClientManagement';

import { commonListConfig } from './components/commonListConfig';
import { DynamicFormTruckingRateQuote } from './components/DynamicFormTruckingRateQuote';
import { clientManagementData } from './Helpers/clientManagementData';
import { initialFreightData } from './Helpers/initialFreightData';
import { transformData } from './Helpers/transformData';
import { validationClientMangementScheme } from './Helpers/validationClientMangementScheme';
import { validationTruckingRateQuoteScheme } from './Helpers/validationTruckingRateQuoteScheme';

export const TruckingRateQuoteContext = createContext();
export const TruckingRateQuotePage = () => {
  const hasPermission = permissions.vendemmiaTruckingRateQuote;

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [processOptions, setProcessOptions] = useState([]);
  const [tripOptions, setTripOptions] = useState([]);

  const [list, setList] = useState([]);
  const [code, setCode] = useState('');
  const [hasType, setHasType] = useState();

  const [initialValues, setinitialValues] = useState(initialFreightData);

  const [selectOptions, setSelectOptions] = useState({
    users: [],
    trips: [],
    cities: [],
    states: [],
    vehicles: [],
  });

  const [clientsOptions, setClientsOptions] = useState([]);
  const [cityOrigin, setCityOrigin] = useState([]);
  const [cityDestination, setCityDestination] = useState([]);

  // Hooks de controle
  const { isOpen: isFormQuoteModal, onOpen: openFormQuoteModal, onClose: closeFormQuoteModal } = useDisclosure();
  const {
    isOpen: isClientManagementDrawer,
    onOpen: openClientManagementDrawer,
    onClose: closeClientManagementDrawer,
  } = useDisclosure();

  const load = (filters, key, page) => {
    /* window.localStorage.removeItem('entity-old'); */
    getQuoteList(filters, key, page);
  };

  // Utilizção do hook personalizado para o refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const handleChangesolicitor = async (url) => {
    await executeRequest({
      action: () =>
        requests.post({
          url,
        }),
      msgSuccess: 'Solicitante alterado com sucesso!',
      setIsLoading,
    });
  };

  const config = commonListConfig({ handleChangesolicitor });

  const getProcessList = useCallback(
    debounce(async (inputValue, setIsFetching, setOptionsMessage) => {
      try {
        const response = await executeRequest({
          action: () =>
            requests.get({
              url: `/process/list?search=${inputValue}&`,
              pageSize: 999,
            }),
          setIsLoading,
        });

        setProcessOptions(response.data?.data || []);
        setOptionsMessage('Nenhum resultado encontrado');
      } catch (error) {
        console.error('Erro ao buscar processos:', error);
        setProcessOptions([]);
      } finally {
        setIsFetching(false); // 🔥 Desativa o estado de carregamento após a busca
      }
    }, 1000),
    [setProcessOptions]
  );

  const getTripList = useCallback(
    debounce(async (inputValue, setIsFetching, setOptionsMessage) => {
      const params =
        'status[]=DRAFT&status[]=CREATED&status[]=SENT_TO_DRIVER&status[]=ACCEPTED_BY_DRIVER&status[]=GOING_TO_COLLECT&status[]=TRAVELLING';

      try {
        const response = await executeRequest({
          action: () =>
            requests.get({
              url: `/adm/driver-freight/list?search=${inputValue}&${params}&`,
              pageSize: 999,
            }),
          setIsLoading,
        });

        setTripOptions(response.data?.data || []);

        setOptionsMessage('Nenhum resultado encontrado');
      } catch (error) {
        console.error('Erro ao buscar processos:', error);
        setTripOptions([]);
      } finally {
        setIsFetching(false); // 🔥 Desativa o estado de carregamento após a busca
      }
    }, 1000),
    [setTripOptions]
  );

  // Funções de requisição
  const getQuoteList = async (filters, _key, page) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: '/tms/quote/transport/list',
          filters,
          page,
        }),
      setIsLoading,
    });

    setList(res?.data?.data);
    setMetadata(res?.data?.meta);
  };

  // Buscar cidades com base no codigo do estado
  const fetchCitiesList = async (setCities, values) => {
    const { code } = values;

    setCities([]);

    if (!code) {
      return;
    }

    const cities = await executeRequest({
      action: () => requests.get({ url: `/tms/quote/transport/city/list?federativeUnity=${code}&`, pageSize: 999 }),
      setIsLoading,
    });
    setCities(cities.data?.data);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsTruckingRateQuote());
  };

  const loadSelectOptions = async () => {
    const vehicles = [
      { label: 'Caminhão', value: 'TRUCK' },
      { label: 'Carreta', value: 'CARRETA' },
      { label: 'Contêiner', value: 'CONTAINER' },
    ];

    try {
      setIsLoading(true);

      const [responsible, clients, cities, states] = await Promise.all([
        executeRequest({ action: () => requests.get({ url: '/quote/client/users' }) }),
        executeRequest({ action: () => requests.get({ url: '/client/list/trip', pageSize: 99999 }) }),
        executeRequest({ action: () => requests.get({ url: '/tms/quote/transport/city/list', pageSize: 999 }) }),
        executeRequest({ action: () => requests.get({ url: '/tms/quote/transport/state/list', pageSize: 999 }) }),
      ]);

      setSelectOptions({ responsible, cities, states, vehicles });
      setClientsOptions(clients);
    } catch (error) {
      console.error('Erro ao carregar opções do select:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateClientsOptions = async () => {
    setClientsOptions(
      await executeRequest({
        action: () =>
          requests.get({
            url: '/quote/client/list',
          }),
        setIsLoading,
      })
    );
  };

  const handleClientNew = async (values) => {
    await executeRequest({
      action: () =>
        requests.post({
          url: '/client/new',
          data: values,
        }),
      msgSuccess: 'Cliente criado com sucesso',
      msgError: 'Erro ao criar o cliente, tente novamente.',
      setIsLoading,
      triggerRefresh,
      callback: () => {
        onClose();
        updateClientsOptions();
      },
    });
  };

  const createTruckingQuoteNew = async (values) => {
    await executeRequest({
      action: () =>
        requests.post({
          url: '/tms/quote/transport/new',
          data: values,
        }),
      msgSuccess: 'Cotação rodoviário criado com sucesso',
      msgError: 'Erro ao criar o cotação rodoviário, tente novamente.',
      setIsLoading,
      triggerRefresh,
      callback: () => {
        closeFormQuoteModal();
      },
    });
  };

  // Funções de ação
  const handleOpenModal = async (_, itemProps) => {
    const { code } = itemProps || {};

    setCode(code);

    openFormQuoteModal();

    /* console.log(itemProps); */
    if (hasType === 'edit') {
      let initialValues = transformData(itemProps) || initialFreightData;
      console.log(initialValues);
      setinitialValues(initialValues);
    }

    const data = await loadSelectOptions();
  };

  const onSubmitForm = async (data) => {
    const formDataTrucking = transformData(data);

    if (hasType === 'edit') {
      console.log('edit');
    } else {
      console.log('create');
      createTruckingQuoteNew(formDataTrucking);
    }

    console.log(data, formDataTrucking);
  };

  const customButtons = [
    {
      main: [
        {
          label: 'Editar',
          tooltip: 'Editar produto',
          icon: <MdOutlineModeEditOutline size={20} />,
          action: (identifier, itemProps) => {
            handleOpenModal(identifier, itemProps);
            addParametersToURL({ type: 'edit' });
          },
        },
      ],
    },
  ];

  useEffect(() => {
    getFilterOptions();
  }, []);

  // Atualiza o hasType quando o parâmetro de URL muda
  useEffect(() => {
    const urlType = hasSpecificURLParameters(['type']).value;
    setHasType(urlType);
  }, [window.location.href]);

  return (
    <TruckingRateQuoteContext.Provider
      value={{
        openClientManagementDrawer,
        selectOptions,
        clientsOptions,
        isLoading,
        setIsLoading,
        triggerRefresh,
        getProcessList,
        getTripList,
        processOptions,
        tripOptions,
        cityOrigin,
        setCityOrigin,
        cityDestination,
        setCityDestination,
        fetchCitiesList,
      }}>
      <CommonPage
        screen="quote"
        title="Cotação de frete"
        breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
        textFilterPlaceholder="Buscar por referência ou texto"
        hasPermission={hasPermission}
        metadata={metadata}
        list={list}
        load={load}
        filterOptions={filterOptions}
        isContentLoading={isLoading}
        isRefreshLoading={isLoading}
        showFilters={true}
        showPeriodFilter={false}
        allowEmptySearchPeriod={true}
        refreshSeconds={false}
        forceLoadTrigger={forceLoadTrigger}>
        <PageContainer>
          <CommonList
            list={list}
            rawData={list}
            //actions
            action={action}
            sorting={sorting}
            metadata={metadata}
            setAction={setAction}
            setMetadata={setMetadata}
            handleSort={handleSort}
            //custom
            customButtons={customButtons}
            //loading
            isLoading={isLoading}
            {...config}
          />
        </PageContainer>

        {/* Modal de criação/edição de cotação de frete */}
        <CommonModal
          heading={
            <HeaderModalDetails
              title={hasType === 'edit' ? `#${code}` : 'Cotação - Rodoviário'}
              description={hasType === 'edit' ? 'Cotação - Rodoviário' : 'Nova cotação'}
              closeModal={closeFormQuoteModal}
              setIdentifier={setCode}
            />
          }
          isOpen={isFormQuoteModal}
          onOpen={openFormQuoteModal}
          onClose={closeFormQuoteModal}
          closeButton={false}
          fullpage={true}>
          <DynamicForm
            config={{
              initialValues,
              validationSchema: validationTruckingRateQuoteScheme,
              callback: onSubmitForm,
            }}>
            <DynamicFormBody>
              <DynamicFormTruckingRateQuote />
            </DynamicFormBody>
            <DynamicFormFooter>
              <DynamicFormButton icon={<MdOutlineSave size={20} />} name={'Salvar'} loadingText={'Salvando'} type="button" />
            </DynamicFormFooter>
          </DynamicForm>
        </CommonModal>

        {/* Drawer de cadastro de clientes */}
        <CommonDrawer
          heading="Dados do cliente"
          placement="left"
          isOpen={isClientManagementDrawer}
          onOpen={openClientManagementDrawer}
          onClose={closeClientManagementDrawer}
          isLoading={isLoading}>
          <DynamicForm
            config={{
              initialValues: clientManagementData,
              validationSchema: validationClientMangementScheme,
              callback: handleClientNew,
            }}>
            <DynamicFormClientManagement />
            <DynamicFormFooter>
              <DynamicFormButton name={'Salvar'} type="button" />
            </DynamicFormFooter>
          </DynamicForm>
        </CommonDrawer>

        {/* Menu flutuante */}
        <FloatActionButton
          showOptions={false}
          isOpen={isOpenFloatButton}
          setIsOpen={setIsOpenFloatButton}
          saveButton={
            <Button
              leftIcon={<MdAdd color="#FFFFFF" size={20} />}
              _hover={{ bgColor: '#70D499' }}
              bg="green"
              p="1.5rem"
              pb="1.5rem"
              pl="1.3rem"
              pr="1.3rem"
              borderRadius="50px"
              onClick={(identifier, itemProps) => {
                handleOpenModal(identifier, itemProps);
                addParametersToURL({ type: 'new' });
              }}>
              <Text mr="10px" color="white">
                Nova cotação
              </Text>
            </Button>
          }
        />
      </CommonPage>
    </TruckingRateQuoteContext.Provider>
  );
};

export const useTruckingRateQuote = () => useContext(TruckingRateQuoteContext);
