// Função para transformar o objeto recebido da API para o formato esperado
export const transformData = (input = {}) => ({
  addressDestination: input.addressDestination || input.addressDestination,
  addressOrigin: input.addressOrigin || '',
  cofinsPercent: input.cofinsPercent || 7.6,
  cofinsMonetary: input.cofinsMonetary || '',
  companyDestination: input.companyDestination?.identifier || '',
  companyOrigin: input.companyOrigin?.identifier || '',
  destination: input.cityDestination?.code || '',
  distance: input.distance || '',
  freightRiskMonetary: input.freightRiskMonetary || '',
  freightRiskPercent: input.freightRiskPercent || '',
  freightValuePercent: input.freightValuePercent || '',
  freightValueMonetary: input.freightValueMonetary || '',
  freightWeightMonetary: input.freightWeightMonetary || '',
  goodsValue: input.goodsValue || '',
  goodsWeight: input.goodsWeight || '',
  icmsBaseMonetary: input.icmsBaseMonetary || '',
  icmsBasePercent: input.icmsBasePercent || '',
  icmsMonetary: input.icmsMonetary || '',
  icmsPercent: input.icmsPercent || '',
  origin: input.cityOrigin?.code || '',
  pisCofinsBaseMonetary: input.pisCofinsBaseMonetary || '',
  pisCofinsBasePercent: input.pisCofinsBasePercent || '',
  pisMonetary: input.pisMonetary || '',
  pisPercent: input.pisPercent || 1.65,
  process: Array.isArray(input.process) ? input.process.map((p) => p.code) : [],
  responsible: input.responsible?.identifier || '',
  servicesMonetary: input.servicesMonetary || '',
  tollMonetary: input.tollMonetary || '',
  totalMonetary: input.totalMonetary || '',
  trip: input.trip?.identifier || '',
  truckAxleMonetary: input.truckAxleMonetary || '',
  truckAxleQuantity: input.truckAxleQuantity || '',
  vehicleModality: input.vehicleModality?.value || '',
  /* status: input.status.value || 'RASCUNHO', */
});
