export const sanitizeUrl = (url) => {
  // Remove duplicações de "??", "&&", "?&" e "&?"
  let sanitized = url
    .replace(/\?{2,}/g, '?') // Substitui "??" por "?"
    .replace(/&{2,}/g, '&') // Substitui "&&" por "&"
    .replace(/&\?/g, '&') // Substitui "&?" por "&"
    .replace(/\?&/g, '?') // Substitui "?&" por "?"
    .replace(/(\?.*?)\?/g, '$1') // Remove "?" extra no meio
    .replace(/&$/, ''); // Remove "&" no final, se houver

  // Garante que a query string sempre comece com "?"
  if (!sanitized.includes('?') && sanitized.includes('=')) {
    sanitized = sanitized.replace(/([^?]*)(.*)/, '$1?$2');
  }

  return sanitized;
};
