import React from 'react';

import { Route } from 'react-router-dom';

import ExpeditionDocumentsPage from '../pages/warehouse/expedition/documents/ExpeditionDocumentsPage';
import ExpeditionPage from '../pages/warehouse/expedition/ExpeditionPage';
import PickingPackingPage from '../pages/warehouse/picking-packing/PickingPackingPage';
import ReceiptPage from '../pages/warehouse/receipt/ReceiptPage'; // eslint-disable-next-line import/order
import StockAgingPage from '../pages/warehouse/stock-aging/StockAgingPage';
import { StockTurnoverPage } from '../pages/warehouse/stock-turnover/StockTurnoverPage'; // eslint-disable-next-line import/order
import StockPage from '../pages/warehouse/stock/StockPage';
import TransportSchedulePage from '../pages/warehouse/transport-schedule/TransportSchedulePage';

export const WarehouseRoutes = (
  <>
    <Route exact path="/warehouse/receipt" element={<ReceiptPage />} />
    <Route exact path="/warehouse/stock" element={<StockPage />} />
    <Route exact path="/warehouse/picking-packing" element={<PickingPackingPage />} />
    <Route exact path="/warehouse/expedition" element={<ExpeditionPage />} />
    <Route exact path="/warehouse/expedition/documents" element={<ExpeditionDocumentsPage />} />
    <Route exact path="/warehouse/stock-aging" element={<StockAgingPage />} />
    <Route exact path="/warehouse/stock-turnover" element={<StockTurnoverPage />} />
    <Route exact path="/warehouse/transport-schedule" element={<TransportSchedulePage />} />
  </>
);
