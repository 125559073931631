import { useContext } from 'react';

import { Flex, ListItem, Text } from '@chakra-ui/react';

import CommonListCategories from '../CommonListCategories';
import CommonListItemLine from '../CommonListItemLine';
import { CommonListContext } from '../contexts/CommonListContext';
import { FormattedList } from '../Helpers/formattedList';

/**
* @param  rawItem necessário para o recebimento de todos os parametros do item(o item padrão vem com filtro de campos especificos)

*/

const CommonListTable = ({ ...props }) => {
  const {
    subList,
    subcategories,
    data,
    hasDetailsButtonSublist,
    detailsRedirectEndpointSublist,
    emptyListMessage,
    maxLengthResumeChildren,
    resizeRowsChildren,
  } = useContext(CommonListContext);

  const content = props?.content;
  const customButtons = props?.customButtons;
  const pathSublist = props?.pathSublist;
  const sublistPathItens = props?.sublistPathItens;
  const listIndex = props?.listIndex;

  /* const formattedPathSublist = sublistPathItens && removeKeysFromList(sublistPathItens, hiddenCategories ? hiddenCategories : []); */
  const formattedPathSublist =
    pathSublist != '' ? FormattedList(subList, subcategories, sublistPathItens) : FormattedList([data[listIndex]], subcategories);

  //receber todos os parametros, inclusive os ocultos quem vem de commonListConfig.jsx do subCategories
  const subCategoriesProps = formattedPathSublist[0];

  const identifier = subCategoriesProps?.identifier;

  return (
    <Flex direction={'column'} gap={'15px'} p={'15px'}>
      <CommonListCategories categories={subcategories} gridTemplateLength={subcategories && resizeRowsChildren} />

      {
        pathSublist ? (
          formattedPathSublist.length > 0 ? (
            formattedPathSublist?.map((subitem, key) => (
              <ListItem key={key}>
                <CommonListItemLine
                  item={subitem}
                  content={content}
                  gridTemplateLength={subcategories && resizeRowsChildren}
                  identifier={identifier ?? ''}
                  categories={subcategories}
                  hasDetailsButton={hasDetailsButtonSublist}
                  detailsRedirectEndpoint={detailsRedirectEndpointSublist}
                  customButtons={customButtons}
                  maxLengthResume={maxLengthResumeChildren}
                  listIndex={listIndex}
                  data={data[listIndex][pathSublist]}
                />
              </ListItem>
            ))
          ) : (
            <Text alignSelf={'left'}>{emptyListMessage}</Text>
          )
        ) : subList && subList.length > 0 ? (
          subList?.map((subitem, key) => {
            return (
              <ListItem key={key}>
                <CommonListItemLine
                  item={subitem}
                  content={content}
                  gridTemplateLength={resizeRowsChildren}
                  identifier={identifier ?? ''}
                  categories={subcategories}
                  hasDetailsButton={hasDetailsButtonSublist}
                  detailsRedirectEndpoint={detailsRedirectEndpointSublist}
                  customButtons={customButtons}
                  maxLengthResume={maxLengthResumeChildren}
                  listIndex={key}
                  data={data}
                />
              </ListItem>
            );
          })
        ) : (
          <Text alignSelf={'left'}>{emptyListMessage}</Text>
        ) /* executa caso seja uma lista normal */
      }
    </Flex>
  );
};

export default CommonListTable;
