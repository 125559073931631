import { isCompletedTrip } from '..//utils/translate';

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = (lat2 - lat1) * (Math.PI / 180); // degree 2 rad
  var dLon = (lon2 - lon1) * (Math.PI / 180); // degree 2 rad
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const generateTrajectoryPath = async (trajectories, checkpoints, loadDirections, status, currentDriverPosition) => {
  // Validar e ajustar trajetos
  const validTrajectories = trajectories?.filter(
    (traj) =>
      traj.latitude !== '0' &&
      traj.longitude !== '0' &&
      traj.latitude !== '' &&
      traj.longitude !== '' &&
      traj.latitude !== null &&
      traj.longitude !== null
  );

  if (validTrajectories?.length < 2) {
    return []; // Retorna vazio se o trajeto for inválido
  }

  let result = [];

  if (checkpoints?.length > 0) {
    await generateDirectionWithCheckpoint(validTrajectories, checkpoints, result, loadDirections, status);
  } else if ((Array.isArray(currentDriverPosition) && currentDriverPosition.length > 0) ||
    (typeof currentDriverPosition === "object" && currentDriverPosition !== null && Object.keys(currentDriverPosition).length > 0)) {
    await generateDirectionPublicCheckpoint(validTrajectories, result, loadDirections, status, currentDriverPosition);
  } else {
    await generateDirectionWithoutCheckpoint(validTrajectories, result, loadDirections, status);
  }

  return result;
};

const generateDirectionPublicCheckpoint = async (trajectories, result, loadDirections, status, currentDriverPosition) => {
  if (trajectories == undefined || trajectories == null) {
    return;
  }

  // Ponto de origem
  const origin = trajectories[0];

  // Destino final
  const destination = trajectories[trajectories.length - 1];  // Adapte para pegar o destino real se for diferente

  // Waypoints do trecho COMPLETED
  let completedWaypoints = [];
  if (trajectories.length > 2) {
    for (let i = 1; i < trajectories.length; i++) {
      completedWaypoints.push(trajectories[i]);
    }
  }

  if (isCompletedTrip(status)) {
    result.push({
      directions: await loadDirections(origin, destination, completedWaypoints),
      status: 'COMPLETED',
    });
  } else {
    // Caminho COMPLETED (Origem até a posição atual)
    result.push({
      directions: await loadDirections(origin, currentDriverPosition, completedWaypoints),
      status: 'COMPLETED',
    });

    // Caminho PENDING (Posição atual até o destino)
    result.push({
      directions: await loadDirections(currentDriverPosition, destination, []),
      status: 'PENDING',
    });
  }


};

const generateDirectionWithoutCheckpoint = async (trajectories, result, loadDirections, status) => {
  if (trajectories == undefined || trajectories == null) {
    return;
  }

  // Ponto de origem
  const origin = trajectories[0];

  // Destino final
  const destination = trajectories[trajectories.length - 1];  // Adapte para pegar o destino real se for diferente

  // Waypoints do trecho COMPLETED
  let waypoints = [];
  if (trajectories.length > 2) {
    for (let i = 1; i < trajectories.length - 1; i++) {
      waypoints.push(trajectories[i]);
    }
  }

  result.push({
    directions: await loadDirections(origin, destination, waypoints),
    status: isCompletedTrip(status) ? 'COMPLETED' : 'PENDING',
  });

};

const generateDirectionWithCheckpoint = async (trajectories, checkpoints, result, loadDirections, status) => {
  // Garante ter origem e destino
  const origin = trajectories[0];
  const destination = trajectories[trajectories.length - 1];

  const last = checkpoints[checkpoints.length - 1];
  const checkingTrajectoriesPending = [];

  const checkingTrajectories = trajectories.filter((traj) => {
    const distanceThreshold = 2.5; // Defina o limite de distância desejado (em quilômetros)

    // Verifica se a distância entre o trajeto e algum ponto de checkpoint é menor ou igual ao limite
    const isWithinDistance = checkpoints.some((checkpoint) => {
      const distance = getDistanceFromLatLonInKm(checkpoint.latitude, checkpoint.longitude, traj.latitude, traj.longitude);
      return distance > distanceThreshold;
    });

    return isWithinDistance;
  });

  if (checkingTrajectories?.length > 0) {
    addTrajectoriesCheckpoint(origin, destination, last, checkpoints, checkingTrajectories, checkingTrajectoriesPending);
  }

  // Gera os trechos da viagem que já foram completados
  let chunks = generateChunks(origin, last, checkpoints);

  for (let i = 0; i < chunks.length; i++) {
    result.push({
      directions: await loadDirections(chunks[i].origin, chunks[i].destination, chunks[i].waypoints),
      status: 'COMPLETED',
    });
  }

  // Gera os trechos da viagem que ainda estão pendentes
  if (checkingTrajectoriesPending?.length > 0) {
    let chunks = generateChunks(last, destination, checkingTrajectoriesPending);

    for (let i = 0; i < chunks.length; i++) {
      result.push({
        directions: await loadDirections(chunks[i].origin, chunks[i].destination, chunks[i].waypoints),
        status: isCompletedTrip(status) ? 'COMPLETED' : 'PENDING',
      });
    }
  }
};

const addTrajectoriesCheckpoint = (origin, destination, last, checkpoints, checkingTrajectories, checkingTrajectoriesPending) => {
  // Filtra checkingTrajectories para manter apenas os registros que não correspondem às coordenadas de origem/destino
  checkingTrajectories = checkingTrajectories.filter((traj) => {
    return (
      traj.latitude !== origin.latitude && traj.longitude !== origin.longitude // &&
      // traj.latitude !== destination.latitude &&
      // traj.longitude !== destination.longitude
    );
  });

  const distanceLast = getDistanceFromLatLonInKm(last?.latitude, last?.longitude, destination?.latitude, destination?.longitude);
  for (let i = 0; i < checkingTrajectories.length; i++) {
    const distanceTrajectory = getDistanceFromLatLonInKm(
      checkingTrajectories[i]?.latitude,
      checkingTrajectories[i]?.longitude,
      destination?.latitude,
      destination?.longitude
    );

    if (distanceTrajectory > distanceLast) {
      // Encontra o índice do ponto de checkpoint mais próximo da última posição
      const nearestIndex = findNearestCheckpointIndex(destination, checkingTrajectories[i], checkpoints);

      // Adiciona o checkingTrajectories[i] no ponto mais próximo
      if (nearestIndex !== -1) {
        checkpoints.splice(nearestIndex, 0, checkingTrajectories[i]);
      }
    } else {
      checkingTrajectoriesPending.push(checkingTrajectories[i]);
    }
  }

  if (checkingTrajectories.length == 0) {
    checkingTrajectoriesPending.push(destination);
  }
};

// Função para encontrar o índice do ponto de checkpoint mais próximo em relação a uma origem específica
const findNearestCheckpointIndex = (destination, currentLocation, checkpoints) => {
  let nearestIndex = 0;
  const currentDistance = getDistanceFromLatLonInKm(
    currentLocation.latitude,
    currentLocation.longitude,
    destination.latitude,
    destination.longitude
  );

  for (let i = 0; i < checkpoints.length; i++) {
    const checkpoint = checkpoints[i];
    const distance = getDistanceFromLatLonInKm(checkpoint.latitude, checkpoint.longitude, destination.latitude, destination.longitude);
    if (currentDistance < distance) {
      nearestIndex = i;
    }
  }

  return nearestIndex;
};

export const generateChunks = (origin, destination, checkpoints, chunkSize = 24) => {
  let list = checkpoints.slice();

  list.unshift(origin);
  list.push(destination);

  let directions = [];
  let chunkCount = Math.ceil(list.length / chunkSize);

  for (let i = 0; i < chunkCount; i++) {
    let first = i * chunkSize;
    if (first > 0) {
      first -= 1; // Gerar intersecção dos caminhos
    }
    let last = Math.min((i + 1) * chunkSize - 1, list.length - 1);

    let waypoints = [];

    for (let j = first + 1; j < last; j++) {
      waypoints.push(list[j]);
    }

    directions.push({
      origin: list[first],
      destination: list[last],
      waypoints: waypoints,
    });
  }

  return directions;
};

export const centroid = (coordinates) => {
  let x = 0;
  let y = 0;
  let z = 0;

  for (let i in coordinates) {
    let coordinate = coordinates[i];

    let latitude = (coordinate.latitude * Math.PI) / 180;
    let longitude = (coordinate.latitude * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  x = x / coordinates.length;
  y = y / coordinates.length;
  z = z / coordinates.length;

  return {
    latitude: (Math.atan2(y, x) * 180) / Math.PI,
    longitude: (Math.atan2(z, Math.sqrt(x * x + y * y)) * 180) / Math.PI,
  };
};

export const calculateOptimalZoom = (ne, sw, mapHeight = 500, mapWidth = 1000, worldHeight = 256, worldWidth = 256, zoomMax = 21) => {
  function latRad(lat) {
    let sin = Math.sin((lat * Math.PI) / 180);
    let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  let latFraction = (latRad(ne.latitude) - latRad(sw.latitude)) / Math.PI;

  let lngDiff = ne.longitude - sw.longitude;
  let lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  let latZoom = Math.floor(Math.log(mapHeight / worldHeight / latFraction) / Math.LN2);
  if (isNaN(latZoom)) {
    latZoom = zoomMax;
  }
  let lngZoom = Math.floor(Math.log(mapWidth / worldWidth / lngFraction) / Math.LN2);
  if (isNaN(lngZoom)) {
    lngZoom = zoomMax;
  }

  return Math.min(latZoom, lngZoom, zoomMax);
};

export const reduceCheckpointListByDistance = (checkpoints, distanceInKm = 3) => {
  if (checkpoints.length <= 2) {
    return checkpoints;
  }

  const checkpointsReduced = [];

  let nextJ = 1;
  for (let j = 0; j < checkpoints.length - 2; j++) {
    // Não vai ate o último da lista
    let distance = 0;
    let first = checkpoints[j];

    checkpointsReduced.push(checkpoints[j]);
    do {
      let next = checkpoints[nextJ++];
      if (!next) {
        break;
      }
      distance = getDistanceFromLatLonInKm(first.latitude, first.longitude, next.latitude, next.longitude);
    } while (distance <= distanceInKm);
    j = nextJ;
  }

  checkpointsReduced.push(checkpoints[checkpoints.length - 1]); // Sempre envia o último

  return checkpointsReduced;
};

export const checkVisitedPoints = (trajectories, checkpoints, distanceInKm = 2.5) => {
  let result = {
    visited: [],
    notVisited: [],
  };

  for (let i in trajectories) {
    let current = trajectories[i];

    let isVisited = false;
    for (let j in checkpoints) {
      let checkpoint = checkpoints[j];

      let distance = getDistanceFromLatLonInKm(current.latitude, current.longitude, checkpoint.latitude, checkpoint.longitude);

      if (distance <= distanceInKm) {
        isVisited = true;
        break;
      }
    }

    if (isVisited) {
      result.visited.push(current);
    } else {
      result.notVisited.push(current);
    }
  }

  return result;
};
