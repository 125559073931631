import React, { useEffect, useState } from 'react';

import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Button, Text } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import CommonPage from '../../../components/CommonPage/CommonPage';
import FloatActionButton from '../../../components/Generic/FloatActionButton';
import { PageContainer } from '../../../components/PageContainer/PageContainer';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import { requests } from '../../../services/v2/requests';
import { filterOptionsQuote } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { commonListConfig } from './components/commonListConfig';
const QuotePage = () => {
  const hasPermission = permissions.vendemmiaFreightQuote;

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);

  const load = (filters, key, page) => {
    window.localStorage.removeItem('entity-old');

    getQuoteList(filters, key, page);
  };

  // Utilizção do hook personalizado para o refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const handleChangesolicitor = async (url) => {
    await executeRequest({
      action: () =>
        requests.post({
          url,
        }),
      msgSuccess: 'Solicitante alterado com sucesso!',
      setIsLoading,
    });
  };

  const config = commonListConfig({ handleChangesolicitor });

  // Funções de requisição
  const getQuoteList = async (filters, _key, page) => {
    const res = await executeRequest({
      action: () =>
        requests.get({
          url: '/quote/list',
          filters,
          page,
        }),
      setIsLoading,
    });

    setList(res?.data?.data);
    setMetadata(res?.data?.meta);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsQuote());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <CommonPage
      screen="quote"
      title="Cotação de frete"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Buscar por referência ou texto"
      hasPermission={hasPermission}
      metadata={metadata}
      list={list}
      load={load}
      filterOptions={filterOptions}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      refreshSeconds={false}
      forceLoadTrigger={forceLoadTrigger}>
      <PageContainer>
        <CommonList
          list={list}
          rawData={list}
          //actions
          action={action}
          sorting={sorting}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          //loading
          isLoading={isLoading}
          {...config}
        />
      </PageContainer>
      <FloatActionButton
        showOptions={false}
        isOpen={isOpenFloatButton}
        setIsOpen={setIsOpenFloatButton}
        saveButton={
          <Link to="/vendemmia/quote/new">
            <Button
              leftIcon={<MdAdd color="#FFFFFF" size={20} />}
              _hover={{ bgColor: '#70D499' }}
              bg="green"
              p="1.5rem"
              pb="1.5rem"
              pl="1.3rem"
              pr="1.3rem"
              borderRadius="50px">
              <Text mr="10px" color="white">
                Nova cotação
              </Text>
            </Button>
          </Link>
        }
      />
    </CommonPage>
  );
};

export default QuotePage;
