import { useContext } from 'react';

import { MdOutlineMenu } from 'react-icons/md';

import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Tooltip } from '@chakra-ui/react';

import { CommonListContext } from '../contexts/CommonListContext';

const shouldRender = (buttonProps, condition = true) => (typeof condition === 'function' ? condition(buttonProps) : condition);

const hasPermission = (allowedRoles = [], deniedRoles = []) => {
  const userRole = localStorage.getItem('profile-role')?.trim();
  if (!userRole) return false;
  if (deniedRoles.includes(userRole)) return false;
  return allowedRoles.length === 0 || allowedRoles.includes(userRole);
};

const renderButton = ({ button, buttonProps, identifier, subCategoriesProps, isMenu = false, index }) => {
  const { isButtonDisabled = false, icon, label, tooltip } = button || '';
  const isDisabled = shouldRender(buttonProps, isButtonDisabled);
  const commonProps = {
    key: index,
    onClick: (e) => {
      e.stopPropagation();
      button.action(identifier, subCategoriesProps);
    },
    isDisabled,
  };

  if (isMenu) {
    return (
      <MenuItem key={`menu-item-${index}`} {...commonProps} border={0} boxShadow={'none'}>
        {icon} {label}
      </MenuItem>
    );
  }

  return (
    <Tooltip key={`tooltip-${index}`} label={tooltip}>
      <Button p={icon ? '0' : '0 15px'} variant="commonTable" {...commonProps}>
        {icon || label}
      </Button>
    </Tooltip>
  );
};

const CustomButtons = ({ buttons, buttonProps, identifier, subCategoriesProps }) => {
  // Utiliza o contexto para acessar as propriedades
  const { customButtons } = useContext(CommonListContext);

  // Consolida todas as configurações em um único objeto
  const mergedConfig =
    customButtons?.reduce((acc, item) => {
      return { ...acc, ...item.config?.[0] };
    }, {}) ?? {};

  // Define valores padrão para evitar undefined
  const { maxVisibleButtons = 2, ...otherConfigs } = mergedConfig;

  if (!buttons || buttons.length === 0) return null;

  const visibleButtons = buttons.filter(
    (button) => shouldRender(buttonProps, button.isButtonVisible) && hasPermission(button.allowedRoles, button.deniedRoles)
  );

  if (visibleButtons.length === 0) return null;

  const primaryButtons = visibleButtons.slice(0, maxVisibleButtons);
  const menuButtons = visibleButtons.slice(maxVisibleButtons);

  return (
    <Flex align="center" gap="8px">
      {primaryButtons.map((button, index) => renderButton({ button, buttonProps, identifier, subCategoriesProps, index }))}

      {menuButtons.length > 0 && (
        <Menu>
          <MenuButton as={Button} variant="commonTable">
            <MdOutlineMenu size={18} />
          </MenuButton>
          <MenuList className="commonMenu" zIndex={'101'}>
            {menuButtons.map((button, index) =>
              renderButton({
                button,
                buttonProps,
                identifier,
                subCategoriesProps,
                isMenu: true,
                index: `menu-${index}`,
              })
            )}
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};

export default CustomButtons;
